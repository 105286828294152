import PhysicalDoctorForm from './PhysicalDoctorForm';
import PhysicalPatientForm from './PhysicalPatientForm';
import { DetailWrapped } from './styled';
import customToast from '@/new-components/CustomNotification';
import {
  Grid,
  makeStyles,
  TextField,
  Tab,
  Tabs,
  Box,
  withStyles,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { LocalizationProvider } from '@material-ui/pickers';
import MomentUtils from '@material-ui/pickers/adapter/moment';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles({
  title: {
    fontWeight: 600,
    marginRight: 8,
  },
  wrapperItem: {
    height: '100%',
    backgroundColor: '#F6F7FA',
    padding: ' 10px 20px 0 20px',
  },
  rowItem: {
    margin: 0,
    overflowY: 'auto',
    paddingBottom: 10,
  },
  inputFilter: { width: 550 },
  tabInput: { width: '25%', alignItems: 'center' },
  wrapperHeader: { borderBottom: ' 2px solid #F6F7FA', padding: '8px 0px' },
  textDate: {
    fontSize: 12,
    color: '#8F9BA9',
  },
  divider: { borderBottom: ' 2px solid #F6F7FA' },
});

const StyledTab = withStyles((theme) => ({
  root: {
    minWidth: 'unset',
    minHeight: '28px',
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(15),

    marginRight: 10,
    paddingTop: 0,
    paddingBottom: 0,

    backgroundColor: '#F6F7FA',
    borderRadius: 4,
    color: '#8F9BA9',
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: '#FFFFFF',
    },
    '&:focus': {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

const StyledAutocomplete = withStyles((theme) => ({
  option: {
    borderBottom: ' 2px solid #F6F7FA',
  },
}))((props) => <Autocomplete {...props} />);

const PhysicalInformation = ({
  isDoctorSide,
  isEdit,
  setIsDoctorSide,
  dataPEDoctor,
  setDataPEDoctor,

  dataPEPatient,
  setDataPEPatient,
  physicalExamInfo,
  currentSelectValue,
  setCurrentSelectValue,
  errors,
}) => {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState(0);

  const onChangeDate = (e, newValue) => {
    const newDatePEDoctor = {
      doctorJsonData: newValue?.doctorJsonData,
      doctorSubmittedDate: newValue?.doctorSubmittedDate,
      paxScreeningId: newValue?.paxScreeningId | null,
    };
    const newDatePEPatient = {
      patientJsonData: newValue?.patientJsonData,
      patientSubmittedDate: newValue?.patientSubmittedDate,
      paxScreeningId: newValue?.paxScreeningId,
    };
    setDataPEDoctor(newDatePEDoctor);
    setDataPEPatient(newDatePEPatient);
    setCurrentSelectValue(newValue);
  };

  useEffect(() => {
    const newDatePEDoctor = {
      doctorJsonData: currentSelectValue?.doctorJsonData,
      doctorSubmittedDate: currentSelectValue?.doctorSubmittedDate,
      paxScreeningId: currentSelectValue?.paxScreeningId,
    };
    const newDatePEPatient = {
      patientJsonData: currentSelectValue?.patientJsonData,
      patientSubmittedDate: currentSelectValue?.patientSubmittedDate,
      paxScreeningId: currentSelectValue?.paxScreeningId,
    };
    setDataPEDoctor(newDatePEDoctor);
    setDataPEPatient(newDatePEPatient);
  }, [currentSelectValue]);

  const USER_SIDES_TABS = [
    {
      key: 'client',
      label: 'Client',
      component: (
        <PhysicalPatientForm
          isEdit={isEdit}
          dataPEPatient={dataPEPatient}
          setDataPEPatient={setDataPEPatient}
          errors={errors}
        />
      ),
    },
    {
      key: 'doctor',
      label: 'Doctor',
      component: (
        <PhysicalDoctorForm
          isEdit={isEdit}
          dataPEDoctor={dataPEDoctor}
          setDataPEDoctor={setDataPEDoctor}
          errors={errors}
        />
      ),
    },
  ];

  const renderOptionLabel = (option) => {
    const { projectName, locationAddress } = option;
    return projectName && locationAddress
      ? `${option.projectName} - ${option.locationAddress}`
      : null;
  };

  useEffect(() => {
    if (USER_SIDES_TABS[currentTab].key === 'doctor') {
      setIsDoctorSide(true);
    } else setIsDoctorSide(false);
  }, [currentTab]);

  return (
    <DetailWrapped>
      <LocalizationProvider dateAdapter={MomentUtils}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              className={classes.wrapperHeader}
            >
              <Tabs
                value={currentTab}
                onChange={(e, val) => {
                  const isPatient =
                    dataPEPatient?.patientJsonData !==
                    currentSelectValue?.patientJsonData;
                  const isDoctor =
                    dataPEDoctor?.doctorJsonData !==
                    currentSelectValue?.doctorJsonData;
                  if (isEdit && (isPatient || isDoctor)) {
                    customToast('error', 'Please save your change!');
                  } else setCurrentTab(val);
                }}
                indicatorColor="none"
                variant="scrollable"
                className={classes.tabInput}
              >
                {USER_SIDES_TABS.map((item) => (
                  <StyledTab
                    label={item['label'] || item}
                    key={item['label'] || item}
                  />
                ))}
              </Tabs>
              <StyledAutocomplete
                className={classes.inputFilter}
                getOptionSelected={(option, value) => option.id === value.id}
                getOptionLabel={(option) => renderOptionLabel(option)}
                renderOption={(option) => (
                  <React.Fragment>
                    <Grid containr alignItems="center">
                      <Grid item xs={12}>
                        {option.projectName} - {option.locationAddress}
                      </Grid>

                      <Grid item xs={12} className={classes.textDate}>
                        {option.appointmentDate &&
                          moment(option.appointmentDate).format('DD/MM/YYYY')}
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )}
                options={physicalExamInfo}
                value={currentSelectValue}
                autoComplete
                onChange={onChangeDate}
                disabled={isEdit}
                renderInput={(params) => (
                  <TextField {...params} label="Appointment" />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            {USER_SIDES_TABS[currentTab].component}
          </Grid>
        </Grid>
      </LocalizationProvider>
    </DetailWrapped>
  );
};

export default PhysicalInformation;

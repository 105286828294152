import { createDownloadableLink } from '@/helpers';
import moment from 'moment';
import { synthesize } from 'redux-dispatcher';

const mapDispatchToAC = {
  saveDataSuccess: (name, value) => ({ name, value }),
  getListCategories: () => async ({ Api }) => {
    const response = await Api.post(`Categories/filter`, {});
    if (response.status === 200) {
      globalDispatcher.saveDataSuccess('categories', response.result.list);
    }
  },
  getListLocations: () => async ({ Api }) => {
    const response = await Api.post(`Locations/filter`, {});
    if (response.status === 200) {
      const newList = [...response.result.list].filter(
        item => item?.name && item?.address
      );
      globalDispatcher.saveDataSuccess('locations', newList);
    }
  },
  getListStations: () => async ({ Api }) => {
    const response = await Api.get(`Stations`, {});
    if (response.status === 200) {
      globalDispatcher.saveDataSuccess('stations', response.result.list);
    }
  },
  getListProfiles: callback => async ({ Api }) => {
    const response = await Api.get(`Profiles`, {});
    response.status === 200 && callback && callback(response.result?.list);
  },
  getListServiceByCategory: (category, callback, isAssigned) => async ({
    Api
  }) => {
    if (!category) return;
    const response = await Api.get('Tests', {
      category,
      isAssigned
    });
    response.status === 200 && callback && callback(response.result?.list);
  },
  getListSurveys: callback => async ({ Api }) => {
    const response = await Api.post(`Surveys/filter`, {});
    response.status === 200 && callback && callback(response.result?.list);
  },
  getExportExcel: (service, search) => async ({ Api }) => {
    let { result, status } = await Api.post(`${service}/csv-report`, {
      search
    });
    status === 200 && createDownloadableLink(result, `${service}-Report`);
  },
  getListConsentForm: () => async ({ Api }) => {
    const response = await Api.post(`Consents/filter`, {});
    if (response.status === 200) {
      globalDispatcher.saveDataSuccess('consents', response.result.list);
    }
  },
  getAllTests: () => async ({ Api }) => {
    try {
      const response = await Api.get(`Tests/key-values`);
      if (response.status === 200) {
        globalDispatcher.saveDataSuccess('tests', response.result);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  },
  getAllTestsSuccess: data => ({ data }),
  getListTagsPackage: () => async ({ Api }) => {
    const { result, status } = await Api.get(`Tags/package`, {});
    status === 200 && globalDispatcher.saveDataSuccess('tagsPackage', result);
  },
  getListTagsAddOns: () => async ({ Api }) => {
    const { result, status } = await Api.get(`Tags/add-on`, {});
    status === 200 && globalDispatcher.saveDataSuccess('tagsAddOns', result);
  },
  uploadIdentityImage: (data, callback) => async ({ Api }) => {
    const { status, result } = await Api.post(
      `Files/upload-identity-photo`,
      data,
      '',
      undefined,
      true,
      true
    );

    if (status === 200) {
      callback && callback(result);
      globalDispatcher.saveDataSuccess('identityImage', result);
    }
  },
  getIdentityImages: (patientId, callback) => async ({ Api }) => {
    const { result, status } = await Api.get(`Files/identity-urls`, {
      patientId
    });
    status === 200 && callback && callback(result);
  },
  getMaxPriceDefault: (type, callback) => async ({ Api }) => {
    const { result, status } = await Api.get(
      `Metadata/max-price?MaxPriceOf=${type}`
    );
    status === 200 && callback && callback(result);
  },
  getGlobalRevenueChart: (filterOption = {}) => async ({ Api }) => {
    globalDispatcher.showHideLoadingAction('teleConsultRevenueLoading', true);

    const { result, status } = await Api.post(
      `Analytics/global/chart/revenue`,
      { ...filterOption }
    );

    if (status === 200) {
      globalDispatcher.showHideLoadingAction(
        'teleConsultRevenueLoading',
        false
      );
      globalDispatcher.saveDataSuccess('chartRevenue', result.items);
    }
  },
  getGlobalEpisodesChart: (filterOption = {}) => async ({ Api }) => {
    globalDispatcher.showHideLoadingAction('teleConsultEpisodesLoading', true);

    const { result, status } = await Api.post(
      `Analytics/global/chart/episodes`,
      { ...filterOption }
    );
    if (status === 200) {
      globalDispatcher.showHideLoadingAction(
        'teleConsultEpisodesLoading',
        false
      );
      globalDispatcher.saveDataSuccess('chartEpisodes', result.items);
    }
  },
  getTeleConsultSummary: () => async ({ Api }) => {
    const { result, status } = await Api.get(`Analytics/teleconsult/summary`);
    if (status === 200) {
      globalDispatcher.saveDataSuccess('teleConsultSummary', result);
    }
  },

  getTeleConsultPeakDayOfWeek: () => async ({ Api }) => {
    globalDispatcher.showHideLoadingAction(
      'teleConsultPeakDayOfWeekLoading',
      true
    );

    const { result, status } = await Api.get(
      `Analytics/teleconsult/peak/day-of-week`
    );
    if (status === 200) {
      globalDispatcher.showHideLoadingAction(
        'teleConsultPeakDayOfWeekLoading',
        false
      );
      globalDispatcher.saveDataSuccess(
        'chartTeleConsultPeakDayOfWeek',
        result.items
      );
    }
  },
  getTeleConsultPeakHourOfDay: () => async ({ Api }) => {
    globalDispatcher.showHideLoadingAction(
      'teleConsultPeakHourOfDayLoading',
      true
    );

    const { result, status } = await Api.get(
      `Analytics/teleconsult/peak/hour-of-day`
    );
    if (status === 200) {
      globalDispatcher.showHideLoadingAction(
        'teleConsultPeakHourOfDayLoading',
        false
      );
      globalDispatcher.saveDataSuccess(
        'chartTeleConsultPeakHourOfDay',
        result.items
      );
    }
  },
  getTeleConsultWaitingTimeChart: (filterOption = {}) => async ({ Api }) => {
    globalDispatcher.showHideLoadingAction(
      'teleConsultWaitingTimeLoading',
      true
    );

    const { result, status } = await Api.post(
      `Analytics/teleconsult/chart/waiting-time`,
      {
        ...filterOption,
        fromDate: filterOption.fromDate
          ? moment(filterOption.fromDate).format('YYYY-MM-DD')
          : undefined,
        toDate: filterOption.toDate
          ? moment(filterOption.toDate).format('YYYY-MM-DD')
          : undefined
      }
    );
    if (status === 200) {
      globalDispatcher.showHideLoadingAction(
        'teleConsultWaitingTimeLoading',
        false
      );
      globalDispatcher.saveDataSuccess(
        'chartTeleConsultWaitingTime',
        result.items
      );
    }
  },
  getTeleConsultDropsChart: (filterOption = {}) => async ({ Api }) => {
    globalDispatcher.showHideLoadingAction('teleConsultDropsLoading', true);

    const { result, status } = await Api.post(
      `Analytics/teleconsult/chart/drops`,
      {
        ...filterOption,
        fromDate: filterOption.fromDate
          ? moment(filterOption.fromDate).format('YYYY-MM-DD')
          : undefined,
        toDate: filterOption.toDate
          ? moment(filterOption.toDate).format('YYYY-MM-DD')
          : undefined
      }
    );
    if (status === 200) {
      globalDispatcher.showHideLoadingAction('teleConsultDropsLoading', false);
      globalDispatcher.saveDataSuccess('chartTeleConsultDrops', result.items);
    }
  },
  getTeleConsultPickupsChart: (filterOption = {}) => async ({ Api }) => {
    globalDispatcher.showHideLoadingAction('teleConsultPickupsLoading', true);

    const { result, status } = await Api.post(
      `Analytics/teleconsult/chart/pickups`,
      {
        ...filterOption,
        fromDate: filterOption.fromDate
          ? moment(filterOption.fromDate).format('YYYY-MM-DD')
          : undefined,
        toDate: filterOption.toDate
          ? moment(filterOption.toDate).format('YYYY-MM-DD')
          : undefined
      }
    );
    if (status === 200) {
      globalDispatcher.showHideLoadingAction(
        'teleConsultPickupsLoading',
        false
      );
      globalDispatcher.saveDataSuccess('chartTeleConsultPickups', result.items);
    }
  },

  getTeleConsultCallsChart: (filterOption = {}) => async ({ Api }) => {
    globalDispatcher.showHideLoadingAction('teleConsultCallsLoading', true);

    const { result, status } = await Api.post(
      `Analytics/teleconsult/chart/calls`,
      {
        ...filterOption,
        fromDate: filterOption.fromDate
          ? moment(filterOption.fromDate).format('YYYY-MM-DD')
          : undefined,
        toDate: filterOption.toDate
          ? moment(filterOption.toDate).format('YYYY-MM-DD')
          : undefined
      }
    );
    if (status === 200) {
      globalDispatcher.showHideLoadingAction('teleConsultCallsLoading', false);
      globalDispatcher.saveDataSuccess('chartTeleConsultCalls', result.items);
    }
  },

  showHideLoadingAction: (name, value) => ({ name, value })
};

const globalDispatcher = synthesize('globalState', mapDispatchToAC);

export default globalDispatcher;

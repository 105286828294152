import { StatusComponent } from '../all-screenings/component/StatusComponent';
import TableCellFilted from '../common/componentUI/TableCellFilted';
import telecounselAppointmentDispatcher from './action';
import GroupToolbar from './component/GroupToolbar';
import {
  COUNSEL_APPOINTMENT_STATUS,
  TYPE_MODAL,
  renderCounselApptCol,
} from './constants';
import {
  CreateNewIcon,
  EditIcon,
  ExportIcon,
  NoDataScreenings,
  RemoveIcon,
  Teleconsult,
} from '@/assets/svg';
import { ROLE_TYPES } from '@/constants/roles';
import { urlLabel } from '@/enum/PermissionEnum';
import { handleSelectAll } from '@/helpers';
import ModalAddOrEditTelecounselAppt from '@/new-components/CustomModal/ModalAddOrEditTelecounselAppt';
import ModalConfirmation from '@/new-components/CustomModal/ModalConfirmation';
import customToast from '@/new-components/CustomNotification';
import CustomPaperContainer from '@/new-components/CustomPaperContainer';
import CustomHeader from '@/new-components/CustomPaperContainer/CustomHeader';
import CustomTable from '@/new-components/CustomTable';
import CustomTooltip from '@/new-components/CustomTooltip';
import {
  Button,
  TableHead,
  TableRow,
  TableCell,
  IconButton,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { debounce } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const StartCallButton = withStyles((theme) => ({
  root: {
    padding: '8px 12px',
    minWidth: 93,
  },
}))(Button);

const TelecounselAppointments = () => {
  const history = useHistory();
  const {
    list: data,
    paging,
    listAvailableUsers,
  } = useSelector((state) => state.telecounselAppointment);
  const currentUser = useSelector((state) => state.auth.userInfo);

  const [sortOption, setSortOption] = useState({ by: 'Date', dir: 'desc' });
  const [searchKey, setSearchKey] = useState('');
  const [filterOption, setFilterOption] = useState({
    appointmentTypes: ['TeleCounsel'],
  });

  const [counselDate, setCounselDate] = useState([]);
  const [appointmentStatuses, setAppointmentStatuses] = useState([]);
  const [counselIds, setCounselIds] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);

  const [typeModal, setTypeModal] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  const isCounsellor = currentUser.roleName === ROLE_TYPES.COUNSELLOR;

  const onFilterOptionClear = (key, defaultValue, setState) => () => {
    setFilterOption({
      ...filterOption,
      [key]: defaultValue,
    });

    setState && setState(defaultValue);
  };

  const onFilterOptionChange = (key, setState) => (e, newValue) => {
    let value = e.target.value;

    switch (key) {
      case 'appointmentStatuses':
        let newValue = handleSelectAll(
          value?.filter((it) => it !== 'Assigned'),
          COUNSEL_APPOINTMENT_STATUS.map((item) => item.key),
          appointmentStatuses?.filter((it) => it !== 'Assigned')
        );

        if (newValue.includes('Upcoming')) {
          newValue = newValue.concat('Assigned');
        } else {
          newValue = newValue.filter((it) => it !== 'Assigned');
        }

        newValue = newValue.filter(
          (item, pos, self) => self.indexOf(item) === pos
        );
        value = newValue;

        break;
      case 'counselIds':
        value = newValue.map((item) => item.id);
        setCounselIds(value);
        break;
      default:
        break;
    }

    setFilterOption({
      ...filterOption,
      [key]: value,
    });

    setState && setState(value);
  };

  const onResetFilterOps = () => {
    setCounselDate([]);
    setAppointmentStatuses([]);
    setCounselIds([]);
    setFilterOption({ appointmentTypes: ['TeleCounsel'] });
  };

  const onShowModal = (type) => {
    setShowModal(true);
    setTypeModal(type);
  };

  const onCloseModal = () => {
    setShowModal(false);
  };

  const handleToggleShowCancelModal = useCallback(() => {
    setShowCancelModal(!showCancelModal);
  }, [showCancelModal]);

  const onExportExcel = () => {
    if (data.length === 0) {
      return customToast(
        'error',
        'There is no data available to export the excel file'
      );
    }

    telecounselAppointmentDispatcher.getExportExcel(
      searchKey,
      sortOption,
      filterOption
    );
  };

  const onCancelAppointment = () => {
    telecounselAppointmentDispatcher.cancelAppointment(selectedItem.id, () => {
      fetchData(searchKey, paging, sortOption, filterOption);
      renderToast(selectedItem.patientFullName, true);
      handleToggleShowCancelModal();
    });
  };

  const handleUpdateAppointment = (type, data) => {
    switch (type) {
      case TYPE_MODAL.Edit:
        telecounselAppointmentDispatcher.editDoctorToAppointment(
          data.id,
          {
            counsellorId: data.doctor?.id,
            appointmentType: data.appointmentType,
            appointmentDate: data.appointmentDate,
          },
          () => {
            fetchData(searchKey, paging, sortOption, filterOption);
            renderToast(data.patient.fullName, true);
            onCloseModal();
          }
        );
        break;
      case TYPE_MODAL.Create:
        telecounselAppointmentDispatcher.createDoctorToAppointment(
          {
            appointmentType: data.appointmentType,
            appointmentDate: data.appointmentDate,
            doctorId: data.doctor?.id,
            patientId: data.patient?.id,
            bookingTimeType: 'TeleCounsel',
          },
          () => {
            fetchData(searchKey, paging, sortOption, filterOption);
            renderToast(data.patient.fullName);
            onCloseModal();
          }
        );
        break;
      default:
        break;
    }
  };

  const renderToast = (data, isEdit = false) => {
    return customToast(
      'success',
      <span>
        Appointment for <strong style={{ fontWeight: 600 }}>{data}</strong> has
        been successully {isEdit ? 'updated.' : 'created.'}
      </span>,
      isEdit ? 'Appointment updated' : 'New appointment created'
    );
  };

  const renderDateTime = (row) => {
    let startDateFormat, startTimeFormat;
    const { appointmentDate } = row;

    if (!appointmentDate) return null;

    startDateFormat = moment(appointmentDate).format('DD/MM/YYYY');
    startTimeFormat = moment(appointmentDate).format('LT');
    return `${startDateFormat} ${startTimeFormat}`;
  };

  const checkIsValidTime15Mins = (dateTime) => {
    const startTime = moment(dateTime).subtract(15, 'minutes');
    const endTime = moment(dateTime).add(15, 'minutes');

    const timeCompare = moment();
    const diffStartTime = startTime.diff(timeCompare, 'minutes');
    const diffEndTime = endTime.diff(timeCompare, 'minutes');

    return diffStartTime <= 0 && diffEndTime > 0;
  };

  const fetchData = async (
    search = searchKey,
    pagingData = paging,
    sortOptionData = sortOption,
    filterOptionData = filterOption
  ) => {
    telecounselAppointmentDispatcher.getData(
      search,
      pagingData,
      ...[sortOptionData, filterOptionData].filter((i) => i)
    );
  };

  const debounceLoadData = useCallback(
    debounce((searchKey, paging, sortOption, filterOption) => {
      fetchData(
        searchKey,
        {
          pageIndex: 0,
          pageSize: paging.pageSize,
        },
        sortOption,
        filterOption
      );
    }, 500),
    []
  );

  useEffect(() => {
    debounceLoadData(searchKey, paging, sortOption, filterOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey, sortOption, filterOption]);

  const TableHeader = () => (
    <TableHead>
      <TableRow>
        {renderCounselApptCol(isCounsellor).map((item) =>
          item.sortBy ? (
            <TableCellFilted
              key={item.stateValue}
              label={item.label}
              stateValue={item.stateValue}
              sortBy="Date"
              sortOption={sortOption}
              onSortChange={() => {
                let newSortOption = {
                  by: 'Date',
                  dir: sortOption.dir !== 'asc' ? 'asc' : 'desc',
                };
                setSortOption(newSortOption);
              }}
              style={{
                minWidth: item.minWidth || 'unset',
                width: item.width || 'unset',
              }}
              isHidden={false}
            />
          ) : (
            <TableCellFilted
              key={item.stateValue}
              label={item.label}
              stateValue={item.stateValue}
              style={{
                minWidth: item.minWidth || 'unset',
                width: item.width || 'unset',
              }}
              isHidden={false}
            />
          )
        )}
      </TableRow>
    </TableHead>
  );

  const renderTableBody = (row) => {
    return (
      <>
        <TableCell>
          <CustomTooltip content={row.patientFullName} />
        </TableCell>
        <TableCell>
          <CustomTooltip content={row.patientShortId} />
        </TableCell>
        <TableCell>
          <CustomTooltip content={row.patientNRIC} />
        </TableCell>
        {!isCounsellor && (
          <TableCell>
            <CustomTooltip content={row.doctorFullName || '—'} />
          </TableCell>
        )}
        <TableCell>{renderDateTime(row)}</TableCell>

        <TableCell>
          <StatusComponent
            status={
              row.appointmentStatus === 'Assigned'
                ? 'Upcoming'
                : row.appointmentStatus
            }
          />
        </TableCell>
        <TableCell>
          {isCounsellor ? (
            <StartCallButton
              startIcon={<Teleconsult />}
              color="primary"
              variant="contained"
              hidden={
                !(
                  ['Assigned', 'Upcoming'].includes(row.appointmentStatus) &&
                  checkIsValidTime15Mins(row.appointmentDate)
                )
              }
              onClick={() => history.push(`/${urlLabel.telecounselQueue}`)}
            >
              Call now
            </StartCallButton>
          ) : (
            <>
              <IconButton
                onClick={() => {
                  onShowModal(TYPE_MODAL.Edit);
                  setSelectedItem(row);
                }}
                disabled={
                  !['Assigned', 'Upcoming'].includes(row.appointmentStatus)
                }
              >
                <EditIcon
                  color={
                    !['Assigned', 'Upcoming'].includes(row.appointmentStatus)
                      ? '#AD5E9933'
                      : '#AD5E99'
                  }
                />
              </IconButton>
              <IconButton
                onClick={() => {
                  handleToggleShowCancelModal();
                  setSelectedItem(row);
                }}
                disabled={
                  !['Assigned', 'Upcoming'].includes(row.appointmentStatus)
                }
                style={{ margin: '0 16px' }}
              >
                <RemoveIcon />
              </IconButton>
            </>
          )}
        </TableCell>
      </>
    );
  };

  return (
    <>
      <CustomPaperContainer
        header={
          <CustomHeader
            searchPlaceholder="Search by client name or ID number..."
            title="Appointments"
            setSearchKey={setSearchKey}
            searchKey={searchKey}
            filterOption={filterOption}
            setFilterOption={setFilterOption}
            sortOption={sortOption}
            fetchData={fetchData}
            renderToolbar={() => (
              <GroupToolbar
                filterOption={filterOption}
                setFilterOption={setFilterOption}
                onFilterOptionClear={onFilterOptionClear}
                onFilterOptionChange={onFilterOptionChange}
                onResetFilterOps={onResetFilterOps}
                counselDate={counselDate}
                setCounselDate={setCounselDate}
                appointmentStatuses={appointmentStatuses}
                setAppointmentStatuses={setAppointmentStatuses}
                counselIds={counselIds}
                setCounselIds={setCounselIds}
                isCounsellor={isCounsellor}
              />
            )}
            renderButton={() => (
              <>
                <Button
                  color="primary"
                  variant="outlined"
                  startIcon={<ExportIcon width={20} height={20} />}
                  onClick={onExportExcel}
                >
                  Export
                </Button>
                {currentUser.roleName !== ROLE_TYPES.COUNSELLOR && (
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={<CreateNewIcon />}
                    onClick={() => {
                      setSelectedItem(null);
                      onShowModal(TYPE_MODAL.Create);
                    }}
                  >
                    Create new
                  </Button>
                )}
              </>
            )}
          />
        }
      >
        <CustomTable
          data={data}
          paging={paging}
          header={TableHeader}
          renderRow={(row, i) => renderTableBody(row)}
          noDataHelperText="No appointments"
          noDataHelperSubText="Please come back at another time."
          Icon={NoDataScreenings}
          totalCols={renderCounselApptCol(isCounsellor).length}
          fetchData={fetchData}
        />
      </CustomPaperContainer>
      {showModal && (
        <ModalAddOrEditTelecounselAppt
          open={showModal}
          onClose={onCloseModal}
          typeModal={typeModal}
          onSubmit={handleUpdateAppointment}
          selectedItem={selectedItem}
          isCounsellor={isCounsellor}
        />
      )}
      {showCancelModal && (
        <ModalConfirmation
          open={showCancelModal}
          onClose={handleToggleShowCancelModal}
          onClick={onCancelAppointment}
          mainContent={'Cancel appointment'}
          subContent={'Are you sure you want to cancel this appointment?'}
          cancelLabel="No, keep it"
          confirmLabel="Confirm"
          confirmColor="red"
        />
      )}
    </>
  );
};

export default TelecounselAppointments;

import { teleAppoinmentDispatcher } from '..';
import MenuAction from '../../../components/MenuAction';
import TableContainer from '../../../components/Table/TableContainer';
import { urlLabel } from '../../../enum/PermissionEnum';
import TableCellFilted from '../../common/componentUI/TableCellFilted';
import EditAppointment from '../component/EditAppointment';
import { STATUSES_APPOINTMENT } from '../constants';
import GroupSearch from './GroupSearch';
import { StatusComponent } from './StatusComponent';
import {
  ConsentFormActive,
  ConsentFormInactive,
  PaymentActive,
  PaymentInactive,
  SurveyActive,
  SurveyInative,
  PhysicalExamActive,
  PhysicalExamInactive,
  CancelIcon,
  CheckmarkCircleIcon,
} from '@/assets/svg';
import CustomTooltip from '@/components/CustomTooltip';
import { PdfExport } from '@/components/PdfExport';
import {
  AppointmentType,
  TYPE_MODAL,
} from '@/module/appointment-management/constants';
import { authDispatcher } from '@/module/auth';
import FiltedBodyCell from '@/module/common/component/FiltedBodyCell';
import ModalConfirmation from '@/new-components/CustomModal/ModalConfirmation';
import customToast from '@/new-components/CustomNotification';
import globalDispatcher from '@/redux/global/actions';
import {
  TableHead,
  TableRow,
  TableCell,
  Menu,
  IconButton,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  withStyles,
  Tooltip,
  makeStyles,
  Checkbox,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import {
  MoreVertRounded,
  Visibility,
  Check,
  Cancel,
  GetApp,
  VisibilityRounded,
  Edit,
  CancelOutlined,
  Info,
  LibraryAddCheck,
  Cached,
  Autorenew,
  FileCopyOutlined,
} from '@material-ui/icons';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const TableCellSticky = withStyles({
  root: {
    position: 'sticky',
    background: 'white',
    right: 0,
    // borderLeft: '1px solid #e0e0e0',
    width: 80,
  },
})(TableCell);

const useStyle = makeStyles({
  wrapperIcon: {
    position: 'relative',
    width: 30,
    maxWidth: 30,
    marginRight: 16,
  },
  checkedIcon: {
    position: 'absolute',
    right: -7,
    top: -3,
  },
});

const TeleAppointmentManagement = ({ userId, showToolbar }) => {
  const history = useHistory();
  const classes = useStyle();
  const { list: data, paging } = useSelector((state) => state.teleAppoinment);
  const { locations: listLocations } = useSelector(
    (state) => state.globalState
  );
  const { userPasscode } = useSelector((state) => state.auth);

  const [selectedItem, setSelectedItem] = useState({});
  const [hiddenRows, setHiddenRows] = useState({});
  const [searchKey, setSearchKey] = useState('');
  const [deleteModal, setDeleteModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const [appointmentStatuses, setAppointmentStatuses] = useState([]);
  const [paxScreenPaymentStatus, setPaxScreenPaymentStatus] = useState([]);
  const [paymentSupressed, setPaymentSupressed] = useState([]);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showRevertModal, setShowRevertModal] = useState(false);
  const [exporting, setExporting] = useState(false);

  const [locationOpen, setLocationOpen] = useState(false);
  const [locationFilter, setLocationFilter] = useState([]);
  const [startDate, setStartDate] = useState(moment().startOf('month'));
  const [endDate, setEndDate] = useState(moment().endOf('date'));
  const [createdStartDate, setCreatedStartDate] = useState('');
  const [createdEndDate, setCreatedEndDate] = useState('');

  const [filterOption, setFilterOption] = useState({
    appointmentTypes,
    appointmentStatuses,
    startDate,
    endDate,
  });
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [isViewDetail, setIsViewDetail] = useState(false);
  const [sortOption, setSortOption] = useState({});

  useEffect(() => {
    globalDispatcher.getListLocations();
    authDispatcher.getUserPasscode();

    return () => {
      teleAppoinmentDispatcher.resetData();
    };
  }, []);

  useEffect(() => {
    if (isEmpty(startDate)) {
      setFilterOption({
        ...filterOption,
        startDate,
        endDate: startDate,
      });
    }
    // eslint-disable-next-line
  }, [startDate]);

  const onShowModal = (type) => {
    setAnchorEl(null);
  };

  const onResetFilterOps = () => {
    setPaymentSupressed([]);
    setPaxScreenPaymentStatus([]);
    setAppointmentTypes([]);
    setAppointmentStatuses([]);
    setLocationFilter([]);
    setStartDate('');
    setEndDate('');
    setCreatedStartDate('');
    setCreatedEndDate('');
    setFilterOption({
      appointmentTypes: [],
      isFreePayment: null,
    });
  };

  const onFilterOptionClear = (state, setState, defaultValue) => () => {
    setFilterOption({
      ...filterOption,
      [state]: defaultValue,
    });
    setState(defaultValue);
  };

  const onFilterOptionChange = (state, setState) => (e) => {
    setFilterOption({
      ...filterOption,
      [state]: e.target.value,
    });
    setState(e.target.value);
  };

  const onCancelAppointment = () => {
    if (isEmpty(selectedItem)) return;
    teleAppoinmentDispatcher.cancelTeleAppointment(
      selectedItem.paxScreeningId,
      () => {
        customToast('success', 'Cancel Appointment Success');
        teleAppoinmentDispatcher.getData(searchKey, paging, filterOption);
        setShowCancelModal(false);
      }
    );
  };

  const onCreateAppointment = () => {
    // setShowModal(true);
    // setTypeModal(TYPE_MODAL.Create);
    history.push({
      pathname: `/${urlLabel.aSCreateAppointment}`,
    });
  };

  const onRevertAppointment = () => {
    if (isEmpty(selectedItem)) return;
    teleAppoinmentDispatcher.revertAppoinmentToUpcoming(
      selectedItem.paxScreeningId,
      () => {
        teleAppoinmentDispatcher.getData(searchKey, paging, filterOption);
        setShowRevertModal(false);
      }
    );
  };

  const renderDateTime = (row) => {
    let startDateFormat, startTimeFormat;
    const { startDate } = row;

    if (!startDate) return null;

    startDateFormat = moment(startDate).format('DD-MM-YYYY');
    startTimeFormat = moment(startDate).format('LT');
    return `${startDateFormat}, ${startTimeFormat}`;
  };

  const renderListAction = (
    status,
    appointmentTypes = [],
    paymentStatus,
    startDate
  ) => {
    if (!status) return [];

    let listActions = [];

    let isTeleDocAppointment = AppointmentType.map((i) => i.value).some((i) =>
      appointmentTypes.includes(i)
    );
    if (isTeleDocAppointment) {
      listActions = [
        {
          key: 'view',
          icon: VisibilityRounded,
          label: 'View Details',
          onClick: () => onShowModal(TYPE_MODAL.View),
        },
      ];
      return listActions;
    }
    const patientInformationRegistration = () => {
      if (isEmpty(selectedItem)) return;

      const {
        paxScreeningId,
        status,
        healthScreeningId,
        patientId,
        totalCost,
        isFollowUpAppointment,
      } = selectedItem;

      history.push({
        pathname: `/${urlLabel.checkInPatientInfo}`,
        state: {
          paxScreeningId,
          status,
          healthScreeningId,
          patientId,
          totalCost,
          isFollowUpAppointment,
        },
      });
    };

    const followUpAppointment = () => {
      if (isEmpty(selectedItem)) return;

      history.push({
        pathname: `/${urlLabel.aSCreateAppointment}`,
        state: {
          isFollowUp: true,
          paxProps: selectedItem,
        },
      });
    };

    switch (status) {
      case STATUSES_APPOINTMENT.DEFAULTED:
        listActions = listActions.concat([
          {
            key: 'edit',
            icon: Edit,
            label: 'Edit',
            onClick: () => {
              setShowModalEdit(true);
              setAnchorEl(null);
            },
          },
        ]);
        break;
      case STATUSES_APPOINTMENT.CHECK_IN:
      case STATUSES_APPOINTMENT.IN_PROGRESS:
      case STATUSES_APPOINTMENT.COMPLETED:
        listActions = listActions.concat([
          {
            key: 'view-registration',
            icon: Visibility,
            label: 'View Registration Information',
            onClick: patientInformationRegistration,
          },
        ]);
        break;
      case STATUSES_APPOINTMENT.UPCOMING:
        var isCurrentDate = moment(startDate).isSame(new Date(), 'day');
        if (isCurrentDate) {
          listActions = listActions.concat([
            {
              key: 'check-in',
              icon: Check,
              label: 'Check-in location',
              onClick: patientInformationRegistration,
            },
          ]);
        }
        listActions = listActions.concat([
          {
            key: 'cancel',
            icon: Cancel,
            label: 'Cancel',
            onClick: () => {
              setAnchorEl(null);
              setShowCancelModal(true);
            },
          },
          {
            key: 'edit',
            icon: Edit,
            label: 'Edit',
            onClick: () => {
              setShowModalEdit(true);
              setAnchorEl(null);
            },
          },
        ]);
        break;

      case STATUSES_APPOINTMENT.CHECKIN_LOCATION:
        listActions = listActions.concat([
          {
            key: 'check-in',
            icon: Check,
            label: 'Registration station',
            onClick: patientInformationRegistration,
          },
          {
            key: 'edit',
            icon: Edit,
            label: 'Edit',
            onClick: () => {
              setShowModalEdit(true);
              setAnchorEl(null);
            },
          },
        ]);
        break;

      default:
        break;
    }

    listActions = listActions.concat({
      key: 'detail',
      icon: Info,
      label: 'View Detail',
      onClick: () => {
        setIsViewDetail(true);
        setShowModalEdit(true);
        setAnchorEl(null);
      },
    });

    if (
      [
        STATUSES_APPOINTMENT.IN_PROGRESS,
        STATUSES_APPOINTMENT.COMPLETED,
      ].includes(status)
    ) {
      // listActions = listActions.concat({
      //   key: 'download',
      //   icon: GetApp,
      //   label: 'Download Report',
      //   onClick: () => {
      //     teleAppoinmentDispatcher.downloadReportByStatus(
      //       selectedItem.paxScreeningId,
      //       selectedItem.shortId
      //     );
      //     setAnchorEl(null);
      //   }
      // });

      listActions.push(
        {
          key: 'export',
          icon: GetApp,
          label: 'Export PDF',
          onClick: () => {
            const hasIRReport = selectedItem.hasIRReport;
            const newPaxScreeningId = selectedItem.paxScreeningId;
            setExporting(true);
            if (hasIRReport) {
              teleAppoinmentDispatcher.downloadIRReport(newPaxScreeningId);
              setExporting(false);
            } else {
              customToast('error', `You don't have any IR report to download`);
              setExporting(false);
            }

            setAnchorEl(null);
          },
        },
        {
          key: 're-generate',
          icon: Cached,
          label: 'Regenerate PDF',
          onClick: () => {
            const newPaxScreeningId = selectedItem.paxScreeningId;
            setExporting(true);
            teleAppoinmentDispatcher.regenerateIRReport(newPaxScreeningId);
            setExporting(false);
            setAnchorEl(null);
          },
        }
      );
    }

    if (
      paymentStatus &&
      paymentStatus !== STATUSES_APPOINTMENT.READY_TO_PAY &&
      ![
        STATUSES_APPOINTMENT.CANCELLED,
        STATUSES_APPOINTMENT.DEFAULTED,
        STATUSES_APPOINTMENT.UPCOMING,
        STATUSES_APPOINTMENT.CHECKIN_LOCATION,
      ].includes(status) &&
      !selectedItem.isFollowUpAppointment
    ) {
      listActions.push({
        key: 'follow-up',
        icon: LibraryAddCheck,
        label: 'Follow-up Appointment',
        onClick: followUpAppointment,
      });
    }

    if (
      [
        STATUSES_APPOINTMENT.IN_PROGRESS,
        STATUSES_APPOINTMENT.CHECK_IN,
        STATUSES_APPOINTMENT.CHECKIN_LOCATION,
      ].includes(status) &&
      !selectedItem.isFollowUpAppointment
    ) {
      listActions = listActions.concat({
        key: 'revert',
        icon: Autorenew,
        label: 'Revert To Upcoming',
        onClick: () => {
          setAnchorEl(null);
          setShowRevertModal(true);
        },
      });
    }
    return listActions;
  };

  const exportExcel = () => {
    if (data.length === 0) {
      return customToast(
        'info',
        'There is no data available to export the excel file'
      );
    }
    let params = {
      ...sortOption,
      appointmentTypes,
      search: searchKey,
      appointmentStatuses,
      startDate,
      endDate,
      userId,
      paxScreenPaymentStatus,
      locationIds: locationFilter,
      createdStartDate,
      createdEndDate,
    };

    if (!isEmpty(paymentSupressed)) {
      params = { ...params, isFreePayment: !isEmpty(paymentSupressed) };
    }
    teleAppoinmentDispatcher.getExportExcel(params);
  };

  const copyLinkClipboard = async (text) => {
    if (!navigator?.clipboard) {
      customToast('error', 'Clipboard not supported!');
      return;
    }

    // Try to save to clipboard then save it in the state if worked
    try {
      await navigator.clipboard.writeText(text);
      customToast('success', 'Copy successful!');
    } catch (error) {
      customToast('error', 'Copy failed!');
    }
  };
  const listActions = renderListAction(
    selectedItem?.status,
    selectedItem?.appointmentTypes,
    selectedItem?.paxScreenPaymentStatus,
    selectedItem?.startDate
  );

  const TableCellFiltedWrapper = ({ ...props }) => (
    <TableCellFilted
      {...{
        hiddenRows,
        setHiddenRows,
      }}
      {...props}
    />
  );
  const TableHeader = () => (
    <TableHead>
      <TableRow>
        <TableCellFiltedWrapper
          label="User"
          stateValue="user"
          style={{ width: 200 }}
        />
        <TableCellFiltedWrapper
          label="Registration Status"
          stateValue="registrationStatus"
          style={{ width: 200 }}
        />
        <TableCellFiltedWrapper
          label="Free Payment"
          stateValue="paymentSuppression"
          style={{ width: 200 }}
        />
        <TableCellFiltedWrapper
          label="NRIC"
          stateValue="nric"
          style={{ width: 200 }}
        />
        <TableCellFiltedWrapper
          label="Date & Time"
          stateValue="date"
          sortBy="Date"
          sortOption={sortOption}
          onSortChange={() => {
            let newSortOption = {
              sortBy: 'BookedApptDate',
              orderBy: sortOption.orderBy !== 'Asc' ? 'Asc' : 'Desc',
            };
            setSortOption(newSortOption);
          }}
          style={{ width: 200 }}
        />
        <TableCellFiltedWrapper
          label="Location"
          stateValue="location"
          style={{ width: 200 }}
        />
        {userId && (
          <TableCellFiltedWrapper label="Doctor" stateValue="doctorFullName" />
        )}
        <TableCellFiltedWrapper
          label="Status"
          stateValue="status"
          style={{ width: 160 }}
        />

        <TableCellSticky align="right">
          <IconButton
            aria-label="delete"
            style={{ padding: 0 }}
            onClick={exportExcel}
          >
            <GetApp />
          </IconButton>
        </TableCellSticky>
      </TableRow>
    </TableHead>
  );

  const renderRegistrationStatus = (row) => (
    <div style={{ display: 'flex' }}>
      <Tooltip title="Payment Status">
        {row.paxScreenPaymentStatus === 'Paid' ? (
          <div className={classes.wrapperIcon}>
            <CheckmarkCircleIcon className={classes.checkedIcon} />
            <PaymentActive />
          </div>
        ) : (
          <div className={classes.wrapperIcon}>
            <PaymentInactive />
          </div>
        )}
      </Tooltip>
      <Tooltip title="Consent Form">
        {row.status === STATUSES_APPOINTMENT.UPCOMING ||
        row.consentInfo.totalCompleted !== row.consentInfo.total ? (
          <div className={classes.wrapperIcon}>
            <ConsentFormInactive />
          </div>
        ) : (
          <div className={classes.wrapperIcon}>
            <CheckmarkCircleIcon className={classes.checkedIcon} />
            <ConsentFormActive />
          </div>
        )}
      </Tooltip>
      <Tooltip title="Survey">
        {row.surveyInfo.totalCompleted === row.surveyInfo.total ? (
          <div className={classes.wrapperIcon}>
            <CheckmarkCircleIcon className={classes.checkedIcon} />
            <SurveyActive />
          </div>
        ) : (
          <div className={classes.wrapperIcon}>
            <SurveyInative />
          </div>
        )}
      </Tooltip>
      <Tooltip title="Physical Examination">
        {row.patientPEInfo.totalCompleted !== row.patientPEInfo.total ? (
          <div className={classes.wrapperIcon}>
            <PhysicalExamInactive />
          </div>
        ) : (
          <div className={classes.wrapperIcon}>
            <CheckmarkCircleIcon className={classes.checkedIcon} />
            <PhysicalExamActive />
          </div>
        )}
      </Tooltip>
    </div>
  );

  return (
    <>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuAction listActions={listActions} />
      </Menu>
      <TableContainer
        fullWidthSearch
        toolbar={showToolbar}
        title="Appointments"
        defaultSearchInput={false}
        paging={paging}
        data={data}
        header={TableHeader}
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        filterOption={filterOption}
        sortOption={sortOption}
        query={(...params) => {
          teleAppoinmentDispatcher.getData(...params, userId);
        }}
        customButtons={
          <TextField
            value={userPasscode}
            style={{ pointerEvents: 'none', width: 200 }}
            InputProps={{
              startAdornment: <InputAdornment>Passcode: </InputAdornment>,
              endAdornment: (
                <InputAdornment>
                  <IconButton
                    style={{ pointerEvents: 'auto' }}
                    color="primary"
                    onClick={() => copyLinkClipboard(userPasscode)}
                  >
                    <FileCopyOutlined />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        }
        ToolbarComponent={
          <GroupSearch
            {...{
              userId,
              setSearchKey,
              searchKey,
              appointmentTypes,
              setAppointmentTypes,
              filterOption,
              onFilterOptionClear,
              onFilterOptionChange,
              setFilterOption,
              startDate,
              setStartDate,
              endDate,
              setEndDate,
              onResetFilterOps,
              listLocations,
              locationOpen,
              setLocationOpen,
              locationFilter,
              setLocationFilter,
              appointmentStatuses,
              setAppointmentStatuses,
              paxScreenPaymentStatus,
              setPaxScreenPaymentStatus,
              onCreateAppointment,
              paymentSupressed,
              setPaymentSupressed,
              createdStartDate,
              setCreatedStartDate,
              createdEndDate,
              setCreatedEndDate,
            }}
          />
        }
        renderRow={(row, i) => {
          return (
            <>
              <FiltedBodyCell hidden={hiddenRows.user}>
                <CustomTooltip maxLength={20} content={row.fullName} />
                <Typography style={{ color: '#8F9BA9' }}>
                  {row.shortId}
                </Typography>
              </FiltedBodyCell>
              <FiltedBodyCell hidden={hiddenRows.registrationStatus}>
                {row.consentInfo &&
                  row.surveyInfo &&
                  row.paxScreenPaymentStatus &&
                  row.patientPEInfo &&
                  renderRegistrationStatus(row)}
              </FiltedBodyCell>
              <FiltedBodyCell hidden={hiddenRows.paymentSuppression}>
                <Checkbox
                  name="paymentSuppression"
                  checked={row.isPaxscreenSuppression}
                  disabled
                />
              </FiltedBodyCell>
              <FiltedBodyCell hidden={hiddenRows.nric}>
                {row.nric}
              </FiltedBodyCell>
              <FiltedBodyCell hidden={hiddenRows.date}>
                {renderDateTime(row)}
              </FiltedBodyCell>
              <FiltedBodyCell hidden={hiddenRows.location}>
                <CustomTooltip maxLength={40} content={row.location} />
              </FiltedBodyCell>
              {userId && (
                <FiltedBodyCell hidden={hiddenRows.doctorFullName}>
                  {[row.doctorFirstName, row.doctorLastName].filter((i) => i)
                    .length ? (
                    <>
                      <Typography>
                        {[row.doctorFirstName, row.doctorLastName]
                          .filter((i) => i)
                          .join(' ')}
                      </Typography>
                    </>
                  ) : (
                    '- -'
                  )}
                </FiltedBodyCell>
              )}
              <FiltedBodyCell hidden={hiddenRows.status}>
                <StatusComponent status={row.status} />
              </FiltedBodyCell>

              <TableCellSticky align="right">
                {renderListAction(
                  row.status,
                  row.appointmentTypes,
                  null,
                  row.startDate
                ).length !== 0 && (
                  <IconButton
                    onClick={(e) => {
                      setIsViewDetail(false);
                      setAnchorEl(e.currentTarget);
                      setSelectedItem(row);
                    }}
                  >
                    <MoreVertRounded />
                  </IconButton>
                )}
              </TableCellSticky>
            </>
          );
        }}
      />
      <Dialog
        open={showModalEdit}
        onClose={() => setShowModalEdit(false)}
        maxWidth="md"
        fullWidth
        scroll="body"
        disableEnforceFocus
      >
        <EditAppointment
          isViewDetail={isViewDetail}
          onClose={() => setShowModalEdit(false)}
          selectedItem={selectedItem}
          cbGetData={() =>
            teleAppoinmentDispatcher.getData(searchKey, paging, filterOption)
          }
        />
      </Dialog>

      {showCancelModal && (
        <ModalConfirmation
          open={showCancelModal}
          onClose={() => setShowCancelModal(false)}
          onClick={onCancelAppointment}
          mainContent={'Cancel appointment'}
          subContent={'Are you sure you want to cancel this appointment?'}
          cancelLabel="No, keep it"
          confirmLabel="Confirm"
        />
      )}
      {showRevertModal && (
        <ModalConfirmation
          maxWidth="sm"
          open={showRevertModal}
          onClose={() => setShowRevertModal(false)}
          onClick={onRevertAppointment}
          mainContent={'Do you want to revert this appointment ?'}
          subContent="This appointment will be reverted to Upcoming status"
          Icon={<Autorenew color="error" style={{ fontSize: 80 }} />}
        />
      )}
      <Dialog open={exporting}>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Exporting PDF
          </DialogContentText>
        </DialogContent>
      </Dialog>
      {/* {paxScreening && (
        <PdfExport paxScreening={paxScreening} onDone={onExportPdfDone} />
      )} */}
    </>
  );
};

export default TeleAppointmentManagement;

import { synthesize } from 'redux-dispatcher';

const mapDispatchToAC = {
  addCustomBreadcrumb: ({ routes, asPath }) => ({
    routes,
    asPath
  })
};

const breadcrumbDispatcher = synthesize('breadcrumb', mapDispatchToAC);

export default breadcrumbDispatcher;

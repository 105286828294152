import React, { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';
import CustomSelectContainer from '@/new-components/CustomSelectOld/CustomSelectContainer';
import { LANGUAGES_MOTHER_STATEMENT } from '../contansts';

const CustomButton = withStyles({
  label: {
    fontWeight: 700
  }
})(Button);

const GroupToolbar = ({
  onResetFilterOps,
  searchKey,
  currentLang,
  setCurrentLang
}) => {
  return (
    <Toolbar>
      <Box
        display="flex"
        flex={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" gridGap={12}>
          <CustomSelectContainer
            isFullWidth
            filterValue={currentLang}
            data={LANGUAGES_MOTHER_STATEMENT}
            renderValue={<>{currentLang || 'Select language'}</>}
            isMutiple={false}
            onChange={e => setCurrentLang(e.target.value)}
            onClear={() => setCurrentLang('')}
          />
        </Box>
        <CustomButton
          disabled={isEmpty(searchKey) && isEmpty(currentLang)}
          onClick={onResetFilterOps}
        >
          Reset
        </CustomButton>
      </Box>
    </Toolbar>
  );
};

export default GroupToolbar;

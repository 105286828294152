import { urlLabel } from '@/enum/PermissionEnum';
import { PrivateLayout } from '@/module/common';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({ component: Component, title, ...rest }) => {
  return (
    <React.Fragment>
      <Route
        {...rest}
        render={(matchProps) => {
          if (rest.auth.accessToken) {
            return (
              <PrivateLayout title={title}>
                <Component {...matchProps} />
              </PrivateLayout>
            );
          }
          return <Redirect to={`/${urlLabel.login}`} />;
        }}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    privilege: state.userPermission.privilege,
  };
};

export const WrapperPrivateRoute = connect(mapStateToProps)(PrivateRoute);

import dispatcher from './actions';

const initialState = {
  routes: [],
  asPath: ''
};

const breadcrumReducer = dispatcher(initialState, {
  [dispatcher.addCustomBreadcrumb]: (_, payload) => {
    const { routes, asPath } = payload;
    return {
      routes,
      asPath
    };
  }
});

export default breadcrumReducer;

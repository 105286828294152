/**
 * Author: Quan Vo
 * Date: 2020-01-07
 */
import styled from 'styled-components';
import { TableCell, DialogActions } from '@material-ui/core';

export const CellBorder = styled(TableCell)`
  border-right: 1px solid #e0e0e0;
`;

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Content = styled.div`
  padding: 20px;
  flex: 1;
`;

export const Header = styled.div`
  align-self: flex-end;
`;

export const Footer = styled.div`
  align-self: flex-end;
  button {
    margin: 10px;
  }
`;

export const StyledButton = styled.div`
  height: 40px;
  width: 340px;
  border-radius: 20px;
  background-color: #ad5e99;
  transition: all 0.5s;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 16px;
  color: white;

  &:hover {
    background-color: #94347c;
  }
`;

export const ButtonEnhance = styled.div`
  height: 40px;
  min-width: ${props => (props.minWidth ? props.minWidth : '160px')};
  border-radius: 20px;
  background-color: ${props =>
    props.background ? props.background : '#ad5e99'};
  transition: all 0.5s;
  cursor: ${props => (props.disabled ? 'no-drop' : 'pointer')};
  text-align: center;
  font-size: ${props => (props.fontSize ? props.fontSize : '14px')};
  font-weight: 600;
  padding: 10px 16px;
  color: ${props => (props.color ? props.color : 'white')}
  &:hover {
    background-color: ${props =>
      props.backgroundHover ? props.backgroundHover : '#94347c'}
  };
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  margin: 5px;
  border: ${props => (props.border ? props.border : 'none')};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`;

export const ChipStatus = styled.div`
  font-size: 12px;
  color: white;
  border-radius: 10px;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 2px;
  padding-bottom: 2px;
  text-align: center;
  width: fit-content;
  white-space: nowrap;
  background-color: ${props => props.color || '#62CAA4'};
`;

const StatusWrapper = styled.div`
  font-size: 12px;
  color: white;
  border-radius: 50px;
  padding: 5px 10px;
  text-align: center;
  width: fit-content;
  background-color: ${props => {
    switch (props.status) {
      case 'Temp':
      case 'Pending': {
        return '#CACFD3';
      }
      case 'Closed':
      case 'Reject':
      case 'InActive': {
        return '#EA6B75';
      }
      case 'Open':
      case 'Approved':
      case 'Active': {
        return '#62CAA4';
      }
      default:
        return '#EA6B75';
    }
  }};
`;

export { StatusWrapper };

export const ModalPage = styled.div`
  max-width: 780px;
  hr {
    background: #cacfd3;
    margin: 0;
  }
  .MuiDialogContent-root {
    padding: 0;
    &:first-child {
      padding-top: 0;
    }
  }
  .test-header {
    padding: 20px;
    font-size: 18px;
    font-weight: 600;
    color: #192637;
    border-bottom: 1px solid rgb(202, 207, 211);
  }
  .test-body {
    padding: 0 20px 20px 20px;
  }
`;

export const ModalFooter = styled(DialogActions)`
  border-top: 1px solid rgb(202, 207, 211);
  padding: 10px 0px;
`;

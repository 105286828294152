import Enum from '../../../enum/Enum';

const TYPE_MODAL = {
  AssignDoctor: 'AssignDoctor',
  Edit: 'Edit',
  View: 'View',
  Create: 'Create'
};

const LIST_REQUIRED = [
  'title',
  'categoryId',
  'trainerStudioClassId',
  'date',
  'studioId',
  'price',
  'slot',
  'duration'
];

const FORM_VALUE = {
  title: '',
  thumbnail: '',
  price: 0,
  slot: '',
  categoryId: '',
  trainerStudioClassId: '',
  studioId: ''
};

const FORMAT_DATE = 'MMM DD, YYYY';
const FORMAT_TIME = 'hh:mm A';
const FORMAT_DATE_TIME = 'MMM DD, YYYY hh:mm A';

const AppointmentType = Enum({
  TeleConsult: 'See Doctor Online',
  TeleArt: 'Supervised ART',
  TeleMedicine: 'Chronic Disease Telemedicine Programme',
  TeleReview: 'Post-Screening Review'
});

const renderAppointmentType = (
  isSwabber,
  isHiddenAppointmentTypes,
  hasPayment,
  isCreate = false
) => {
  let newListTypes = AppointmentType;
  if (isSwabber)
    newListTypes = Enum({
      TeleArt: 'Supervised ART'
    });

  if (isHiddenAppointmentTypes || isCreate)
    newListTypes = Enum({
      TeleConsult: 'See Doctor Online',
      TeleMedicine: 'Chronic Disease Telemedicine Programme',
      TeleReview: 'Post-Screening Review'
    });
  if (!hasPayment)
    newListTypes = Enum({
      TeleReview: 'Post-Screening Review'
    });

  if (isCreate) {
    newListTypes = Enum({
      TeleConsult: 'See Doctor Online'
    });
  }

  return newListTypes;
};

const TeleAppointmentType = Enum({
  MMS: 'MMS',
  HBS: 'HBS',
  EHS: 'EHS',
  CHS: 'CHS'
});

const AllUsersAppointmentType = Enum({
  TeleConsult: 'See Doctor Online',
  TeleReview: 'Post-Screening Review',
  TeleMedicine: 'Chronic Disease Telemedicine Programme',
  TeleArt: 'Supervised ART',
  MMS: 'MMS',
  HBS: 'HBS',
  EHS: 'EHS',
  CHS: 'CHS'
});

const AppointmentStatus = Enum({
  Upcoming: 'Upcoming',
  // Assigned: 'Assigned',
  InProgress: 'InProgress',
  Completed: 'Completed',
  Cancelled: 'Cancelled',
  Rejected: 'Rejected',
  Missed: 'Missed'
});

const TeleAppointmentStatus = Enum({
  Upcoming: 'Upcoming',
  CheckIn: 'Checked-In',
  CheckInLocation: 'Check In Location',
  InProgress: 'In Progress',
  Completed: 'Completed',
  Cancelled: 'Cancelled',
  Missed: 'Missed'
});

const TeleAppointmentPaymentStatus = Enum({
  ReadyToPay: 'ReadyToPay',
  Paid: 'Paid'
});

const AllUsersAppointmentStatus = Enum({
  Upcoming: 'Upcoming',
  Assigned: 'Assigned',
  CheckIn: 'Checked-In',
  CheckInLocation: 'Check In Location',
  InProgress: 'In Progress',
  Completed: 'Completed',
  Cancelled: 'Cancelled',
  Rejected: 'Rejected',
  Missed: 'Missed'
});

const AppointmentColor = Enum({
  InProgress: '#5A5EAB',
  Upcoming: '#CE568F',
  Cancelled: '#8F9BA9',
  Rejected: '#8F9BA9',
  Missed: '#EA6B75',
  Assigned: '#FA8F51',
  Completed: '#AD5E99'
});

const TYPE_BOOKING_TIME = Enum({
  Other: 'Other',
  SupervisedArt: 'SupervisedArt'
});

const PAYMENT_SUPPRESSED = Enum({
  AllAppointmentSuppressed: 'All Appointment Suppressed'
});

export {
  TYPE_BOOKING_TIME,
  TYPE_MODAL,
  LIST_REQUIRED,
  FORM_VALUE,
  FORMAT_DATE,
  FORMAT_TIME,
  FORMAT_DATE_TIME,
  AppointmentStatus,
  AppointmentColor,
  AppointmentType,
  TeleAppointmentStatus,
  TeleAppointmentType,
  AllUsersAppointmentType,
  AllUsersAppointmentStatus,
  TeleAppointmentPaymentStatus,
  renderAppointmentType,
  PAYMENT_SUPPRESSED
};

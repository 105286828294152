import React from 'react';
import './styles.scss';
import { AddImage } from '../../assets/svg';
import { CloseRounded } from '@material-ui/icons';
import { blobUrl } from '../../helpers';

const UploadCompAnt = ({
  fileData,
  onChange,
  label,
  editable,
  id,
  ActionComponent,
  isAddNew = false,
  error
}) => {
  const uploadButton = () => {
    if (!editable) return null;
    if (fileData)
      return (
        <CloseRounded
          style={{
            fontSize: 20,
            position: 'absolute',
            right: 20,
            top: 20,
            cursor: 'pointer'
          }}
          onClick={e => {
            e.preventDefault();
            onChange(null);
          }}
        />
      );
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        className={error && !fileData ? 'error-highlight' : ''}
      >
        <AddImage style={{ marginRight: 5 }} />
        {label}
      </div>
    );
  };
  const onInputChange = event => {
    const file = event.target.files[0];
    file && onChange(file);
    event.target.value = '';
  };

  return (
    <label htmlFor={id}>
      <input
        id={id}
        accept="image/*"
        type="file"
        hidden
        onChange={onInputChange}
        disabled={!editable}
      />
      {ActionComponent || (
        <div
          className={
            fileData ? 'avatar-uploader image-selected' : 'avatar-uploader'
          }
          style={{
            backgroundImage: fileData
              ? `url(${
                  typeof fileData === 'object' ? blobUrl(fileData) : fileData
                })`
              : null,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat'
          }}
        >
          {uploadButton()}
        </div>
      )}
    </label>
  );
};

export default UploadCompAnt;

import {
  TableCell,
  Tooltip,
  Checkbox,
  TableSortLabel
} from '@material-ui/core';
import React from 'react';
import ImportExportIcon from '@material-ui/icons/ImportExport';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { EyeShow, EyeHide } from '../../../assets/svg';
import { isFunction } from 'lodash';

const TableCellFilted = ({
  label,
  stateValue,
  hiddenRows = {},
  setHiddenRows,
  style = {},
  useCheckBox,
  isChecked,
  onCheckboxChange,
  className,
  sortBy = '',
  onSortChange,
  sortOption = {},
  isSort = false,
  isHidden = true
}) => {
  const checked = isFunction(isChecked) ? isChecked() : isChecked;
  const isCurrentSort = sortBy === sortOption.by;
  const direction = sortOption.dir;
  let icon = ArrowDropDownIcon;
  if (!isCurrentSort && !hiddenRows[stateValue]) {
    icon = ImportExportIcon;
  }
  return (
    <TableCell className={className} style={style}>
      <TableSortLabel
        active={!hiddenRows[stateValue] && (sortBy === sortOption.by || isSort)}
        direction={direction}
        hideSortIcon={!sortBy || hiddenRows[stateValue]}
        onClick={() => {
          sortBy && !hiddenRows[stateValue] && onSortChange();
        }}
        IconComponent={icon}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {useCheckBox && (
            <Checkbox
              color="primary"
              checked={checked}
              onChange={onCheckboxChange}
            />
          )}
          {!hiddenRows[stateValue] && label}
          {isHidden && (
            <Tooltip title={label}>
              {!hiddenRows[stateValue] ? (
                <EyeShow
                  className="eye-custom-color"
                  onClick={e => {
                    e.stopPropagation();
                    setHiddenRows({
                      ...hiddenRows,
                      [stateValue]: true
                    });
                  }}
                />
              ) : (
                <EyeHide
                  className="eye-custom-color"
                  onClick={e => {
                    e.stopPropagation();
                    setHiddenRows({
                      ...hiddenRows,
                      [stateValue]: false
                    });
                  }}
                />
              )}
            </Tooltip>
          )}
        </div>
      </TableSortLabel>
    </TableCell>
  );
};

export default TableCellFilted;

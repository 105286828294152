import React, { useState, useEffect, Fragment } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';
import userPermissionDispatcher from '../../user-permission/action';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Collapse
} from '@material-ui/core';
import {
  ExpandLess,
  ExpandMore,
  DirectionsBikeRounded,
  Loyalty,
  EventAvailableRounded,
  ViewCarouselRounded
} from '@material-ui/icons';
import { ListRounded, People } from '@material-ui/icons';
import {
  ClockIcon,
  DoctorIcon,
  SettingIcon,
  HistoryIcon,
  ClinicIcon,
  PatientIcon,
  CaIcon,
  WorkoutIcon,
  QueueIcon,
  TestIcon,
  LocationIcon,
  CompanyIcon,
  Queues,
  AllUser,
  Appointments,
  Projects,
  Services,
  Deletion,
  LabResult,
  Banner
} from '../../../assets/svg';
import { urlLabel } from '../../../enum/PermissionEnum';
import authDispatcher from '../../auth/action/auth';
import './menu.scss';
import waitingRoomDispatcher from '@/module/waiting-room/action/waitingRoom';

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  secondary: {
    color: '#000000de'
  }
});

export const GROUPED_MENU_ITEMS = [
  {
    title: 'All Users',
    privilege: 'HomescreenAllUsers',
    isGroupItem: false,
    isItem: true,
    value: urlLabel.userDetails,
    icon: AllUser,
    children: [
      {
        value: urlLabel.editUserDetail
      }
    ]
  },
  {
    title: 'TeleDoc',
    isGroupItem: true,
    isOpen: false,
    children: [
      {
        title: 'Doctor Management',
        value: urlLabel.doctorManagement,
        privilege: 'DoctorManagement',
        icon: DoctorIcon
      },
      {
        title: 'Patient Management',
        value: urlLabel.patientManagement,
        privilege: 'PatientManagement',
        icon: PatientIcon
      },
      {
        title: 'CA Management',
        value: urlLabel.caManagement,
        privilege: 'CAManagement',
        icon: CaIcon
      },
      {
        title: 'Swabber Management',
        value: urlLabel.swabberManagement,
        privilege: 'SwabberManagement',
        icon: People
      },
      {
        title: 'Waiting Room',
        value: urlLabel.waitingRoom,
        privilege: 'WaitingRoom',
        icon: ClockIcon
      },
      {
        title: 'Consult List',
        value: urlLabel.consultList,
        privilege: 'ConsultList',
        icon: ListRounded
      },
      {
        title: 'Meeting History',
        value: urlLabel.meetingHistory,
        privilege: 'MeetingHistory',
        icon: HistoryIcon
      },
      {
        title: 'Clinic Management',
        value: urlLabel.clinicManagementMenu,
        privilege: 'ClinicManagement',
        icon: ClinicIcon,
        isOpen: false,
        children: [
          {
            title: 'Clinic Management',
            privilege: 'ClinicOperatingHour',
            value: urlLabel.clinicManagement
          },
          {
            title: 'Manage Booking Time',
            privilege: 'BookingTime',
            value: urlLabel.bookingManagement
          },
          {
            title: 'Manage Booking Time (SA)',
            privilege: 'BookingTimeSA',
            value: urlLabel.bookingManagementSupervisedArt
          }
        ]
      },
      {
        title: 'Queue Request',
        privilege: 'QueueRequest',
        value: urlLabel.queueManagement,
        icon: QueueIcon
      },
      {
        title: 'Appointment Management',
        privilege: 'BookedAppointment',
        value: urlLabel.appointmentManagement,
        icon: EventAvailableRounded
      },
      {
        title: 'MHS Management',
        privilege: 'MHSManagement',
        value: urlLabel.mhsManagement,
        icon: DoctorIcon
      },
      {
        title: 'Promotion',
        privilege: 'Promotion',
        value: urlLabel.promotion,
        icon: Loyalty
      }
    ]
  },
  {
    title: 'TeleFit',
    isOpen: false,
    isGroupItem: true,
    value: 'TeleFit',
    children: [
      {
        title: 'Workout',
        value: urlLabel.workoutManagement,
        privilege: 'Workout',
        icon: WorkoutIcon,
        isOpen: false,
        children: [
          {
            title: 'Categories',
            value: urlLabel.categoryManagement,
            privilege: 'Category'
          },
          {
            title: 'On Demand',
            value: urlLabel.onDemandManagement,
            privilege: 'OnDemand'
          },
          {
            title: 'Studio Classes',
            value: urlLabel.studioClassesManagement,
            privilege: 'StudioClass'
          },
          {
            title: 'Livestream',
            value: urlLabel.livestreamManagement,
            privilege: 'Livestream',
            children: [
              {
                value: urlLabel.livestreamSectionDetail
              }
            ]
          }
        ]
      },

      {
        title: 'Upcoming Workouts',
        privilege: 'UpcomingWorkout',
        value: urlLabel.upcomingWorkoutManagement,
        icon: DirectionsBikeRounded
      },
      {
        title: 'Studio Management',
        privilege: 'StudioManagement',
        value: urlLabel.studioManagement,
        icon: LocationIcon
      },
      {
        title: 'Trainer Management',
        privilege: 'TrainerManagement',
        value: urlLabel.trainerManagement,
        icon: DoctorIcon
      },
      {
        title: 'Internal Trainer Management',
        privilege: 'UserManagement',
        value: urlLabel.userManagement,
        icon: DoctorIcon
      }
    ]
  },
  {
    title: 'TeleScreen',
    isGroupItem: true,
    isOpen: false,
    value: 'TeleScreen',
    children: [
      {
        title: 'Appointments',
        value: urlLabel.appointmentsMenu,
        privilege: 'TelescreenAppointments',
        icon: Appointments,
        isOpen: false,
        children: [
          {
            title: 'Appointments',
            privilege: 'TelescreenAppointments',
            value: urlLabel.appointments,
            children: [
              {
                value: urlLabel.checkInPatientInfo
              },
              { value: urlLabel.assignConsentForm },
              { value: urlLabel.aSCreateAppointment },
              { value: urlLabel.detailAppointment },
              { value: urlLabel.surveyDetail }
            ]
          },
          {
            title: 'Order List Export',
            privilege: 'OrderListExport',
            value: urlLabel.orderListExport
          },
          {
            title: 'Backroom Module',
            privilege: 'BackroomModule',
            value: urlLabel.backroomModule
          }
        ]
      },
      {
        title: 'Site Closing Report',
        privilege: 'SiteClosingReport',
        value: urlLabel.siteClosingReport,
        icon: Queues
      },
      {
        title: 'Queue',
        privilege: 'Queue',
        value: urlLabel.screenQueue,
        icon: Queues
      },
      {
        title: 'Result Inbox',
        privilege: 'ResultInbox',
        value: urlLabel.resultInbox,
        icon: DoctorIcon
      },
      {
        title: 'Projects',
        privilege: 'Projects',
        value: urlLabel.projects,
        icon: Projects,
        children: [
          {
            title: 'Company Management',
            privilege: 'CompanyManagement',
            value: urlLabel.companyManagement,
            children: [
              {
                value: urlLabel.createCompany
              },
              {
                value: urlLabel.editCompany
              },
              {
                value: urlLabel.createProject
              },
              {
                value: urlLabel.editProject
              },
              {
                value: urlLabel.projectManage
              }
            ]
          },
          {
            title: 'Location Management',
            privilege: 'LocationManagement',
            value: urlLabel.locationManagement,
            children: [
              {
                value: urlLabel.locationManagementCreate
              },
              {
                value: urlLabel.locationManagementEdit
              }
            ]
          },
          {
            title: 'Stations',
            value: urlLabel.stationManagement,
            privilege: 'StationManagement',
            children: [
              {
                value: urlLabel.createStation
              },
              {
                value: urlLabel.editStation
              }
            ]
          },
          {
            title: 'Time Slot Template',
            privilege: 'LocationTimeTemplateManagement',
            value: urlLabel.timeSlotTemplate,
            children: [
              {
                value: urlLabel.timeSlotTemplateCreate
              },
              {
                value: urlLabel.timeSlotTemplateEdit
              }
            ]
          }
        ]
      },
      {
        title: 'Services',
        privilege: 'Services',
        value: urlLabel.services,
        icon: Services,
        children: [
          {
            title: 'Tests',
            value: urlLabel.testManagement,
            privilege: 'Test'
          },
          {
            title: 'Profiles',
            value: urlLabel.healthProfiles,
            privilege: 'Profile',
            children: [
              {
                value: urlLabel.healthProfilesForm
              },
              {
                value: urlLabel.healthProfilesEditForm
              },
              {
                value: urlLabel.healthProfilesStatements
              }
            ]
          },

          {
            title: 'Packages',
            value: urlLabel.healthPackages,
            privilege: 'PackageManagement',
            children: [
              {
                value: urlLabel.healthPackagesForm
              },
              {
                value: urlLabel.healthPackagesEditForm
              }
            ]
          },
          {
            title: 'Measurements',
            value: urlLabel.measurementManagement,
            privilege: 'Measurement'
          },
          {
            title: 'Procedures',
            value: urlLabel.procedureManagement,
            privilege: 'Procedure'
          },
          {
            title: 'Radiologies',
            value: urlLabel.radiologyManagement,
            privilege: 'Radiology'
          },
          {
            title: 'Vaccinations',
            value: urlLabel.vaccinationManagement,
            privilege: 'Vaccination'
          },
          {
            title: 'Reviews',
            value: urlLabel.reviewManagement,
            privilege: 'Review'
          },
          {
            title: 'Motherhood Statements',
            value: urlLabel.motherhoodStatement,
            privilege: 'MotherhoodStatement'
          },
          {
            title: 'Questionnaires',
            value: urlLabel.screeningQuestionnaires,
            privilege: 'Questionnaires'
          }
        ]
      },
      {
        title: 'Consent Form',
        privilege: 'ConsentManagement',
        value: urlLabel.consentManagement,
        icon: DoctorIcon,
        children: [
          { value: urlLabel.createConsent },
          { value: urlLabel.editConsent }
        ]
      },
      {
        title: 'Survey',
        privilege: 'SurveyManagement',
        value: urlLabel.surveyManagement,
        icon: DoctorIcon,
        children: [
          { value: urlLabel.createSurvey },
          { value: urlLabel.editSurvey }
        ]
      }
    ]
  },
  {
    title: 'Others',
    isOpen: false,
    isGroupItem: true,
    value: 'Others',
    children: [
      {
        title: 'Admin Management',
        privilege: 'AdminManagement',
        value: urlLabel.adminManagement,
        icon: DoctorIcon
      },
      {
        title: 'Banner Management',
        privilege: 'BannerManagement',
        value: urlLabel.bannerManagement,
        icon: Banner
      },
      {
        title: 'User Permission',
        privilege: 'UserPermission',
        value: urlLabel.userPermission,
        icon: DoctorIcon
      },
      {
        title: 'Lab Result',
        privilege: 'LabResult',
        value: urlLabel.labResult,
        icon: LabResult
      },
      {
        title: 'Deletion Request',
        privilege: 'DeletionRequest',
        value: urlLabel.deletionRequest,
        icon: Deletion
      }
    ]
  },
  {
    title: 'Logout',
    value: 'logout',
    icon: SettingIcon
  }
];

export const MENU_ITEMS = [
  {
    title: 'Waiting Room',
    value: urlLabel.waitingRoom,
    privilege: 'WaitingRoom',
    icon: ClockIcon
  },
  {
    title: 'Meeting History',
    value: urlLabel.meetingHistory,
    privilege: 'MeetingHistory',
    icon: HistoryIcon
  },
  {
    title: 'Doctor Management',
    value: urlLabel.doctorManagement,
    privilege: 'DoctorManagement',
    icon: DoctorIcon
  },
  {
    title: 'CA Management',
    value: urlLabel.caManagement,
    privilege: 'CAManagement',
    icon: CaIcon
  },
  {
    title: 'Consult List',
    value: urlLabel.consultList,
    privilege: 'ConsultList',
    icon: ListRounded
  },
  {
    title: 'Patient Management',
    value: urlLabel.patientManagement,
    privilege: 'PatientManagement',
    icon: PatientIcon
  },
  {
    title: 'Clinic Management',
    value: urlLabel.clinicManagementMenu,
    privilege: 'ClinicManagement',
    icon: ClinicIcon,
    isOpen: false,
    children: [
      {
        title: 'Clinic Management',
        privilege: 'ClinicOperatingHour',
        value: urlLabel.clinicManagement
      },
      {
        title: 'Manage Booking Time',
        privilege: 'BookingTime',
        value: urlLabel.bookingManagement
      },
      {
        title: 'Manage Booking Time For Supervised Art',
        privilege: 'BookingTimeSA',
        value: urlLabel.bookingManagementSupervisedArt
      }
    ]
  },
  {
    title: 'Workout',
    value: urlLabel.workoutManagement,
    privilege: 'Workout',
    icon: WorkoutIcon,
    isOpen: false,
    children: [
      {
        title: 'Categories',
        value: urlLabel.categoryManagement,
        privilege: 'Category'
      },
      {
        title: 'On Demand',
        value: urlLabel.onDemandManagement,
        privilege: 'OnDemand'
      },
      {
        title: 'Studio Classes',
        value: urlLabel.studioClassesManagement,
        privilege: 'StudioClass'
      },
      {
        title: 'Livestream',
        value: urlLabel.livestreamManagement,
        privilege: 'Livestream',
        children: [
          {
            value: urlLabel.livestreamSectionDetail
          }
        ]
      }
    ]
  },
  {
    title: 'Queue Request',
    privilege: 'QueueRequest',
    value: urlLabel.queueManagement,
    icon: QueueIcon
  },
  {
    title: 'Upcoming Workouts',
    privilege: 'UpcomingWorkout',
    value: urlLabel.upcomingWorkoutManagement,
    icon: DirectionsBikeRounded
  },
  {
    title: 'Services',
    privilege: 'Services',
    value: urlLabel.services,
    icon: Services,
    children: [
      {
        title: 'Tests',
        value: urlLabel.testManagement,
        privilege: 'Test',
        children: [
          {
            value: urlLabel.testForm
          },
          {
            value: urlLabel.testEditForm
          }
        ]
      },
      {
        title: 'Profiles',
        value: urlLabel.healthProfiles,
        privilege: 'Profile',
        children: [
          {
            value: urlLabel.healthProfilesForm
          },
          {
            value: urlLabel.healthProfilesEditForm
          },
          {
            value: urlLabel.healthProfilesStatements
          }
        ]
      },

      {
        title: 'Packages',
        value: urlLabel.healthPackages,
        privilege: 'PackageManagement',
        children: [
          {
            value: urlLabel.healthPackagesForm
          },
          {
            value: urlLabel.healthPackagesEditForm
          }
        ]
      },
      {
        title: 'Measurements',
        value: urlLabel.measurementManagement,
        privilege: 'Measurement',
        children: [
          {
            value: urlLabel.measurementForm
          },
          {
            value: urlLabel.measurementEditForm
          }
        ]
      },
      {
        title: 'Procedures',
        value: urlLabel.procedureManagement,
        privilege: 'Procedure',
        children: [
          {
            value: urlLabel.procedureForm
          },
          {
            value: urlLabel.procedureEditForm
          }
        ]
      },
      {
        title: 'Radiologies',
        value: urlLabel.radiologyManagement,
        privilege: 'Radiology',
        children: [
          {
            value: urlLabel.radiologyForm
          },
          {
            value: urlLabel.radiologyEditForm
          }
        ]
      },
      {
        title: 'Vaccinations',
        value: urlLabel.vaccinationManagement,
        privilege: 'Vaccination',
        children: [
          {
            value: urlLabel.vaccinationForm
          },
          {
            value: urlLabel.vaccinationEditForm
          }
        ]
      },
      {
        title: 'Reviews',
        value: urlLabel.reviewManagement,
        privilege: 'Review',
        children: [
          {
            value: urlLabel.reviewnForm
          },
          {
            value: urlLabel.reviewEditForm
          }
        ]
      },
      {
        title: 'Motherhood Statements',
        value: urlLabel.motherhoodStatement,
        privilege: 'MotherhoodStatement'
      },
      {
        title: 'Questionnaires',
        value: urlLabel.screeningQuestionnaires,
        privilege: 'Questionnaires'
      }
    ]
  },
  {
    title: 'Projects',
    privilege: 'Projects',
    value: urlLabel.projects,
    icon: Projects,
    children: [
      {
        title: 'Company Management',
        privilege: 'CompanyManagement',
        value: urlLabel.companyManagement,
        children: [
          {
            value: urlLabel.createCompany
          },
          {
            value: urlLabel.editCompany
          },
          {
            value: urlLabel.createProject
          },
          {
            value: urlLabel.editProject
          },
          {
            value: urlLabel.projectManage
          }
        ]
      },
      {
        title: 'Location Management',
        privilege: 'LocationManagement',
        value: urlLabel.locationManagement,
        children: [
          {
            value: urlLabel.locationManagementCreate
          },
          {
            value: urlLabel.locationManagementEdit
          }
        ]
      },
      {
        title: 'Stations',
        value: urlLabel.stationManagement,
        privilege: 'StationManagement',
        children: [
          {
            value: urlLabel.createStation
          },
          {
            value: urlLabel.editStation
          }
        ]
      },
      {
        title: 'Time Slot Template',
        privilege: 'LocationTimeTemplateManagement',
        value: urlLabel.timeSlotTemplate,
        children: [
          {
            value: urlLabel.timeSlotTemplateCreate
          },
          {
            value: urlLabel.timeSlotTemplateEdit
          }
        ]
      }
    ]
  },
  {
    title: 'All Users',
    privilege: 'HomescreenAllUsers',
    value: urlLabel.userDetails,
    icon: AllUser,
    children: [
      {
        value: urlLabel.editUserDetail
      }
    ]
  },
  {
    title: 'Studio Management',
    privilege: 'StudioManagement',
    value: urlLabel.studioManagement,
    icon: LocationIcon
  },
  {
    title: 'Trainer Management',
    privilege: 'TrainerManagement',
    value: urlLabel.trainerManagement,
    icon: DoctorIcon
  },
  {
    title: 'Internal Trainer Management',
    privilege: 'UserManagement',
    value: urlLabel.userManagement,
    icon: DoctorIcon
  },
  {
    title: 'Promotion',
    privilege: 'Promotion',
    value: urlLabel.promotion,
    icon: Loyalty
  },
  {
    title: 'Appointment Management',
    privilege: 'BookedAppointment',
    value: urlLabel.appointmentManagement,
    icon: EventAvailableRounded
  },
  {
    title: 'MHS Management',
    privilege: 'MHSManagement',
    value: urlLabel.mhsManagement,
    icon: DoctorIcon
  },
  {
    title: 'Admin Management',
    privilege: 'AdminManagement',
    value: urlLabel.adminManagement,
    icon: DoctorIcon
  },
  {
    title: 'Banner Management',
    privilege: 'BannerManagement',
    value: urlLabel.bannerManagement,
    icon: Banner
  },
  {
    title: 'User Permission',
    privilege: 'UserPermission',
    value: urlLabel.userPermission,
    icon: DoctorIcon
  },
  {
    title: 'Appointments',
    value: urlLabel.appointmentsMenu,
    privilege: 'TelescreenAppointments',
    icon: Appointments,
    isOpen: false,
    children: [
      {
        title: 'Appointments',
        privilege: 'TelescreenAppointments',
        value: urlLabel.appointments,
        children: [
          {
            value: urlLabel.checkInPatientInfo
          },
          { value: urlLabel.assignConsentForm },
          { value: urlLabel.aSCreateAppointment },
          { value: urlLabel.detailAppointment },
          { value: urlLabel.surveyDetail }
        ]
      },
      {
        title: 'Order List Export',
        privilege: 'OrderListExport',
        value: urlLabel.orderListExport
      },
      {
        title: 'Backroom Module',
        privilege: 'BackroomModule',
        value: urlLabel.backroomModule
      }
    ]
  },
  {
    title: 'Queue',
    privilege:
      'Thereisnoprivilegeforthismodule#364fff3a-9f14-4de0-8030-6d6079d364ff',
    value: urlLabel.screenQueue,
    icon: Queues
  },
  {
    title: 'Result Inbox',
    privilege: 'ResultInbox',
    value: urlLabel.resultInbox,
    icon: DoctorIcon
  },
  {
    title: 'Consent Form',
    privilege: 'ConsentManagement',
    value: urlLabel.consentManagement,
    icon: DoctorIcon,
    children: [
      { value: urlLabel.createConsent },
      { value: urlLabel.editConsent }
    ]
  },
  {
    title: 'Survey',
    privilege: 'SurveyManagement',
    value: urlLabel.surveyManagement,
    icon: DoctorIcon,
    children: [{ value: urlLabel.createSurvey }, { value: urlLabel.editSurvey }]
  },
  {
    title: 'Lab Result',
    privilege: 'LabResult',
    value: urlLabel.labResult,
    icon: LabResult
  },
  {
    title: 'Swabber Management',
    value: urlLabel.swabberManagement,
    privilege: 'SwabberManagement',
    icon: CaIcon
  },
  {
    title: 'Site Closing Report',
    value: urlLabel.siteClosingReport,
    privilege: 'SiteClosingReport',
    icon: Queues
  },
  {
    title: 'Deletion Request',
    privilege: 'DeletionRequest',
    value: urlLabel.deletionRequest,
    icon: Deletion
  },
  {
    title: 'Logout',
    value: 'logout',
    icon: SettingIcon
  }
];

const MenuItem = ({ history, userInfo, userRole, location }) => {
  const { privilege, groupedPrivilege, loading } = useSelector(
    state => state.userPermission
  );
  const [tabSelected, setTabSelected] = useState('');
  const [tabSelectedValue, setTabSelectedValue] = useState('');
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    if (_.isEmpty(tabSelected)) return;
    const path = tabSelected || '';
    if (path === 'logout') {
      waitingRoomDispatcher.clearWaitingList();
      userPermissionDispatcher.clearPermission();
      authDispatcher.logout();
    } else history.push(`/${path}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabSelected]);

  useEffect(() => {
    if (!!groupedPrivilege.length) {
      setMenuItems(groupedPrivilege);
    }
  }, [groupedPrivilege]);

  // useEffect(() => {
  //   if (!!privilege.length) {
  //     setMenuItems(privilege);
  //   }
  // }, [privilege]);

  useEffect(() => {
    const path = location.pathname.substring(1, location.pathname.length);
    let initTab = {};
    let tabSelectedValue = '';

    switch (path) {
      case urlLabel.home:
        if (!!privilege.length) {
          const firstPrivilege = privilege[0];
          if (firstPrivilege.value === 'logout') {
            initTab = { value: urlLabel.profileManagement };
            break;
          }
          if (firstPrivilege.children) {
            initTab = firstPrivilege.children[0];
          } else initTab = firstPrivilege;
          break;
        }
        break;
      case urlLabel.meetingHistory:
        initTab = MENU_ITEMS[1];
        break;
      case urlLabel.doctorManagement:
        initTab = MENU_ITEMS[2];
        break;
      case urlLabel.caManagement:
        initTab = MENU_ITEMS[3];
        break;
      case urlLabel.waitingRoom:
        initTab = MENU_ITEMS[0];
        break;
      case urlLabel.consultList:
        initTab = MENU_ITEMS[4];
        break;
      case urlLabel.patientManagement:
        initTab = MENU_ITEMS[5];
        break;
      case urlLabel.clinicManagementMenu:
        initTab = MENU_ITEMS[6];
        break;
      case urlLabel.clinicManagement:
        initTab = _.get(MENU_ITEMS, '[6]].children[0]');
        reOpenMenuItemParent(urlLabel.clinicManagementMenu);
        break;
      case urlLabel.bookingManagement:
        initTab = _.get(MENU_ITEMS, '[6]].children[1]');
        reOpenMenuItemParent(urlLabel.clinicManagementMenu);
        break;
      case urlLabel.bookingManagementSupervisedArt:
        initTab = _.get(MENU_ITEMS, '[6]].children[2]');
        reOpenMenuItemParent(urlLabel.clinicManagementMenu);
        break;
      case urlLabel.workoutManagement:
        initTab = MENU_ITEMS[7];
        break;
      case urlLabel.categoryManagement:
        initTab = _.get(MENU_ITEMS, '[7]].children[0]');
        reOpenMenuItemParent(urlLabel.workoutManagement);
        break;
      case urlLabel.onDemandManagement:
        initTab = _.get(MENU_ITEMS, '[7]].children[1]');
        reOpenMenuItemParent(urlLabel.workoutManagement);
        break;
      case urlLabel.studioClassesManagement:
        initTab = _.get(MENU_ITEMS, '[7].children[2]');
        reOpenMenuItemParent(urlLabel.workoutManagement);
        break;
      case urlLabel.livestreamManagement:
        initTab = _.get(MENU_ITEMS, '[7].children[3]');
        reOpenMenuItemParent(urlLabel.workoutManagement);
        break;
      case urlLabel.queueManagement:
        initTab = MENU_ITEMS[8];
        break;
      case urlLabel.upcomingWorkoutManagement:
        initTab = MENU_ITEMS[9];
        break;
      case urlLabel.testManagement:
      case urlLabel.testForm:
      case urlLabel.testEditForm:
        initTab = _.get(MENU_ITEMS, '[10]].children[0]');
        reOpenMenuItemParent(urlLabel.profilesAndTests);
        break;
      case urlLabel.healthProfiles:
      case urlLabel.healthProfilesForm:
      case urlLabel.healthProfilesEditForm:
      case urlLabel.healthProfilesStatements:
      case urlLabel.profilesAndTests:
        initTab = _.get(MENU_ITEMS, '[10]].children[1]');
        reOpenMenuItemParent(urlLabel.profilesAndTests);
        break;
      case urlLabel.healthPackages:
      case urlLabel.healthPackagesForm:
      case urlLabel.healthPackagesEditForm:
        initTab = _.get(MENU_ITEMS, '[10]].children[2]');
        reOpenMenuItemParent(urlLabel.profilesAndTests);
        break;
      case urlLabel.measurementManagement:
      case urlLabel.measurementForm:
      case urlLabel.measurementEditForm:
        initTab = _.get(MENU_ITEMS, '[10]].children[3]');
        reOpenMenuItemParent(urlLabel.profilesAndTests);
        break;
      case urlLabel.procedureManagement:
      case urlLabel.procedureForm:
      case urlLabel.procedureEditForm:
        initTab = _.get(MENU_ITEMS, '[10]].children[4]');
        reOpenMenuItemParent(urlLabel.profilesAndTests);
        break;
      case urlLabel.radiologyManagement:
      case urlLabel.radiologyForm:
      case urlLabel.radiologyEditForm:
        initTab = _.get(MENU_ITEMS, '[10]].children[5]');
        reOpenMenuItemParent(urlLabel.profilesAndTests);
        break;
      case urlLabel.vaccinationManagement:
      case urlLabel.vaccinationForm:
      case urlLabel.vaccinationEditForm:
        initTab = _.get(MENU_ITEMS, '[10]].children[6]');
        reOpenMenuItemParent(urlLabel.profilesAndTests);
        break;
      case urlLabel.reviewManagement:
      case urlLabel.reviewEditForm:
      case urlLabel.reviewnForm:
        initTab = _.get(MENU_ITEMS, '[10]].children[7]');
        reOpenMenuItemParent(urlLabel.profilesAndTests);
        break;
      case urlLabel.motherhoodStatement:
        initTab = _.get(MENU_ITEMS, '[10]].children[8]');
        reOpenMenuItemParent(urlLabel.profilesAndTests);
        break;
      case urlLabel.screeningQuestionnaires:
        initTab = _.get(MENU_ITEMS, '[10]].children[9]');
        reOpenMenuItemParent(urlLabel.screeningQuestionnaires);
        break;
      case urlLabel.companyManagement:
      case urlLabel.projects:
      case urlLabel.createProject:
      case urlLabel.editProject:
      case urlLabel.projectManage:
      case urlLabel.createCompany:
      case urlLabel.editCompany:
        initTab = _.get(MENU_ITEMS, '[11]].children[0]');
        reOpenMenuItemParent(urlLabel.projects);
        break;
      case urlLabel.locationManagementCreate:
      case urlLabel.locationManagementEdit:
      case urlLabel.locationManagement:
        initTab = _.get(MENU_ITEMS, '[11]].children[1]');
        reOpenMenuItemParent(urlLabel.projects);
        break;
      case urlLabel.stationManagement:
      case urlLabel.createStation:
      case urlLabel.editStation:
        initTab = _.get(MENU_ITEMS, '[11]].children[2]');
        reOpenMenuItemParent(urlLabel.projects);
        break;
      case urlLabel.timeSlotTemplateEdit:
      case urlLabel.timeSlotTemplateCreate:
      case urlLabel.timeSlotTemplate:
        initTab = _.get(MENU_ITEMS, '[11]].children[3]');
        reOpenMenuItemParent(urlLabel.projects);
        break;
      case urlLabel.editUserDetail:
      case urlLabel.userDetails:
        initTab = MENU_ITEMS[12];
        break;
      case urlLabel.studioManagement:
        initTab = MENU_ITEMS[13];
        break;
      case urlLabel.trainerManagement:
        initTab = MENU_ITEMS[14];
        break;
      case urlLabel.userManagement:
        initTab = MENU_ITEMS[15];
        break;
      case urlLabel.promotion:
        initTab = MENU_ITEMS[16];
        break;
      case urlLabel.appointmentManagement:
        initTab = MENU_ITEMS[17];
        break;
      case urlLabel.mhsManagement:
        initTab = MENU_ITEMS[18];
        break;
      case urlLabel.adminManagement:
        initTab = MENU_ITEMS[19];
        break;
      case urlLabel.bannerManagement:
        initTab = MENU_ITEMS[20];
        break;
      case urlLabel.userPermission:
        initTab = MENU_ITEMS[21];
        break;
      case urlLabel.appointmentsMenu:
        initTab = MENU_ITEMS[22];
        break;
      case urlLabel.appointments:
      case urlLabel.aSCreateAppointment:
      case urlLabel.detailAppointment:
      case urlLabel.checkInPatientInfo:
      case urlLabel.assignConsentForm:
      case urlLabel.surveyDetail:
        initTab = _.get(MENU_ITEMS, '[22]].children[0]');
        reOpenMenuItemParent(urlLabel.appointmentsMenu);
        break;
      case urlLabel.orderListExport:
        initTab = _.get(MENU_ITEMS, '[22]].children[1]');
        reOpenMenuItemParent(urlLabel.appointmentsMenu);
        break;
      case urlLabel.backroomModule:
        initTab = _.get(MENU_ITEMS, '[22]].children[2]');
        reOpenMenuItemParent(urlLabel.appointmentsMenu);
        break;
      case urlLabel.screenQueue:
        initTab = MENU_ITEMS[23];
        break;
      case urlLabel.resultInbox:
        initTab = MENU_ITEMS[24];
        break;
      case urlLabel.consentManagement:
      case urlLabel.createConsent:
      case urlLabel.editConsent:
        initTab = MENU_ITEMS[25];
        break;
      case urlLabel.surveyManagement:
      case urlLabel.createSurvey:
      case urlLabel.editSurvey:
        initTab = MENU_ITEMS[26];
        break;
      case urlLabel.labResult:
        initTab = MENU_ITEMS[27];
        break;
      case urlLabel.swabberManagement:
        initTab = MENU_ITEMS[28];
        break;
      case urlLabel.siteClosingReport:
        initTab = MENU_ITEMS[29];
        break;
      case urlLabel.deletionRequest:
        initTab = MENU_ITEMS[30];
        break;
      default:
        if (path.includes(urlLabel.healthProfiles)) {
          initTab = _.get(MENU_ITEMS, '[10]].children[1]');
          reOpenMenuItemParent(urlLabel.profilesAndTests);
        } else if (path.includes(urlLabel.healthPackages)) {
          initTab = _.get(MENU_ITEMS, '[10]].children[2]');
          reOpenMenuItemParent(urlLabel.profilesAndTests);
        } else if (path.includes(urlLabel.companyManagement)) {
          initTab = _.get(MENU_ITEMS, '[11]].children[0]');
          reOpenMenuItemParent(urlLabel.projects);
        } else if (path.includes(urlLabel.consentManagement)) {
          initTab = MENU_ITEMS[25];
        } else if (path.includes(urlLabel.livestreamManagement)) {
          initTab = _.get(MENU_ITEMS, '[7].children[3]');
        }
        break;
    }
    reOpenGroupItem(initTab, groupedPrivilege);
    setTabSelected(initTab.value);
    setTabSelectedValue(tabSelectedValue || initTab.value);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, privilege, groupedPrivilege]);

  const reOpenMenuItemParent = parentValue => {
    const listMenu = [...menuItems];
    const pos = menuItems.findIndex(item => item.value === parentValue);

    if (pos >= 0) {
      listMenu[pos].isOpen = true;
      setMenuItems(listMenu);
    }
  };

  const reOpenGroupItem = (initTab, menuItems) => {
    let groupItemIdx, itemIdx, subItemIdx;
    menuItems.forEach((menuItem, mnIdx) => {
      if (!menuItem.isGroupItem) return;
      // let idx = menuItem.children.findIndex(i => i.value === initTab.value);
      menuItem.children.forEach((mnItem, index) => {
        if (mnItem.value === initTab.value) {
          itemIdx = index;
          groupItemIdx = mnIdx;
        } else if (mnItem?.children) {
          let childIdx = mnItem.children.findIndex(
            i => i.value === initTab.value
          );
          if (childIdx >= 0) {
            groupItemIdx = mnIdx;
            itemIdx = index;
            subItemIdx = childIdx;
          }
        }
      });
    });
    if (itemIdx >= 0 && groupItemIdx >= 0) {
      const listMenu = [...menuItems];
      listMenu[groupItemIdx].isOpen = true;
      listMenu[groupItemIdx].children[itemIdx].isOpen = true;
      if (subItemIdx >= 0)
        listMenu[groupItemIdx].children[itemIdx].children[
          subItemIdx
        ].isOpen = true;
      setMenuItems(listMenu);
    }
  };

  const handleCollapse = (menuItem, parentIdx) => {
    const listMenu = [...menuItems];
    if (parentIdx === undefined) {
      const pos = menuItems.findIndex(item => item.value === menuItem.value);
      listMenu[pos].isOpen = !listMenu[pos].isOpen;
      setMenuItems(listMenu);
    } else {
      const pos = menuItems[parentIdx].children.findIndex(
        item => item.value === menuItem.value
      );
      listMenu[parentIdx].children[pos].isOpen = !listMenu[parentIdx].children[
        pos
      ].isOpen;
      setMenuItems(listMenu);
    }
  };

  const renderNewMenuItem = () => {
    return !privilege.length ? (
      <Spin />
    ) : (
      menuItems.map((menuItem, parentIdx) => {
        const Icon = menuItem.icon;
        if (menuItem.isGroupItem)
          return (
            <Fragment key={menuItem.title}>
              <ListItem
                button
                className="group-menu-item-title"
                onClick={() => handleCollapse(menuItem)}
              >
                {Icon && (
                  <ListItemIcon className="wrapper-icon">
                    <Icon className="icon" />
                  </ListItemIcon>
                )}
                <ListItemText className="title" primary={menuItem.title} />
                {menuItem.isOpen ? (
                  <ExpandLess className="icon" />
                ) : (
                  <ExpandMore className="icon" />
                )}
              </ListItem>
              <Collapse in={menuItem.isOpen} timeout="auto" unmountOnExit>
                <List>{renderMenuItem(menuItem.children, parentIdx)}</List>
              </Collapse>
            </Fragment>
          );
        else
          return (
            <ListItem
              button
              key={menuItem.title}
              selected={tabSelectedValue === menuItem.value}
              className="menu-item-custom"
              onClick={() => setTabSelected(menuItem.value)}
            >
              {Icon && (
                <ListItemIcon className="wrapper-icon">
                  <Icon className="icon" />
                </ListItemIcon>
              )}
              <ListItemText className="title" primary={menuItem.title} />
            </ListItem>
          );
      })
    );
  };

  const renderMenuItem = (menuItems1 = menuItems, parentIdx) => {
    return !privilege.length ? (
      <Spin />
    ) : (
      menuItems1.map(menuItem => {
        const Icon = menuItem.icon;
        if (_.get(menuItem, 'children[0].privilege')) {
          return (
            <Fragment key={menuItem.value}>
              <ListItem
                button
                key={menuItem.title}
                className="menu-item-custom"
                onClick={() => handleCollapse(menuItem, parentIdx)}
              >
                <ListItemIcon className="wrapper-icon">
                  <Icon className="icon" />
                </ListItemIcon>
                <ListItemText className="title" primary={menuItem.title} />
                {menuItem.isOpen ? (
                  <ExpandLess className="icon" />
                ) : (
                  <ExpandMore className="icon" />
                )}
              </ListItem>
              <Collapse in={menuItem.isOpen} timeout="auto" unmountOnExit>
                <List>
                  {(_.get(menuItem, 'children') || []).map(nestItem => (
                    <Link to={`/${nestItem.value}`} key={nestItem.value}>
                      <ListItem
                        selected={tabSelectedValue === nestItem.value}
                        className="menu-item-nested-custom"
                        button
                        key={nestItem.value}
                        onClick={() => setTabSelected(nestItem.value)}
                      >
                        <ListItemText
                          className="title"
                          primary={nestItem.title}
                        />
                      </ListItem>
                    </Link>
                  ))}
                </List>
              </Collapse>
            </Fragment>
          );
        }
        return (
          <Link to={`/${menuItem.value}`} key={menuItem.value}>
            <ListItem
              button
              key={menuItem.title}
              selected={tabSelectedValue === menuItem.value}
              className="menu-item-custom"
              onClick={() => setTabSelected(menuItem.value)}
            >
              <ListItemIcon className="wrapper-icon">
                <Icon className="icon" />
              </ListItemIcon>
              <ListItemText className="title" primary={menuItem.title} />
            </ListItem>
          </Link>
        );
      })
    );
  };

  return <List component="nav">{renderNewMenuItem()}</List>;
};

MenuItem.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    userInfo: state.auth.userInfo,
    userRole: state.auth.roleType
  };
};

export default connect(mapStateToProps, () => ({}))(
  withStyles(styles)(withRouter(MenuItem))
);

import { Chip, Switch, TextField, withStyles } from '@material-ui/core';
import React from 'react';

export const IOSChip = withStyles(theme => ({
  root: {
    backgroundColor: '#ffe9fb',
    color: '#ad5e99',

    borderRadius: '16px',
    height: '24px',
    padding: '2px 8px',
    fontWeight: 200,
    fontSize: '12px',
    '&:hover': {
      backgroundColor: '#E0E0E0'
    },
    '&.Home': {
      backgroundColor: 'rgba(147,101,232,0.16)',
      color: '#9365E8'
    },
    '&.Office': {
      backgroundColor: 'rgba(35,166,204,0.16)',
      color: '#23A6CC'
    },
    '&.Onsite': {
      backgroundColor: 'rgba(35,166,204,0.16)',
      color: '#23A6CC'
    }
  },
  label: {
    paddingLeft: '4px',
    paddingRight: '4px'
  }
}))(Chip);

export const IOSSwitch = withStyles(theme => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: 0
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#ad5e99',
        opacity: 1,
        border: 'none'
      },

      '&.MuiSwitch-colorPrimary.Mui-disabled + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: 'rgba(173, 94, 153, 0.3)'
      }
    },
    '&$focusVisible $thumb': {
      color: '#ad5e99',
      border: '6px solid #fff'
    },
    '&.MuiSwitch-colorPrimary.Mui-disabled': {
      color: theme.palette.common.white
    },
    '&.MuiSwitch-colorPrimary.Mui-disabled + .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: '#c1c1c1'
    }
  },
  thumb: {
    width: 20,
    height: 20,
    marginTop: 2,
    marginLeft: 2
  },
  track: {
    borderRadius: 26 / 2,
    backgroundColor: '#999',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border'])
  },
  checked: {},
  focusVisible: {}
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked
      }}
      {...props}
    />
  );
});

export const CustomSearchInput = withStyles(theme => ({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 8
    },

    '& input': {
      minWidth: 335,
      minHeight: 40,
      padding: 0
    }
  }
}))(TextField);

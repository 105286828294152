import { allUsersRoutes } from './all-users-routes';
import { clinicRoutes } from './clinic-routes';
import { healthScreeningRoutes } from './health-screening-routers';
import { mincAdminRoutes } from './minc-admin-routes';
import { teleconsultRoutes } from './teleconsult-routes';
import { telecounselRoutes } from './telecounsel-routes';
import { telefitRoutes } from './telefit-routes';
import { urlLabel } from '@/enum/PermissionEnum';
import React, { lazy } from 'react';

const ProfilePage = lazy(() => import('@/module/profile/pages/ProfilePage'));
const UserPermission = lazy(() => import('@/module/user-permission/component'));

const Home = () => <div></div>;
const ScreenQueue = () => <div></div>;
const ResultInbox = () => <div></div>;

const privaterouters = [
  ...allUsersRoutes,
  ...teleconsultRoutes,
  ...clinicRoutes,
  ...healthScreeningRoutes,
  ...telefitRoutes,
  ...mincAdminRoutes,
  ...telecounselRoutes,
  {
    path: `/${urlLabel.home}`,
    component: Home,
    exact: true,
  },
  {
    path: `/${urlLabel.screenQueue}`,
    component: ScreenQueue,
    exact: true,
  },
  {
    path: `/${urlLabel.resultInbox}`,
    component: ResultInbox,
    exact: true,
  },
  {
    path: `/${urlLabel.profileManagement}`,
    component: ProfilePage,
    exact: false,
  },
  {
    path: `/${urlLabel.userPermission}`,
    component: UserPermission,
    exact: true,
  },
];

export default privaterouters;

import allUsersDispatcher from '../../action';
import MedicalHistory from '../../component/MedicalHistory';
import {
  INIT_DATA_PE_DOCTOR,
  INIT_DATA_PE_PATIENT,
  ROLES_PHYSICALEXAMINATION,
  SURVEY_ANSWER_TYPE,
} from '../../constants';
import SurveyHistory from '../SurveyHistory';
import PersonalInformation from './PersonalInformation';
import PhysicalInformation from './PhysicalInformation';
import { PaperWrapped, HeaderWrapped } from './styled';
import LoadingButton from '@/components/LoadingButton';
import { urlLabel } from '@/enum/PermissionEnum';
import validateData from '@/helpers/validationHelpers/validationSchema';
import TabsUI from '@/module/common/componentUI/TabsUI';
import { ButtonEnhance } from '@/module/common/componentUI/commonStyleComponents';
import { TeleAppointmentManagement } from '@/module/tele-appointment';
import customToast from '@/new-components/CustomNotification';
import globalDispatcher from '@/redux/global/actions';
import { Typography } from '@material-ui/core';
import { get, isEmpty } from 'lodash';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

const UserDetail = () => {
  const { loading } = useSelector((state) => state.allUsers);
  const roleType = useSelector((state) => state.auth.roleType);

  const [userFullName, setUserFullName] = useState('');
  const [personalInfo, setPersonalInfo] = useState({
    countryCode: 64,
    identityType: 'NRIC',
  });
  const [errors, setErrors] = useState({});

  const [currentTab, setCurrentTab] = useState(0);
  const [isDoctorSide, setIsDoctorSide] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const location = useLocation();
  const history = useHistory();
  const userID = get(location, 'state.id');
  const [currentSelectValue, setCurrentSelectValue] = useState({});

  const physicalExamInfoDoctor = INIT_DATA_PE_DOCTOR.sections;
  const physicalExamInfoPatient = INIT_DATA_PE_PATIENT;
  const [dataPEDoctor, setDataPEDoctor] = useState({
    doctorJsonData: null,
    doctorSubmittedDate: null,
    paxScreeningId: null,
  });
  const [dataPEPatient, setDataPEPatient] = useState({
    patientJsonData: null,
    patientSubmittedDate: null,
    paxScreeningId: null,
  });
  const [physicalExamInfo, setPhysicalExamInfo] = useState([]);
  const [lstIdentityUrl, setLstIdentityUrl] = useState([]);

  useEffect(() => {
    if (!userID)
      return history.push({
        pathname: `/${urlLabel.userDetails}`,
      });

    allUsersDispatcher.getUserDetail(userID, (result) => {
      if (!isEmpty(result)) {
        setPersonalInfo(result);
        setUserFullName(result.fullName);
        fetchDataPhysicalHistories(result.id);
      }
    });
    if (userID) {
      globalDispatcher.getIdentityImages(userID, (data) => {
        setLstIdentityUrl(data);
      });
    }
  }, []);

  const resetDoctorJsonData = (dataDoctor) => {
    const resetData = dataDoctor.map((section) => ({
      ...section,
      options: section.options.map((opt) => ({
        ...opt,
        valueSelected: null,
        list: opt.list.map((ans) =>
          ans.hasOwnProperty('detail') ? { ...ans, detail: null } : ans
        ),
      })),
    }));
    return resetData;
  };

  const resetPatientJsonData = (dataClient) => {
    const resetData = dataClient.map((section) => ({
      ...section,
      questions: section.questions.map((ques) => ({
        ...ques,
        answers: ques.answers.map((ans) => ({
          ...ans,
          isSelected: false,
          patientNote: '',
        })),
      })),
    }));
    return resetData;
  };

  const isJsonString = (item) => {
    item = typeof item !== 'string' ? JSON.stringify(item) : item;

    try {
      item = JSON.parse(item);
    } catch (e) {
      return false;
    }

    if (typeof item === 'object' && item !== null) {
      return true;
    }

    return false;
  };
  const fetchDataPhysicalHistories = async (id, refreshAfterEdit = false) => {
    allUsersDispatcher.getPhysicalHistories(id, (data = []) => {
      const newData = data.map((d) => {
        const isCheckDoctor = isJsonString(d.doctorJsonData);
        const isCheckPatient = isJsonString(d.patientJsonData);

        const doctorJsonData = isCheckDoctor
          ? JSON.parse(d.doctorJsonData)
          : resetDoctorJsonData(physicalExamInfoDoctor);
        const patientJsonData = isCheckPatient
          ? JSON.parse(d.patientJsonData)
          : resetPatientJsonData(physicalExamInfoPatient);
        return {
          ...d,
          doctorJsonData,
          patientJsonData,
        };
      });
      if (!isEmpty(currentSelectValue) && !refreshAfterEdit) {
        if (
          currentSelectValue.doctorSubmittedDate ||
          currentSelectValue.patientSubmittedDate
        ) {
          const newCurrentSelect = newData.find(
            (item) => item.id === currentSelectValue.id
          );
          setCurrentSelectValue(newCurrentSelect);
        } else {
          if (isDoctorSide) {
            setCurrentSelectValue({
              ...currentSelectValue,
              doctorJsonData: resetDoctorJsonData(
                currentSelectValue.doctorJsonData
              ),
            });
          } else {
            setCurrentSelectValue({
              ...currentSelectValue,
              patientJsonData: resetPatientJsonData(
                currentSelectValue.patientJsonData
              ),
            });
          }
        }
      } else {
        setCurrentSelectValue(newData[0]);
      }
      if (!isEmpty(newData)) setPhysicalExamInfo(newData);
    });
  };

  const onCancel = () => history.goBack();

  const onSavePersonalInfo = async () => {
    try {
      await validateData(
        'personalInformationSchema',
        personalInfo,
        async () => {
          if (!isEmpty(personalInfo.lstIdentityUrl)) {
            try {
              const lstIdentityUrl = await uploadFiles(
                personalInfo.lstIdentityUrl
              );

              allUsersDispatcher.editPersonalInfo(
                personalInfo.id,
                { ...personalInfo, lstIdentityUrl },
                () => {
                  allUsersDispatcher.getUserDetail(
                    personalInfo.id,
                    (result) => {
                      if (!isEmpty(result)) {
                        setPersonalInfo(result);
                        setUserFullName(result.fullName);
                        fetchDataPhysicalHistories(result.id);
                      }
                    }
                  );
                  globalDispatcher.getIdentityImages(
                    personalInfo.id,
                    (data) => {
                      setLstIdentityUrl(data);
                    }
                  );
                }
              );
            } catch (error) {
              console.error('Failed to upload files:', error);
            }
          } else {
            allUsersDispatcher.editPersonalInfo(
              personalInfo.id,
              personalInfo,
              () => {
                allUsersDispatcher.getUserDetail(personalInfo.id, (result) => {
                  if (!isEmpty(result)) {
                    setPersonalInfo(result);
                    setUserFullName(result.fullName);
                    fetchDataPhysicalHistories(result.id);
                  }
                });
              }
            );
          }

          setErrors({});
        }
      );
    } catch (err) {
      setErrors(err);
    }
  };

  const uploadFileAndGetURL = async (file) => {
    const timeStamp = new Date().getTime();
    const fileName = `${timeStamp}${file.name}`;
    const formdata = new FormData();
    formdata.append('file', file, fileName);

    return new Promise((resolve, reject) => {
      globalDispatcher.uploadIdentityImage(formdata, (result) => {
        if (result) {
          resolve(result);
        } else {
          reject('Upload failed');
        }
      });
    });
  };

  const uploadFiles = async (fileList) => {
    const uploadPromises = fileList.map((file) => {
      if (file?.constructor === File) {
        return uploadFileAndGetURL(file);
      }
      return Promise.resolve(file); // Trả về file nếu nó không phải là một đối tượng File
    });

    return Promise.all(uploadPromises);
  };

  const validatePhysicalExam = () => {
    // Doctor PE
    const dataDoctor = dataPEDoctor.doctorJsonData;
    const dataPatient = dataPEPatient.patientJsonData;

    let isNoPatientNote = false;
    let isNoDoctortNote = false;
    let isForceSelected = false;
    let isSelectAtLeastOne = false;

    dataDoctor.forEach((data) => {
      data.options.forEach((option) => {
        const noDoctorNote = option.list.some(
          (item) =>
            item.hasOwnProperty('detail') &&
            item.value === option.valueSelected &&
            isEmpty(item.detail)
        );
        if (noDoctorNote) isNoDoctortNote = true;
      });
    });

    // Client PE
    dataPatient.forEach((data) => {
      data.questions.forEach((question) => {
        const newOtionsParent = data.questions.find(
          (item) => item.id === question.mapParentQuestionId
        );

        const newOtionsChild = data.questions.find(
          (item) => item.mapParentQuestionId === question.mapParentQuestionId
        );

        const newOtionsChildSingle = data.questions.filter(
          (item) => item.answerType === SURVEY_ANSWER_TYPE.SingleChoice
        );

        const noPatientNote = question.answers.some(
          (answer) =>
            answer.isAdditional &&
            answer.isSelected &&
            isEmpty(answer.patientNote)
        );
        if (noPatientNote) isNoPatientNote = true;

        if (newOtionsParent && newOtionsChild) {
          const isParentActive = newOtionsParent?.answers.find(
            (item) => item.isSelected
          );
          const isChildActive = newOtionsChild.answers.filter(
            (answer) => answer.isSelected
          );

          if (isParentActive) {
            if (!isEmpty(newOtionsChildSingle)) {
              let isChildActive = [];
              let isChildTotal = [];
              newOtionsChildSingle.forEach((item) => {
                const itemSelected = item.answers.find((it) => it.isSelected);
                isChildTotal = isChildTotal.concat(item.answers);
                if (itemSelected) isChildActive.push(itemSelected);
              });

              // Need to divide into 2 for single choice select, 1 question have 2 answers (YES and NO)
              if (isChildTotal.length / 2 === isChildActive.length) {
                isForceSelected = false;
              } else isForceSelected = true;
            }
            if (!isEmpty(isChildActive)) {
              isSelectAtLeastOne = false;
            } else {
              isSelectAtLeastOne = true;
            }
          }
        }
      });
    });

    if (isNoPatientNote)
      setErrors({ ...errors, patientNote: 'The detail is required!' });
    if (isNoDoctortNote)
      setErrors({ ...errors, doctorNote: 'The detail is required!' });
    else if (isSelectAtLeastOne) {
      setErrors({
        ...errors,
        atLeastChooseAnswer: 'You need to choose at least option!',
      });
    }
    if (isForceSelected) {
      setErrors({
        ...errors,
        forceAnswer: 'This question is required!',
      });
    }

    return {
      isNoPatientNote,
      isForceSelected,
      isSelectAtLeastOne,
      isNoDoctortNote,
    };
  };
  const onSavePhysicalExam = async () => {
    const {
      isNoPatientNote,
      isForceSelected,
      isSelectAtLeastOne,
      isNoDoctortNote,
    } = validatePhysicalExam();
    try {
      if (
        isNoPatientNote ||
        isForceSelected ||
        isSelectAtLeastOne ||
        isNoDoctortNote
      ) {
        customToast(
          'error',
          'Please enter the answer before saving your change!'
        );
      } else {
        if (dataPEDoctor.paxScreeningId) {
          const doctorJsonData = JSON.stringify(dataPEDoctor.doctorJsonData);

          const patientJsonData =
            // Doctor PE submit without client PE
            isDoctorSide && !dataPEPatient.patientSubmittedDate
              ? null
              : JSON.stringify(dataPEPatient.patientJsonData);

          allUsersDispatcher.updateDoctorPE(
            {
              paxScreeningId: dataPEDoctor.paxScreeningId,
              patientJsonData,
              doctorJsonData,
            },
            () => {
              fetchDataPhysicalHistories(personalInfo.id, true);
              setIsEdit(false);
              setErrors({});
            }
          );
        }
      }
    } catch (err) {
      setErrors(err);
    }
  };

  const userDetailTabs = [
    {
      label: 'Personal Information',
      body: (
        <PersonalInformation
          errors={errors}
          personalInfo={personalInfo}
          setPersonalInfo={setPersonalInfo}
          lstIdentityUrl={lstIdentityUrl}
        />
      ),
      header: (
        <div className="personal-btn">
          <ButtonEnhance
            background="#E9E9E9"
            color="black"
            backgroundHover="#ccc"
            onClick={onCancel}
          >
            Cancel
          </ButtonEnhance>
          <LoadingButton onClick={onSavePersonalInfo} showLoader={loading}>
            Save Change
          </LoadingButton>
        </div>
      ),
    },
    {
      label: 'Appointments',
      body: <TeleAppointmentManagement userId={userID} showToolbar={false} />,
    },
    {
      label: 'Medical History',
      body: <MedicalHistory userId={userID} />,
    },
    {
      label: 'Physical Examination',
      body: (
        <PhysicalInformation
          isDoctorSide={isDoctorSide}
          isEdit={isEdit}
          setIsDoctorSide={setIsDoctorSide}
          dataPEDoctor={dataPEDoctor}
          setDataPEDoctor={setDataPEDoctor}
          dataPEPatient={dataPEPatient}
          setDataPEPatient={setDataPEPatient}
          physicalExamInfo={physicalExamInfo}
          currentSelectValue={currentSelectValue}
          setCurrentSelectValue={setCurrentSelectValue}
          errors={errors}
        />
      ),
      header: isEdit ? (
        <div className="personal-btn">
          <ButtonEnhance
            background="#E9E9E9"
            color="black"
            backgroundHover="#ccc"
            onClick={() => {
              fetchDataPhysicalHistories(personalInfo.id);
              setIsEdit(false);
              setErrors({});
            }}
          >
            Cancel
          </ButtonEnhance>
          <LoadingButton onClick={onSavePhysicalExam} showLoader={loading}>
            Save Change
          </LoadingButton>
        </div>
      ) : (
        ROLES_PHYSICALEXAMINATION.includes(roleType) && (
          <LoadingButton onClick={() => setIsEdit(true)} showLoader={loading}>
            Edit
          </LoadingButton>
        )
      ),
    },
    {
      label: 'Survey',
      body: <SurveyHistory personalInfo={personalInfo} />,
    },
    {
      label: 'Health Analytics',
      body: <div>Coming soon...</div>,
    },
    {
      label: 'Family Information',
      body: <div>Coming soon...</div>,
    },
    {
      label: 'Engagements',
      body: <div>Coming soon...</div>,
    },
    {
      label: 'Health Profile',
      body: <div>Coming soon...</div>,
    },
  ];

  return (
    <PaperWrapped>
      <HeaderWrapped>
        <Typography className="title">{userFullName}</Typography>
        {userDetailTabs[currentTab].header}
      </HeaderWrapped>
      <TabsUI
        listTab={userDetailTabs}
        value={currentTab}
        onChange={(e, val) => {
          setCurrentTab(val);
        }}
        renderLabel="label"
        renderKey="label"
      />
      {userDetailTabs[currentTab].body}
    </PaperWrapped>
  );
};

export default UserDetail;

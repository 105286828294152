/**
 * Author: Quan Vo
 * Date: 2020-01-08
 */
import fetchHelper from './FetchHelper';
import { devLog } from './index';
import { debounced } from './CommonHelper';
import { authDispatcher } from '../module/auth';
import customToast from '@/new-components/CustomNotification';

export const API_URL = process.env.REACT_APP_API_ROOT;

const Api = {
  async get(url, params, headers, showError) {
    url = createUrl(url, params);
    return await request(url, { params, headers, showError });
  },

  async post(url, body, params, headers, showError, isFormSubmit) {
    url = createUrl(url, params);
    return await request(url, {
      method: 'POST',
      body,
      headers,
      showError,
      isFormSubmit
    });
  },

  async put(url, body, params, headers, showError, isFormSubmit) {
    url = createUrl(url, params);
    return await request(url, {
      method: 'PUT',
      body,
      headers,
      showError,
      isFormSubmit
    });
  },

  async delete(url, params, headers, showError) {
    url = createUrl(url, params);
    return await request(url, { method: 'DELETE', params, headers, showError });
  }
};

export default Api;

const createUrl = (url, params) => {
  const args = [];
  for (const key in params) {
    if (
      params.hasOwnProperty(key) &&
      params[key] !== undefined &&
      params[key] !== null &&
      params[key] !== ''
    ) {
      const value = params[key];
      args.push(`${key}=${value}`);
    }
  }
  url = args.length > 0 ? url + '?' + args.join('&') : url;
  return API_URL + url;
};

const request = async (
  url,
  {
    method = 'GET',
    params,
    body,
    headers,
    showError = true,
    isFormSubmit = false
  }
) => {
  try {
    devLog(
      // eslint-disable-next-line no-useless-concat
      '%c' + 'request ' + method + ':' + url,
      'color: #2196F3',
      params || body || ''
    );
    // trim search param
    if (body && typeof body.search === 'string')
      body.search = body.search.trim();
    const config = { method };
    if (body) {
      if (body instanceof FormData) {
        config.body = body;
      } else config.body = JSON.stringify(body);
    }
    if (headers) config.headers = headers;
    const [data, status] = await fetchHelper.fetch(url, config, isFormSubmit);

    // eslint-disable-next-line no-useless-concat
    devLog('%c' + 'response ' + method + ':' + url, 'color: #4CAF50', data);
    if (status === UNAUTHORIZED_CODE) {
      debounced(() => {
        authDispatcher.logout(true);
        customToast('error', 'Token was expired!');
      }, 3000);
      return { status };
    }
    let result = data instanceof Blob ? data : data.data;

    if (SUCCESS_CODE.includes(status)) return { result, status };

    devLog('data.message', data.Message);

    const checkErrorModalMessage = data?.message?.includes(
      'Model validation Error(s)'
    );

    if (checkErrorModalMessage) {
      const handleErrorMessage = Object.values(
        data?.modelValidationErrors
      ).join(', ');

      showError && customToast('error', handleErrorMessage);
    } else
      showError &&
        customToast(
          'error',
          data.Message ||
            data.message ||
            (status === 403 && 'Permission denied') ||
            'Something went wrong'
        );
    return { result: null, message: data.message, status };
  } catch (exception) {
    devLog('exception', exception);
  }
};

const SUCCESS_CODE = [200, 201, 204];
const UNAUTHORIZED_CODE = 401;

const geoUrl = 'https://www.sglocate.com/api/json/searchwithpostcode.aspx';

// /**
//  * https://github.com/Rob--W/cors-anywhere
//  */
const proxy = ' https://cors-anywhere.herokuapp.com/';

export const getAddressByCode = async (code = 560727) => {
  // for test
  // return Promise.resolve(sampleRes);

  const formData = new FormData();
  formData.append(
    'APIKey',
    '8228DBB7B4C2491F9DDCBFE6BAC24FA43BB6C4B5B8394152A45C4DAE0D323431'
  );
  formData.append(
    'APISecret',
    'E771D1461D2B4962BE40E5CA11D4AFD16E226CBEAFDD49929BAE35AAFC406498'
  );
  formData.append('Postcode', code);
  const data = new URLSearchParams();
  for (const pair of formData) {
    data.append(pair[0], pair[1]);
  }
  const rs = await fetch(proxy + geoUrl, {
    method: 'POST',
    body: data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  }).then(res => res.json());
  return rs;
};

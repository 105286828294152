import { synthesize } from 'redux-dispatcher';
import { uploadImage } from '@/firebase';
import { formatUTCDate, createDownloadableLink } from '@/helpers';
import moment from 'moment';
import { FORMAT_DD_MM_YYYY } from '@/module/company/constants';

const mapDispatchToAC = {
  getDataSuccess: data => ({ data }),
  getData: (
    search,
    paging,
    filterOption = {},
    sortOption = {},
    patientId
  ) => async ({ Api, getState }) => {
    let pagingData = paging || getState().upcomingHS.paging;
    const payload = {
      pageIndex: pagingData.pageIndex,
      pageSize: pagingData.pageSize,
      search,
      ...filterOption,
      startDate: filterOption?.startDate
        ? formatUTCDate(filterOption?.startDate, { isStarOfDay: true })
        : undefined,
      endDate: filterOption?.endDate
        ? formatUTCDate(filterOption?.endDate, { isEndOfDay: true })
        : undefined,
      ...sortOption,
      patientId
    };

    let { result, status } = await Api.post(
      `PatientAppointments/filter-paxscreenings`,
      payload
    );

    status === 200 && upcomingHSDispatcher.getDataSuccess(result);
  },
  resetData: () => ({}),
  getConsentsByPatientID: (id, callback) => async ({ Api }) => {
    const { result, status } = await Api.get(
      `PaxConsents/by-pax-screening/${id}`
    );
    status === 200 && callback && callback(result);
  },
  getConsentsByServices: (data, callback) => async ({ Api }) => {
    const { result, status } = await Api.post(
      `Consents/get-test-consents`,
      data
    );
    status === 200 && callback && callback(result);
  },
  cancelTeleAppointment: (id, callback) => async ({ Api }) => {
    const { status } = await Api.put(`PaxScreenings/${id}/cancel`);
    status === 200 && callback && callback();
  },
  setState: (state, value) => ({ state, value }),
  signConsentForm: (id, data, callback) => async ({ Api, customToast }) => {
    upcomingHSDispatcher.setState('loading', true);

    let { staffSignatureUrl } = data;
    if (data.staffSignatureUrl?.constructor === File) {
      staffSignatureUrl = await uploadImage(data.staffSignatureUrl);
    }

    const { status } = await Api.put(`PaxConsents/sign/${id}`, {
      ...data,
      staffSignatureUrl
    });
    upcomingHSDispatcher.setState('loading', false);

    if (status === 200) {
      customToast('success', 'Sign Consent Form Success');
      callback && callback();
    }
  },
  getExportExcel: params => async ({ Api }) => {
    let { result, status } = await Api.post(`PatientAppointments/csv-report`, {
      ...params,
      byHealthScreening: !params.userId,
      startDate: params?.startDate
        ? formatUTCDate(params?.startDate, { isStarOfDay: true })
        : undefined,
      endDate: params?.endDate
        ? formatUTCDate(params?.endDate, { isEndOfDay: true })
        : undefined,
      createdStartDate: params?.createdStartDate
        ? formatUTCDate(params?.createdStartDate, { isStarOfDay: true })
        : undefined,
      createdEndDate: params?.createdEndDate
        ? formatUTCDate(params?.createdEndDate, { isEndOfDay: true })
        : undefined
    });
    status === 200 && createDownloadableLink(result, 'Result');
  },
  editTeleAppointment: (params, cb) => async ({ Api }) => {
    const { id, timeSlotId, date, isSuppression, healthScreeningId } = params;
    let { status } = await Api.put(
      `PatientAppointments/${id}/update-paxscreening`,
      {
        timeSlotId,
        date: moment(date).format('YYYY-MM-DD'),
        isSuppression,
        healthScreeningId
      }
    );
    if (status === 200) {
      cb && cb({ isSuccess: true });
    } else {
      cb && cb({ isSuccess: false });
    }
  },
  downloadReportByStatus: (id, shortId) => async ({ Api }) => {
    const response = await Api.post(
      `PaxScreenings/${id}/export-profile-statement`,
      {}
    );

    const { status, result } = response;

    const fileName = `${shortId}/hs-appointment/${Date.now()}`;

    status === 200 && createDownloadableLink(result, fileName, 'pdf');
  },
  getPaxScreening: (id, onSuccess, onFail) => async ({ Api }) => {
    const { status, result } = await Api.get(
      `AnalysisPaxScreenings/export-report-by-id/${id}`
    );
    status === 200 ? onSuccess(result) : onFail();
  },
  getPaxScreeningWithToken: (id, accessToken, onSuccess, onFail) => async ({
    Api
  }) => {
    if (accessToken) {
      const {
        status,
        result
      } = await Api.get(
        `AnalysisPaxScreenings/export-report-by-id/${id}`,
        undefined,
        { Authorization: `Bearer ${accessToken}` }
      );

      status === 200 ? onSuccess(result) : onFail();
    }
  },
  createAppointment: (params, callback) => async ({ Api }) => {
    const response = await Api.post(`PaxScreenings/staff-register`, {
      ...params
    });

    const { status } = response;
    if (status === 200) {
      callback && callback({ isSuccess: true });
    } else {
      callback && callback({ isSuccess: false });
    }
  },
  patientCheckInLocation: (id, callback) => async ({ Api }) => {
    const { status } = await Api.put(`PaxScreenings/${id}/check-in-location`);
    if (status === 200) {
      callback && callback();
    }
  },
  followUpAppointment: (id, params, callback) => async ({ Api }) => {
    const response = await Api.post(`PaxScreenings/${id}/follow-up-appt`, {
      ...params
    });

    const { status } = response;
    if (status === 200) {
      callback && callback({ isSuccess: true });
    } else {
      callback && callback({ isSuccess: false });
    }
  },
  downloadIRReport: (id, callback) => async ({ Api, customToast }) => {
    const { status, result } = await Api.get(
      `AnalysisPaxScreenings/${id}/download-ir-report`
    );
    if (status === 200) {
      customToast('success', 'Download success');
      createDownloadableLink(result, 'Health Screening Report', 'pdf');
    }
  },
  getLinkDownloadIRReport: (id, callback) => async ({ Api }) => {
    const { status, result } = await Api.get(
      `AnalysisPaxScreenings/${id}/download-ir-report`
    );
    if (status === 200) callback && callback(result);
  },
  generateIRReport: (id, token, paxScreening, callback) => async ({ Api }) => {
    const jsonData = JSON.stringify({
      token,
      paxScreening: {
        id,
        ...paxScreening
      }
    });
    const { status } = await Api.post(
      `AnalysisPaxScreenings/generate-ir-report`,
      {
        jsonData
      }
    );
    if (status === 200) {
      callback && callback();
    }
  },
  regenerateIRReport: id => async ({ Api, customToast }) => {
    const { status } = await Api.put(
      `AnalysisPaxScreenings/${id}/regenerate-ir-report`
    );
    if (status === 200) {
      customToast('success', 'Regenerate success');
    }
  },
  revertAppoinmentToUpcoming: (id, callback) => async ({
    Api,
    customToast
  }) => {
    const { status } = await Api.put(`PaxScreenings/${id}/revert`);
    if (status === 200) {
      callback && callback();
    }
  },

  doneAllConsents: (id, callback) => async ({ Api, customToast }) => {
    const { status } = await Api.put(`PaxScreenings/${id}/done-all-consents`);
    if (status === 200) {
      customToast('success', 'Update all consents success');
      callback && callback();
    }
  }
};

const upcomingHSDispatcher = synthesize('upcomingHS', mapDispatchToAC);

export default upcomingHSDispatcher;

import dispatcher from '../../action';
import DataMedical from './DataMedical';
import FamilyMedical from './FamilyMedical';
import MedicalItem from './MedicalItem';
import WrappedContainer from '@/components/WrappedContainer';
import customToast from '@/new-components/CustomNotification';
import { makeStyles, Grid, TextField, MenuItem } from '@material-ui/core';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useState, useEffect } from 'react';

const useStyles = makeStyles({
  root: {
    margin: 0,
    padding: 0,
  },
  title: {
    fontWeight: 600,
    marginRight: 8,
  },
});

const MedicalHistory = ({ userId }) => {
  const [dateSubmit, setDateSubmit] = useState('');
  const [allDataMedical, setAllDataMedical] = useState();
  const [dataDateSubmits, setDataDateSubmits] = useState([]);
  const [currentData, setCurrentData] = useState({});

  const classes = useStyles();

  useEffect(() => {
    dispatcher.getAllMedicalHistory(userId, (data) => {
      if (data?.length > 0) {
        const newData = data.map((item) => ({
          ...item,
          creationTime: moment(item.creationTime).format('DD/MM/YYYY'),
        }));
        const lastIndex = 0;
        setDataDateSubmits(newData.map((item) => item.creationTime));
        setAllDataMedical(newData);
        setDateSubmit(newData[lastIndex].creationTime);
      }
    });
  }, []);

  useEffect(() => {
    if (dateSubmit) {
      const currentData = allDataMedical.find(
        (item) => item.creationTime === dateSubmit
      );
      if (!currentData) {
        setCurrentData({});
        return;
      }
      try {
        const dataParseJson = JSON.parse(currentData.jsonData);

        setCurrentData(dataParseJson);
      } catch (error) {
        customToast('error', 'Something went wrong');
        setCurrentData({});
      }
    }
  }, [dateSubmit]);

  const {
    generalInformation,
    familyMedicalHistory,
    presentComplaints,
    pastMedicalHistory,
    systemsReview,
  } = currentData;

  const keyPastMedicalHistoryDetail = [];
  for (let keyPast in pastMedicalHistory?.others) {
    if (pastMedicalHistory?.others[keyPast].details) {
      keyPastMedicalHistoryDetail.push(keyPast);
    }
  }

  return (
    <WrappedContainer>
      <div className={classes.root}>
        <Grid item xs={12} md={3}>
          <TextField
            select
            fullWidth
            label="Date submitted"
            value={dateSubmit}
            onChange={(e) => setDateSubmit(e.target.value)}
          >
            <MenuItem key="placeholder" value="" disabled>
              Select Date Submitted
            </MenuItem>
            {dataDateSubmits.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        {!isEmpty(currentData) && (
          <>
            <MedicalItem
              classes={classes}
              title="General Information"
              data={generalInformation}
            />
            <FamilyMedical
              title="Family Medical History"
              classes={classes}
              data={familyMedicalHistory}
            />
            <FamilyMedical
              title="Present Complaints"
              classes={classes}
              data={presentComplaints}
            />
            <DataMedical
              title="Past Medical History"
              classes={classes}
              data={pastMedicalHistory}
              prefix="others"
              keyHaveDetails={keyPastMedicalHistoryDetail}
            />
            <DataMedical
              title="Systems review"
              classes={classes}
              data={systemsReview}
              prefix=""
              isSystemReview
              keyHaveDetails={['currentMedication']}
            />
          </>
        )}
      </div>
    </WrappedContainer>
  );
};

export default MedicalHistory;

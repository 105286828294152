import { isEmpty } from 'lodash';
import { synthesize } from 'redux-dispatcher';

const mapDispatchToAC = {
  onGetWaitingList: data => ({ data }),
  onPTJoinRoom: (data, isJoin = true, isCurrentPT) => ({
    data,
    isJoin,
    isCurrentPT
  }),
  onRemovePT: (data, shouldRemove = true, isCurrentPT) => ({
    data,
    shouldRemove,
    isCurrentPT
  }),
  setState: (state, value) => ({ state, value }),
  updateLocalStream: streams => ({ streams }),
  updateRemoteStream: streams => ({ streams }),
  cancelConsult: callId => async ({ Api, customToast, getState }) => {
    let { status } = await Api.put(`Calls/${callId}/cancel`);
    if (status === 200) {
      customToast('success', 'Cancel Success');
    }
  },
  completeConsult: (callId, cb) => async ({ Api, customToast, getState }) => {
    let { status } = await Api.put(`Calls/${callId}/complete`);
    if (status === 200) {
      cb && cb();
      customToast('success', 'Complete Success');
    }
  },
  checkVerify: () => async ({ getState }) => {
    let { verifyingUserData } = getState().teleQueueRoom;
    if (!isEmpty(verifyingUserData))
      teleQueueRoomDispatcher.setState('verifyModalVisibility', true);
  },
  clearWaitingList: payload => ({ payload }),
  getMemberAppointment: (userId, cb) => async ({ Api }) => {
    const { status, result } = await Api.get(
      `DoctorAppointments/member-appointments?memberId=${userId}`
    );
    cb && cb();
    if (status === 200) {
      teleQueueRoomDispatcher.getMemberAppointmentSuccess(result || []);
    } else teleQueueRoomDispatcher.getMemberAppointmentSuccess([]);
  },
  getMemberAppointmentSuccess: data => ({ data }),
  resetState: () => ({}),
  changeTeleCounseltStatus: data => async ({ Api }) => {
    const { status } = await Api.put('FunctionsConfigurations', {
      function: 'TeleCounselling',
      active: data
    });
    status === 200 && teleQueueRoomDispatcher.getTeleCounselStatusSuccess(data);
  },

  getTeleConsultStatus: () => async ({ Api }) => {
    const { result, status } = await Api.get('FunctionsConfigurations', {
      function: 'TeleCounselling'
    });
    status === 200 &&
      teleQueueRoomDispatcher.getTeleCounselStatusSuccess(result);
  },

  getTeleCounsellingById: (id, cb) => async ({ Api }) => {
    const { result, status } = await Api.get(`TeleCounsellings/${id}`);
    status === 200 && cb(result);
  },
  getTeleCounselStatusSuccess: data => ({ data }),
  generateInvoice: (id, data) => async ({ Api, customToast }) => {
    let { status } = await Api.put(`TeleCounsellings/${id}/invoice`, data);
    if (status === 200) {
      customToast('success', 'Generate invoice successfully');
    }
  },
  checkTeleCounsellingPending: cb => async ({ Api }) => {
    const { result, status } = await Api.get(`TeleCounsellings/pending`);
    status === 200 && cb(result);
  }
};

const teleQueueRoomDispatcher = synthesize('teleQueueRoom', mapDispatchToAC);
export default teleQueueRoomDispatcher;

import { authDispatcher } from '../auth';
import { formatTotalPrice } from '../checkin-patient-information/util';
import TableCellFilted from '../common/componentUI/TableCellFilted';
import importHSAppointmentDispatcher from '../import-hs-appointments/action';
import { convertToFormData } from '../lab-result/utils';
import upcomingHSDispatcher from '../upcoming-health-screening/action';
import allScreeningsDispatcher from './action';
import GroupToolbar from './component/GroupToolbar';
import ModalBulkImport from './component/ModalBulkImport';
import { StatusComponent } from './component/StatusComponent';
import {
  HS_COLUMN_UPCOMING,
  HS_SERVICE_TYPES,
  HS_ALL_SCREENINGS_PAXSCREENING_STATUS,
  HS_PAXSCREENING_STATUS,
  HS_BILLING_TYPES,
} from './constants';
import {
  EditIcon,
  ExportIcon,
  NewLocation,
  ReverseIcon,
  CreateNewIcon,
  ClipboardIcon,
  EyeIcon,
  CloseCircle,
  ContractIcon,
  NoDataScreenings,
  RevertConfirmationIcon,
  RegenerateIcon,
  ArrowDownCircle,
} from '@/assets/svg';
import { urlLabel } from '@/enum/PermissionEnum';
import { getCostRange } from '@/helpers';
import CustomMenu from '@/new-components/CustomMenu';
import CustomMenuActions from '@/new-components/CustomMenuActions';
import ModalConfirmation from '@/new-components/CustomModal/ModalConfirmation';
import customToast from '@/new-components/CustomNotification';
import CustomPaperContainer from '@/new-components/CustomPaperContainer';
import CustomHeader from '@/new-components/CustomPaperContainer/CustomHeader';
import CustomTable from '@/new-components/CustomTable';
import CustomTooltip from '@/new-components/CustomTooltip';
import globalDispatcher from '@/redux/global/actions';
import { TableCell } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { MoreVertRounded } from '@material-ui/icons';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const AllScreenings = () => {
  const { list: data, paging } = useSelector((state) => state.upcomingHS);
  const { locations: listLocations } = useSelector(
    (state) => state.globalState
  );

  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [sortOption, setSortOption] = useState({});
  const [searchKey, setSearchKey] = useState('');
  const [paxScreenStatuses, setPaxScreenStatuses] = useState([]);
  const [serviceTypes, setServiceTypes] = useState([]);
  const [genders, setGenders] = useState([]);
  const [locationIds, setLocationIds] = useState([]);
  const [projectIds, setProjectIds] = useState([]);
  const [billingTypes, setBillingTypes] = useState([]);

  const [appointmentDate, setAppointmentDate] = useState([
    moment().startOf('month'),
    moment().endOf('date'),
  ]);
  const [createdDate, setCreatedDate] = useState([]);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showBulkImportModal, setShowBulkImportModal] = useState(false);
  const [showReverttModal, setShowRevertModal] = useState(false);

  const [selectedItem, setSelectedItem] = useState({});
  const [quickRange, setQuickRange] = useState(null);

  const [filterOption, setFilterOption] = useState({
    paxScreenStatuses: [],
    endDate: appointmentDate[1],
    startDate: appointmentDate[0],
  });
  const [maxPriceDefault, setMaxPriceDefault] = useState(0);

  const renderDateTime = (row) => {
    let startDateFormat, startTimeFormat;
    const { startDate } = row;

    if (!startDate) return null;

    startDateFormat = moment(startDate).format('DD/MM/YYYY');
    startTimeFormat = moment(startDate).format('LT');
    return `${startDateFormat} ${startTimeFormat}`;
  };

  const renderListActions = ({
    paxScreenStatus: status,
    startDate,
    paymentStatus,
    isSignedConsentForm,
  }) => {
    if (!status) return [];
    let listActions = [];
    switch (status) {
      case HS_PAXSCREENING_STATUS.Upcoming:
        var isCurrentDate = moment(startDate).isSame(new Date(), 'day');
        if (isCurrentDate) {
          listActions = listActions.concat([
            {
              key: 'check-in-location',
              icon: NewLocation,
              label: 'Check in',
              onClick: onCheckInLocation,
            },
          ]);
        }
        listActions = listActions.concat(
          {
            key: 'edit',
            icon: EditIcon,
            label: 'Edit appointment',
            styles: { width: 20, height: 20 },
            onClick: onEditAppointment,
          },
          {
            key: 'cancel',
            icon: CloseCircle,
            label: 'Cancel appointment',
            color: '#DB3D49',
            styles: { width: 18, height: 18 },
            onClick: () => {
              setAnchorEl(null);
              setShowCancelModal(true);
            },
          }
        );
        break;
      case HS_PAXSCREENING_STATUS.CheckIn:
        listActions = listActions.concat({
          key: 'view',
          icon: ClipboardIcon,
          label: 'View patient registration',
          styles: { width: 20, height: 20 },
          onClick: onViewRegistration,
        });
        if (!selectedItem?.isFollowUpAppointment) {
          listActions.push({
            key: 'revert',
            icon: ReverseIcon,
            label: 'Revert to Upcoming',
            styles: { width: 20, height: 20 },
            onClick: () => {
              setAnchorEl(null);
              setShowRevertModal(true);
            },
          });
        }

        if (!isSignedConsentForm) {
          listActions.push({
            key: 'sign-all-consent-forms',
            icon: ContractIcon,
            label: 'Sign all consent forms',
            onClick: onSignAllConsentForm,
          });
        }
        break;
      case HS_PAXSCREENING_STATUS.CheckInLocation:
        listActions = listActions.concat({
          key: 'register-patient',
          icon: ClipboardIcon,
          label: 'Register patient',
          styles: { width: 20, height: 20 },
          onClick: onRegisterPatient,
        });

        if (!selectedItem?.isFollowUpAppointment) {
          listActions.push({
            key: 'revert',
            icon: ReverseIcon,
            label: 'Revert to Upcoming',
            styles: { width: 20, height: 20 },
            onClick: () => {
              setAnchorEl(null);
              setShowRevertModal(true);
            },
          });
        }
        break;
      case HS_PAXSCREENING_STATUS.Completed:
        listActions = listActions.concat(
          {
            key: 'view-progress',
            icon: EyeIcon,
            label: 'View progress',
            onClick: onScreeningProgress,
          },
          {
            key: 'view',
            icon: ClipboardIcon,
            label: 'View patient registration',
            styles: { width: 20, height: 20 },
            onClick: onViewRegistration,
          }
        );

        if (!selectedItem?.isFollowUpAppointment) {
          listActions.push(
            {
              key: 'download',
              icon: ArrowDownCircle,
              label: 'Download report',
              styles: { width: 18, height: 18 },
              onClick: () => {
                const newPaxScreeningId = selectedItem.id;
                allScreeningsDispatcher.downloadIRReport(newPaxScreeningId);
                setAnchorEl(null);
              },
            },
            {
              key: 'regenerate',
              icon: RegenerateIcon,
              label: 'Regenerate report',
              onClick: () => {
                const newPaxScreeningId = selectedItem.id;
                allScreeningsDispatcher.regenerateIRReport(newPaxScreeningId);
                setAnchorEl(null);
              },
            },
            {
              key: 'follow-up',
              icon: CreateNewIcon,
              label: 'Follow up appointment',
              onClick: onCreateFollowUpAppointment,
            }
          );
        }

        break;
      case HS_PAXSCREENING_STATUS.InProgress:
        listActions = listActions.concat(
          {
            key: 'view-progress',
            icon: EditIcon,
            label: 'Update progress',
            styles: { width: 20, height: 20 },
            onClick: onScreeningProgress,
          },
          {
            key: 'view-progress',
            icon: ClipboardIcon,
            label: 'View patient registration',
            styles: { width: 20, height: 20 },
            onClick: onViewRegistration,
          }
        );

        if (
          paymentStatus &&
          paymentStatus !== 'ReadyToPay' &&
          !selectedItem?.isFollowUpAppointment
        ) {
          listActions.push({
            key: 'follow-up',
            icon: CreateNewIcon,
            label: 'Follow up appointment',
            onClick: onCreateFollowUpAppointment,
          });
        }

        if (!selectedItem?.isFollowUpAppointment) {
          listActions.push(
            {
              key: 'revert',
              icon: ReverseIcon,
              label: 'Revert to Upcoming',
              styles: { width: 20, height: 20 },
              onClick: () => {
                setAnchorEl(null);
                setShowRevertModal(true);
              },
            },
            {
              key: 'download',
              icon: ArrowDownCircle,
              label: 'Download report',
              styles: { width: 18, height: 18 },
              onClick: () => {
                const newPaxScreeningId = selectedItem.id;
                allScreeningsDispatcher.downloadIRReport(newPaxScreeningId);
                setAnchorEl(null);
              },
            },
            {
              key: 'regenerate',
              icon: RegenerateIcon,
              label: 'Regenerate report',
              onClick: () => {
                const newPaxScreeningId = selectedItem.id;
                allScreeningsDispatcher.regenerateIRReport(newPaxScreeningId);
                setAnchorEl(null);
              },
            }
          );
        }

        break;
      case HS_PAXSCREENING_STATUS.Missed:
        listActions = listActions.concat({
          key: 'reschedule',
          icon: ReverseIcon,
          label: 'Reschedule appointment',
          styles: { width: 20, height: 20 },
          onClick: onRescheduleAppointment,
        });
        break;

      default:
        break;
    }

    return listActions;
  };

  const onExportExcel = () => {
    if (data.length === 0) {
      return customToast(
        'error',
        'There is no data available to export the excel file'
      );
    }
    let params = {
      ...sortOption,
      ...filterOption,
      search: searchKey,
    };

    upcomingHSDispatcher.getExportExcel(params);
  };

  const onCancelAppointment = () => {
    if (isEmpty(selectedItem)) return;
    upcomingHSDispatcher.cancelTeleAppointment(selectedItem.id, () => {
      customToast('success', 'Cancel Appointment Success');
      fetchData();
      setShowCancelModal(false);
    });
  };

  const onEditAppointment = () => {
    history.push({
      pathname: `/${urlLabel.aSEditAppointment}`,
      state: selectedItem,
      label: 'Edit appointment',
    });
  };

  const onRescheduleAppointment = () => {
    history.push({
      pathname: `/${urlLabel.aSRescheduleAppointment}`,
      state: selectedItem,
      label: 'Reschedule appointment',
    });
  };

  const onViewRegistration = () => {
    history.push({
      pathname: `/${urlLabel.aSviewPatientRegistration}`,
      state: {
        ...selectedItem,
        isViewRegistration: true,
      },
      label: 'Patient Registration',
    });
  };

  const onRegisterPatient = () => {
    history.push({
      pathname: `/${urlLabel.aSRegisterPatient}`,
      state: { ...selectedItem },
      label: 'Register patient',
    });
  };

  const onScreeningProgress = () => {
    history.push({
      pathname: `/${urlLabel.aSScreeningProgress}`,
      state: { ...selectedItem, isScreeningProgress: true },
      label: 'Screening progress',
    });
  };

  const onCreateAppointment = () => {
    history.push({
      pathname: `/${urlLabel.aSCreateAppointment}`,
    });
  };

  const onCreateFollowUpAppointment = () => {
    history.push({
      pathname: `/${urlLabel.aSCreateFollowUpAppointment}`,
      state: { ...selectedItem, isFollowUp: true },
      label: 'Create follow-up appointment',
    });
  };

  const onCheckInLocation = () => {
    setAnchorEl(null);
    upcomingHSDispatcher.patientCheckInLocation(selectedItem.id, () => {
      fetchData();
      customToast(
        'success',
        `Patient ${selectedItem.fullName} has successfully checked in the location.`,
        'Checked in'
      );
    });
  };

  const onRevertAppointment = () => {
    if (isEmpty(selectedItem)) return;
    upcomingHSDispatcher.revertAppoinmentToUpcoming(selectedItem.id, () => {
      fetchData();
      customToast(
        'info',
        `Patient ${selectedItem.fullName}'s status has been reverted to Upcoming.`,
        'Appointment reverted',
        true,
        onViewAfterRevert
      );
      setShowRevertModal(false);
    });
  };

  const onViewAfterRevert = () => {
    const filterLocation = listLocations.filter(
      (item) => item.id === selectedItem.locationId
    );
    setSearchKey(selectedItem.nric);
    setLocationIds(filterLocation);
    setPaxScreenStatuses(['Upcoming']);
    setAppointmentDate([
      moment(selectedItem.startDate),
      moment(selectedItem.endDate),
    ]);
    setFilterOption({
      paxScreenStatuses: ['Upcoming'],
      locationId: [selectedItem.locationId],
      startDate: moment(selectedItem.startDate),
      endDate: moment(selectedItem.endDate),
    });
  };

  const onSignAllConsentForm = () => {
    setAnchorEl(null);
    if (isEmpty(selectedItem)) return;
    allScreeningsDispatcher.doneAllConsents(selectedItem.id, () => {
      fetchData();
    });
  };

  const onSubmitBulkImport = (files) => {
    if (isEmpty(files)) return customToast('error', 'File must not be empty');

    // Code here
    importHSAppointmentDispatcher.uploadHSAppointments(
      convertToFormData(files.originFileObj, 'file')
    );
    setShowBulkImportModal(false);
  };

  const onResetFilterOps = () => {
    setBillingTypes([]);
    setProjectIds([]);
    setLocationIds([]);
    setPaxScreenStatuses([]);
    setGenders([]);
    setServiceTypes([]);
    setCreatedDate([]);
    setAppointmentDate([]);
    setFilterOption({});
  };

  const onFilterOptionClear = (key, defaultValue, setState) => () => {
    let clearValue = defaultValue;
    switch (key) {
      case 'genders':
        clearValue = '';
        break;
      case 'paxScreenStatuses':
        clearValue = [];
        break;

      default:
        break;
    }

    setFilterOption({
      ...filterOption,
      [key]: defaultValue,
    });

    setState && setState(clearValue);
  };

  const onFilterOptionChange = (key, setState) => (e, newValue) => {
    let value = e.target.value;

    switch (key) {
      case 'genders':
        value = [value];
        break;
      case 'minCost':
      case 'maxCost':
        value = e.value;
        break;
      case 'paxScreenStatuses':
        value = handleSelectAll(
          value,
          HS_ALL_SCREENINGS_PAXSCREENING_STATUS.map((item) => item.key),
          paxScreenStatuses
        );

        break;
      case 'serviceTypes':
        value = handleSelectAll(
          value,
          HS_SERVICE_TYPES.map((item) => item.value),
          serviceTypes
        );

        break;
      case 'billingTypes':
        value = handleSelectAll(
          value,
          HS_BILLING_TYPES.map((item) => item.value),
          billingTypes
        );

        break;
      case 'locationIds':
        value = newValue.map((item) => item.id);
        setLocationIds(value);
        break;
      case 'projectIds':
        value = newValue.map((item) => item.id);
        setProjectIds(value);
        break;
      default:
        break;
    }

    if (key === 'billingTypes') {
      // Check if Subsidy is selected
      if (value.includes('Subsidy')) {
        setFilterOption({
          ...filterOption,
          [key]: value.filter((it) => it !== 'Subsidy'),
          hasBillingSubsidy: true,
        });
      } else {
        delete filterOption.hasBillingSubsidy;
        setFilterOption({
          ...filterOption,
          [key]: value.filter((it) => it !== 'Subsidy'),
        });
      }
    } else if (key === 'serviceTypes') {
      // Check if Others is selected
      if (value.includes('Others')) {
        setFilterOption({
          ...filterOption,
          [key]: value.filter((it) => it !== 'Others'),
          filterOtherService: true,
        });
      } else {
        delete filterOption.filterOtherService;
        setFilterOption({
          ...filterOption,
          [key]: value.filter((it) => it !== 'Others'),
        });
      }
    } else
      setFilterOption({
        ...filterOption,
        [key]: value,
      });

    setState && setState(value);
  };

  const handleSelectAll = (inputValue, originArr, newArr) => {
    let newValue = [...inputValue];
    const isEqualLength = originArr.length === newArr.length;
    if (inputValue.includes('selectAll')) {
      if (isEqualLength) newValue = [];
      else newValue = [...originArr];
    }
    return newValue;
  };

  const fetchData = async (
    search = searchKey,
    pagingData = paging,
    sortOptionData = sortOption,
    filterOptionData = filterOption
  ) => {
    const _filterOptions = (filterData) => {
      if (filterData?.createdStartDate && filterData?.createdEndDate) {
        delete filterData.endDate;
        delete filterData.startDate;
      }
      return filterData;
    };
    upcomingHSDispatcher.getData(
      search,
      pagingData,
      ...[sortOptionData, _filterOptions(filterOptionData)].filter((i) => i)
    );
  };

  const debounceLoadData = useCallback(
    debounce((searchKey, paging, sortOption, filterOption) => {
      fetchData(
        searchKey,
        {
          pageIndex: 0,
          pageSize: paging.pageSize,
        },
        sortOption,
        filterOption
      );
    }, 500),
    []
  );

  useEffect(() => {
    debounceLoadData(searchKey, paging, sortOption, filterOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey, sortOption, filterOption]);

  useEffect(() => {
    globalDispatcher.getListLocations();
    authDispatcher.getUserPasscode();
    globalDispatcher.getMaxPriceDefault('AllScreening', (data) => {
      setMaxPriceDefault(data);
      setFilterOption({
        ...filterOption,
        maxCost: data,
        minCost: 0,
      });
    });

    return () => {
      upcomingHSDispatcher.resetData();
    };
  }, []);

  useEffect(() => {
    const [minCost, maxCost] = getCostRange(quickRange, maxPriceDefault);
    if (
      Number(filterOption.minCost) !== Number(minCost) ||
      Number(filterOption.maxCost) !== Number(maxCost)
    )
      setQuickRange(null);
  }, [filterOption, maxPriceDefault, quickRange]);

  const TableHeader = () => (
    <TableHead>
      <TableRow>
        {HS_COLUMN_UPCOMING.map((item) =>
          item.sortBy ? (
            <TableCellFilted
              key={item.stateValue}
              label={item.label}
              stateValue={item.stateValue}
              sortBy="Date"
              sortOption={sortOption}
              onSortChange={() => {
                let newSortOption = {
                  sortBy: 'BookedApptDate',
                  orderBy: sortOption.orderBy !== 'Asc' ? 'Asc' : 'Desc',
                };
                setSortOption(newSortOption);
              }}
              style={{ minWidth: item.minWidth || 'unset' }}
              isHidden={false}
            />
          ) : (
            <TableCellFilted
              key={item.stateValue}
              label={item.label}
              stateValue={item.stateValue}
              style={{ minWidth: item.minWidth || 'unset' }}
              isHidden={false}
            />
          )
        )}
      </TableRow>
    </TableHead>
  );

  const renderTableBody = (row) => {
    return (
      <>
        <TableCell>
          <CustomTooltip content={row.fullName} />
        </TableCell>
        <TableCell>
          <CustomTooltip content={row.nric} />
        </TableCell>
        <TableCell>{HS_SERVICE_TYPES[row.packageType]}</TableCell>
        <TableCell>{renderDateTime(row)}</TableCell>
        <TableCell>
          <CustomTooltip content={row.packageName} />
        </TableCell>
        <TableCell>
          <CustomTooltip content={row.location} />
        </TableCell>
        <TableCell>{formatTotalPrice(row.totalCost, '$')}</TableCell>
        <TableCell>
          {row.paxScreenStatus === HS_PAXSCREENING_STATUS.InProgress ? (
            <StatusComponent status={row.stationName} />
          ) : (
            <StatusComponent status={row.paxScreenStatus} />
          )}
        </TableCell>
        <TableCell>
          <IconButton
            disabled={row.paxScreenStatus === HS_PAXSCREENING_STATUS.Cancelled}
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
              setSelectedItem(row);
            }}
          >
            <MoreVertRounded />
          </IconButton>
        </TableCell>
      </>
    );
  };

  return (
    <>
      <CustomMenu
        id="simple-menu"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        <CustomMenuActions listActions={renderListActions(selectedItem)} />
      </CustomMenu>

      <CustomPaperContainer
        header={
          <CustomHeader
            searchPlaceholder="Search by name or ID number..."
            title="All screenings"
            setSearchKey={setSearchKey}
            searchKey={searchKey}
            filterOption={filterOption}
            setFilterOption={setFilterOption}
            sortOption={sortOption}
            fetchData={fetchData}
            renderToolbar={() => (
              <GroupToolbar
                genders={genders}
                setGenders={setGenders}
                serviceTypes={serviceTypes}
                setServiceTypes={setServiceTypes}
                paxScreenStatuses={paxScreenStatuses}
                setPaxScreenStatuses={setPaxScreenStatuses}
                locationIds={locationIds}
                setLocationIds={setLocationIds}
                filterOption={filterOption}
                setFilterOption={setFilterOption}
                quickRange={quickRange}
                setQuickRange={setQuickRange}
                onFilterOptionClear={onFilterOptionClear}
                onFilterOptionChange={onFilterOptionChange}
                onResetFilterOps={onResetFilterOps}
                appointmentDate={appointmentDate}
                setAppointmentDate={setAppointmentDate}
                createdDate={createdDate}
                setCreatedDate={setCreatedDate}
                maxPriceDefault={maxPriceDefault}
                projectIds={projectIds}
                setProjectIds={setProjectIds}
                billingTypes={billingTypes}
                setBillingTypes={setBillingTypes}
              />
            )}
            renderButton={() => (
              <>
                <Button
                  color="primary"
                  variant="outlined"
                  startIcon={<ExportIcon width={20} height={20} />}
                  onClick={onExportExcel}
                >
                  Export
                </Button>
                {/* <Button
                  color="primary"
                  variant="outlined"
                  startIcon={<ImportIcon width={24} height={24} />}
                  onClick={() => setShowBulkImportModal(true)}
                >
                  Bulk import
                </Button> */}
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<CreateNewIcon />}
                  onClick={onCreateAppointment}
                >
                  Create new
                </Button>
              </>
            )}
          />
        }
        children={
          <CustomTable
            data={data}
            Icon={NoDataScreenings}
            paging={paging}
            header={TableHeader}
            renderRow={(row, i) => renderTableBody(row)}
            noDataHelperText="No screenings"
            noDataHelperSubText="Please come back at another time."
            totalCols={HS_COLUMN_UPCOMING.length}
            fetchData={fetchData}
          />
        }
      />

      {showCancelModal && (
        <ModalConfirmation
          open={showCancelModal}
          onClick={onCancelAppointment}
          onClose={() => setShowCancelModal(false)}
          mainContent={'Cancel appointment'}
          subContent={'Are you sure you want to cancel this appointment?'}
          cancelLabel="No, keep it"
          confirmLabel="Confirm"
          confirmColor="red"
        />
      )}
      {showBulkImportModal && (
        <ModalBulkImport
          open={showBulkImportModal}
          onSumit={onSubmitBulkImport}
          onClose={() => setShowBulkImportModal(false)}
        />
      )}
      {showReverttModal && (
        <ModalConfirmation
          open={showReverttModal}
          onClick={onRevertAppointment}
          onClose={() => setShowRevertModal(false)}
          mainContent={'Revert to Upcoming'}
          subContent={
            'Are you sure you want to revert this appointment to Upcoming?'
          }
          cancelLabel="Cancel"
          confirmLabel="Confirm"
          Icon={<RevertConfirmationIcon />}
        />
      )}
    </>
  );
};

export default AllScreenings;

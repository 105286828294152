import React, { useEffect, useMemo, useState } from 'react';
import { CloseIcon } from '@/assets/svg';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import MomentAdapter from '@material-ui/pickers/adapter/moment';
import { LocalizationProvider } from '@material-ui/pickers';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import validateData from '@/helpers/validationHelpers/validationSchema';

import {
  StepperButton,
  StepperControl
} from '@/new-components/CustomStepper/styled';
import surveysDispatcher from '../action';
import SurveyForm from './SurveyForm';
import SurveyQuestionContainer from './SurveyQuestionContainer';
import SurveyModal from './SurveyModal';
import { TYPE_MODAL } from '@/module/common/constants/typeModal';
import { capitalize, cloneDeep, isBoolean, isEmpty } from 'lodash';
import customToast from '@/new-components/CustomNotification';

const titleStyles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    border: '1px solid #f1f1f1'
  },
  title: {
    fontWeight: 600,
    fontSize: 18,
    color: '#333'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 14,
    color: theme.palette.grey[500]
  }
});

const useStyles = makeStyles(theme => ({
  mainWrapper: {
    height: 'calc(100vh - 140px)',
    overflow: 'auto'
  },
  stepsControlWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(1)
  }
}));

const CustomDrawerTitle = withStyles(titleStyles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <Box className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </Box>
  );
});

const ModalContainerForm = ({ onClose, isCreate, selectedItem }) => {
  const classes = useStyles();

  const [showModal, setShowModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [itemSelected, setItemSelected] = useState({});
  const [isCreateQues, setIsCreateQues] = useState(true);
  const [currCategoryName, setCurrCategoryName] = useState('');
  const [removedQuestions, setRemovedQuestions] = useState([]);

  const [errors, setErrors] = useState({});
  const [formValue, setFormValue] = useState({
    questions: []
  });

  useEffect(() => {
    if (!isCreate) {
      const newCategories = transformQuestions(selectedItem.questions);
      setCategories(newCategories);
      setFormValue({ ...selectedItem, questions: selectedItem.questions });
    }
  }, []);

  const handleSubmit = async () => {
    try {
      let questions = transformData(categories).map((item, index) => ({
        ...item,
        order: index
      }));
      questions = questions.concat(removedQuestions);
      await validateData('surveySchema', { ...formValue, questions }, data => {
        onSubmit(data);
      });
    } catch (errs) {
      setErrors(errs);
    }
  };
  const transformData = data => {
    if (isEmpty(data)) return [];
    return data.reduce((acc, curr) => {
      const questionsWithCategory = curr.questions.map(question => ({
        ...question,
        category: curr.categoryName
      }));
      return [...acc, ...questionsWithCategory];
    }, []);
  };

  const renderToast = (isCreate, data, mainType) => {
    return customToast(
      'success',
      <span>
        <strong style={{ fontWeight: 600 }}>{data}</strong> has been
        successfully {isCreate ? 'created' : 'updated'}.
      </span>,
      isCreate ? `New ${mainType} created` : ` ${capitalize(mainType)} updated`
    );
  };

  const onSubmit = data =>
    isCreate
      ? surveysDispatcher.createSurvey(data, () => {
          renderToast(isCreate, data.name, 'lifestyle survey');
          onClose();
        })
      : surveysDispatcher.editSurvey(selectedItem.id, data, () => {
          renderToast(isCreate, data.name, 'lifestyle survey');
          onClose();
        });

  const addCategoryToQuestion = (categoryName = '', questions = []) => {
    const newQues = questions.map(item => ({
      ...item,
      category: categoryName
    }));
    return { questions: newQues };
  };

  const changeTableValue = (action, value, position, parentPosition) => {
    let questions = cloneDeep(formValue.questions);
    let newCategories = cloneDeep(categories);
    const val = addCategoryToQuestion(currCategoryName, value?.questions);
    switch (action) {
      case TYPE_MODAL.Create:
        questions = questions.concat(val.questions);
        if (itemSelected?.parentPosition) {
          newCategories[itemSelected.parentPosition].questions.push(
            value.questions[0]
          );
        } else newCategories = transformQuestions(questions);

        break;
      case TYPE_MODAL.Edit:
        questions[itemSelected?.position] = value.questions[0];
        newCategories[itemSelected?.parentPosition].questions[
          itemSelected?.position
        ] = value.questions[0];

        break;
      case TYPE_MODAL.Delete:
        questions[position].isRemoved = true;
        const questionsExistId = val.questions.filter(item => item.id);
        if (!isEmpty(questionsExistId)) {
          questions[position].questions = questions[
            position
          ].questions.map(item => ({ ...item, isRemoved: true }));
        } else questions.splice(position, 1);

        break;
      default:
        break;
    }
    newCategories = newCategories.filter(
      category => !category.questions.every(question => question.isRemoved)
    );

    onCloseModal();
    setCategories(newCategories);
    setFormValue({ ...formValue, questions });
  };

  const transformQuestions = questions => {
    let newCategories = [];

    for (let question of questions) {
      let existingCategory = newCategories.find(
        cat => cat.categoryName === question.category
      );

      if (existingCategory) {
        existingCategory.questions.push(question);
      } else {
        newCategories.push({
          categoryName: question.category,
          questions: [question]
        });
      }
    }

    return newCategories;
  };

  const onCloseModal = () => {
    setShowModal(false);
    setItemSelected({});
  };

  const isEmptyFilterParams = useMemo(() => {
    const filterKeys = ['name', 'questions'];
    const questions = transformData(categories);
    const newFormValue = { ...formValue, questions };
    const isEmtyCategoryName = categories.some(it => isEmpty(it.categoryName));

    return (
      isEmpty(currCategoryName) ||
      isEmtyCategoryName ||
      filterKeys.some(key =>
        isBoolean(newFormValue[key])
          ? !newFormValue[key]
          : isEmpty(newFormValue[key])
      )
    );
  }, [formValue, categories, currCategoryName]);

  return (
    <LocalizationProvider dateAdapter={MomentAdapter}>
      <Paper square elevation={0}>
        <CustomDrawerTitle onClose={onClose}>
          {isCreate ? 'Create new' : 'Edit'} lifestyle survey
        </CustomDrawerTitle>
        <main className={classes.mainWrapper}>
          <SurveyForm
            errors={errors}
            formValue={formValue}
            setFormValue={setFormValue}
          />

          <SurveyQuestionContainer
            isCreate={isCreate}
            categories={categories}
            setCategories={setCategories}
            formValue={formValue}
            setFormValue={setFormValue}
            currCategoryName={currCategoryName}
            setCurrCategoryName={setCurrCategoryName}
            removedQuestions={removedQuestions}
            setRemovedQuestions={setRemovedQuestions}
            onShowEdit={(item, index, parentInx) => {
              setItemSelected({
                item,
                position: index,
                parentPosition: parentInx
              });
              setShowModal(true);
              setIsCreateQues(false);
            }}
            onShowAdd={parentInx => {
              setItemSelected({
                parentPosition: parentInx
              });
              setShowModal(true);
              setIsCreateQues(true);
            }}
          />
        </main>
        <StepperControl className={classes.stepsControlWrapper}>
          <StepperButton
            disabled={isEmptyFilterParams}
            onClick={() => handleSubmit(formValue)}
          >
            {isCreate ? 'Create' : 'Save changes'}
          </StepperButton>
        </StepperControl>
      </Paper>

      {showModal && (
        <SurveyModal
          open={showModal}
          onClose={() => {
            setItemSelected({});
            setShowModal(false);
          }}
          selectedItem={itemSelected}
          typeModal={isCreateQues ? 'Create' : 'Edit'}
          onSubmit={changeTableValue}
          setFormValue={setFormValue}
          formValue={formValue}
          isCreateQues={isCreateQues}
        />
      )}
    </LocalizationProvider>
  );
};

export default ModalContainerForm;

import React, { useState, useEffect } from 'react';
import { ClearRounded } from '@material-ui/icons';
import {
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  InputAdornment
} from '@material-ui/core';
import { ButtonEnhance } from '../../common/componentUI/commonStyleComponents';
import {
  UploadSingle,
  InputAddressEnhance
} from '../../common/component/index';
import clinicDispatcher from '../actions/index';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { TYPE_MODAL } from '../constants';
import styled from 'styled-components';
import CarouselAnt from '../../../components/CarouselAnt';
import { getBase64Promise } from '../../../helpers/CommonHelper';
import { LIST_REQUIRED, LIST_REQUIRED_EDIT } from '../constants/index';
import LoadingButton from '../../../components/LoadingButton';
import './styles.scss';
import CropImage from '@/module/common/component/CropImage';

const ClinicFormPage = ({
  clinicDetail,
  onCloseModal,
  typeModal,
  reGetListClinic
}) => {
  const loading = useSelector(state => state.clinic.loading);
  const [id, setId] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [formValue, setFormValue] = useState({
    name: '',
    consultFee: '',
    consultPlan: '',
    pictureProfile: null,
    lstBanner: [],
    postalCode: '',
    unitNumber: '',
    address: '',
    reviewLink: ''
  });

  const [listBannerView, setListBannerView] = useState([]);

  useEffect(() => {
    if (formValue.lstBanner && formValue.lstBanner.length) {
      renderListBannerView(formValue.lstBanner);
    }
  }, [formValue.lstBanner]);

  useEffect(() => {
    if (!isEmpty(clinicDetail)) {
      setFormValue({
        name: clinicDetail.name || '',
        consultFee: clinicDetail.consultFee || '',
        consultPlan: clinicDetail.consultPlan || '',
        pictureProfile: clinicDetail.pictureProfile || '',
        lstBanner: clinicDetail.lstBanner || [],
        status: clinicDetail.status,
        unitNumber: clinicDetail.unitNumber || '',
        address: clinicDetail.address || '',
        platoName: clinicDetail.platoName || '',
        latitude: clinicDetail.latitude || 0,
        longitude: clinicDetail.longitude || 0,
        reviewLink: clinicDetail.reviewLink || '',
        postalCode: clinicDetail.postalCode || ''
      });
      setId(clinicDetail.id);
    }
  }, [clinicDetail]);

  const handleChange = (data, field) => {
    let value = '';
    if (field === 'postalCode') {
      // Regex labnum number contain only number without dot and comma symbol
      const regexNumber = /^[0-9\b]+$/;
      let newValue = data;
      if (regexNumber.test(newValue)) value = newValue;
    } else value = data;

    setFormValue({
      ...formValue,
      [field]: value
    });
  };

  const onCheckValidForm = () => {
    let valid = true;
    let errors = {};
    let listValidation = [];
    setIsSubmitted(true);
    if (typeModal === TYPE_MODAL.Create) {
      listValidation = LIST_REQUIRED;
    }
    if (typeModal === TYPE_MODAL.Edit) {
      listValidation = LIST_REQUIRED_EDIT;
    }

    listValidation.forEach(field => {
      if (!formValue[field]) {
        errors = { ...errors, [field]: 'Required' };
        valid = false;
      }
    });

    // Postal Code
    if (formValue.postalCode && formValue.postalCode.length !== 6) {
      errors = { ...errors, postalCode: 'Postal Code must be 6 digits' };
      valid = false;
    }
    setErrors(errors);
    return valid;
  };

  const handleSubmitClinic = () => {
    if (!onCheckValidForm()) {
      return;
    }
    setIsSubmitted(false);
    setErrors({});

    if (typeModal === TYPE_MODAL.Edit) {
      clinicDispatcher.editClinic({ ...formValue }, id, () => {
        reGetListClinic();
        onCloseModal();
      });
      return;
    }
    clinicDispatcher.addClinic({ ...formValue }, () => {
      reGetListClinic();
      onCloseModal();
    });
  };

  const handleChangeBanner = (data, index) => {
    setFormValue({
      ...formValue,
      lstBanner: formValue.lstBanner.map((banner, i) => {
        if (index === i) {
          return data;
        }
        return banner;
      })
    });
  };

  const onRemoveBanner = pos => {
    setFormValue({
      ...formValue,
      lstBanner: formValue.lstBanner.filter((item, index) => index !== pos)
    });
  };

  const handleRenderBanner = () => {
    const data = [];
    for (let i = 0; i < 5 - formValue.lstBanner.length; i++) {
      data.push('');
    }
    data.length &&
      setFormValue({
        ...formValue,
        lstBanner: [...formValue.lstBanner, ...data]
      });
    return formValue.lstBanner.map((item, index) => (
      <BannerItem key={item + index}>
        <UploadSingle
          index={index}
          disabled={typeModal === TYPE_MODAL.View}
          imageUrlSelected={item}
          onChange={data => handleChangeBanner(data, index)}
          typeUpload="inline"
          onRemove={onRemoveBanner}
        />
      </BannerItem>
    ));
  };

  const handleSelectAddress = data => {
    setFormValue({
      ...formValue,
      address: data.description || '',
      latitude: data.lat || '',
      longitude: data.lng || ''
    });
  };

  const renderListBannerView = async listBanner => {
    const data = [];
    for (let i = 0; i < listBanner.length; i++) {
      let url = '';
      const banner = listBanner[i];
      if (banner?.constructor === File) {
        url = await getBase64Promise(banner);
        data.push(url);
      } else {
        banner && data.push(banner);
      }
    }
    setListBannerView(data);
  };

  return (
    <>
      <HeaderModal>
        <div className="title">
          {typeModal === TYPE_MODAL.Create && 'Create New Clinic'}
          {typeModal === TYPE_MODAL.Edit && 'Update Clinic'}
          {typeModal === TYPE_MODAL.View && formValue.name}
        </div>
        <ClearRounded onClick={() => onCloseModal()} className="icon-close" />
      </HeaderModal>
      <DialogContent>
        <WrapperForm>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Grid item xs={4}>
                    <CropImage
                      image={formValue.pictureProfile}
                      onChange={data => handleChange(data, 'pictureProfile')}
                      previousImage={clinicDetail?.pictureProfile}
                      typeModal={typeModal}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <WrapperInput
                    error={isSubmitted && errors.name ? true : false}
                    variant={
                      typeModal === TYPE_MODAL.View ? 'filled' : 'standard'
                    }
                    disabled={typeModal === TYPE_MODAL.View}
                    value={formValue.name}
                    onChange={e => handleChange(e.target.value, 'name')}
                    label="Clinic Name"
                    helperText={isSubmitted && errors.name}
                  />
                </Grid>

                <Grid item xs={12}>
                  <WrapperInput
                    error={isSubmitted && errors.platoName ? true : false}
                    variant={
                      typeModal === TYPE_MODAL.View ? 'filled' : 'standard'
                    }
                    disabled={typeModal === TYPE_MODAL.View}
                    value={formValue.platoName}
                    onChange={e => handleChange(e.target.value, 'platoName')}
                    label="Plato Name"
                    helperText={isSubmitted && errors.platoName}
                  />
                </Grid>

                <Grid item xs={12}>
                  <InputAddressEnhance
                    disabled={typeModal === TYPE_MODAL.View}
                    addressSelected={formValue.address}
                    field="address"
                    isSubmitted={isSubmitted}
                    errors={errors}
                    onSelect={handleSelectAddress}
                  />
                </Grid>

                <Grid item xs={12}>
                  <WrapperInput
                    error={isSubmitted && errors.unitNumber ? true : false}
                    variant={
                      typeModal === TYPE_MODAL.View ? 'filled' : 'standard'
                    }
                    inputProps={{ min: '0' }}
                    disabled={typeModal === TYPE_MODAL.View}
                    value={formValue.unitNumber}
                    onChange={e => handleChange(e.target.value, 'unitNumber')}
                    label="Unit No."
                    helperText={isSubmitted && errors.unitNumber}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">#</InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <WrapperInput
                    error={isSubmitted && errors.postalCode ? true : false}
                    variant={
                      typeModal === TYPE_MODAL.View ? 'filled' : 'standard'
                    }
                    disabled={typeModal === TYPE_MODAL.View}
                    value={formValue.postalCode}
                    onChange={e => handleChange(e.target.value, 'postalCode')}
                    label="Postal Code"
                    helperText={isSubmitted && errors.postalCode}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">S</InputAdornment>
                      )
                    }}
                    onInput={e => {
                      e.target.value = e.target.value.toString().slice(0, 6);
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <WrapperInput
                    // error={isSubmitted && errors.consultFee ? true : false}
                    error={false}
                    variant={
                      typeModal === TYPE_MODAL.View ? 'filled' : 'standard'
                    }
                    disabled={typeModal === TYPE_MODAL.View}
                    value={formValue.consultFee}
                    onChange={e => handleChange(e.target.value, 'consultFee')}
                    type="number"
                    inputProps={{ min: '0' }}
                    label="Consult fee"
                    // helperText={isSubmitted && errors.consultFee}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      )
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <WrapperInput
                    error={isSubmitted && errors.consultPlan ? true : false}
                    variant={
                      typeModal === TYPE_MODAL.View ? 'filled' : 'standard'
                    }
                    disabled={typeModal === TYPE_MODAL.View}
                    value={formValue.consultPlan}
                    onChange={e => handleChange(e.target.value, 'consultPlan')}
                    label="Available consult plans"
                    helperText={isSubmitted && errors.consultPlan}
                  />
                  <TextExplain>Enter plans separated by commas</TextExplain>
                </Grid>

                <Grid item xs={12}>
                  {typeModal === TYPE_MODAL.View && (
                    <>
                      <h5>Google review link</h5>
                      <div>
                        <a
                          target="_blank"
                          href="https://www.google.com/search?gs_ssp=eJzj4tZP1zcsSTE3McqpMmC0UjWoMDZMSTS0tDROTjJNSk62sLQyqDAzS000NDc3NE9JsUg1M7TwEs_NzMtNTVHISE3MKclQKE4uSk3NSy0qBgBW5Ber&q=minmed+health+screeners&rlz=1C1CHBF_enSG746SG746&oq=minmed+heal&aqs=chrome.1.69i57j46j0l3j69i60l3.5622j0j7&sourceid=chrome&ie=UTF-8#lrd=0x31da1993cb5bcc89:0x66ea17717dd8e618,3"
                        >
                          {formValue.reviewLink}
                        </a>
                      </div>
                    </>
                  )}
                  {typeModal !== TYPE_MODAL.View && (
                    <WrapperInput
                      error={isSubmitted && errors.reviewLink ? true : false}
                      variant={
                        typeModal === TYPE_MODAL.View ? 'filled' : 'standard'
                      }
                      disabled={typeModal === TYPE_MODAL.View}
                      value={formValue.reviewLink}
                      onChange={e => handleChange(e.target.value, 'reviewLink')}
                      label="Google review link"
                      helperText={isSubmitted && errors.name}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <WrapperColRight>
                <Grid item xs={12}>
                  {handleRenderBanner()}
                </Grid>

                <Grid item xs={12}>
                  <LabelBannerPreview>Banners Preview</LabelBannerPreview>
                  <CarouselAnt data={listBannerView} disabled />
                </Grid>
              </WrapperColRight>
            </Grid>
          </Grid>
        </WrapperForm>
      </DialogContent>
      {typeModal !== TYPE_MODAL.View && (
        <ModalFooter>
          <ButtonEnhance
            background="#E9E9E9"
            color="black"
            backgroundHover="#ccc"
            onClick={() => onCloseModal()}
          >
            Cancel
          </ButtonEnhance>

          <LoadingButton showLoader={loading} onClick={handleSubmitClinic}>
            Save
          </LoadingButton>
        </ModalFooter>
      )}
    </>
  );
};

const ModalFooter = styled(DialogActions)`
  border-top: 1px solid rgb(202, 207, 211);
  padding: 20px 0px;
`;

const TextExplain = styled.div`
  color: #8f9ba9;
  font-size: 10px;
  margin-top: 3px;
`;

const LabelBannerPreview = styled.div`
  color: #8f9ba9;
  margin-bottom: 10px;
  font-size: 15px;
`;

const BannerItem = styled.div`
  margin-bottom: 20px;
`;

const WrapperColRight = styled.div`
  padding: 25px;
  background: #f7f7f7;
  border-radius: 15px;
  margin: 15px;
`;

const HeaderModal = styled.div`
  border-bottom: 1px solid rgb(202, 207, 211);
  font-weight: 600;
  color: rgb(25, 38, 55);
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  .icon-close {
    cursor: pointer;
  }
  .title {
    font-weight: bold;
    font-size: 18px;
  }
`;
const WrapperForm = styled.div`
  font-size: 14;
  flex-grow: 1;
`;
const WrapperInput = styled(TextField)`
  width: 100%;
  label {
    color: #8f9ba9;
    font-size: 16px;
  }
  .MuiInput-underline:before {
    border-color: #e9e9e9;
  }
`;
export default ClinicFormPage;

import React from 'react';

import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  makeStyles,
  withStyles
} from '@material-ui/core';
import { startCase } from 'lodash';

import { CheckboxActive, CheckboxDefault } from '@/assets/svg';

const useStyles = makeStyles(theme => ({
  root: {
    background: 'red'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 185,
    maxWidth: 300
  },
  selectWrapper: {
    maxHeight: 276,
    boxShadow: '0 4px 16px 0 rgba(0,0,0,0.1)',
    minWidth: 'unset !important',
    '& .MuiListItem-root.Mui-selected': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main
    },
    '& .multiple.MuiListItem-root.Mui-selected': {
      color: 'initial',
      backgroundColor: 'transparent'
    },
    '& .MuiList-root.MuiMenu-list.MuiList-padding': {
      width: props => `${props.width}px !important`
    }
  },
  checkboxIcon: {
    padding: 0,
    marginRight: 4
  }
}));

const CustomMenuItem = withStyles({
  root: {
    padding: 8,
    borderRadius: 8,
    margin: '2px 8px',

    '& span[class="max-line"]': {
      whiteSpace: 'pre-wrap',
      overflow: 'hidden',
      display: '-webkit-inline-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 2
    },

    '&:first-child': {
      marginTop: 8
    },
    '&:last-child': {
      marginBottom: 8
    }
  }
})(MenuItem);

const MutipleSelection = ({
  open,
  onChange,
  data,
  value,
  disabled = false,
  isMutiple = true,
  children,
  asolutePosition = true,
  onClose,
  inputLabel,
  labelId,
  renderValue,
  formClasses,
  isClinic,
  useSelectAll,
  checkedAll,
  error,
  key,
  renderValidItem,
  label,
  labelSelectAll = 'Select All',
  height,
  width = 300
}) => {
  const classes = useStyles({ width });
  const MenuProps = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left'
    },
    getContentAnchorEl: null,
    classes: { paper: classes.selectWrapper }
  };
  return (
    <div
      style={
        formClasses
          ? formClasses
          : {
              width: 0,
              height: 0,
              overflow: 'hidden',
              position: asolutePosition ? 'absolute' : 'relative',
              top: height === '48px' ? 16 : 0,
              left: -20
            }
      }
    >
      <FormControl className={classes.formControl} style={formClasses}>
        {inputLabel && <InputLabel id={labelId}>{inputLabel}</InputLabel>}
        <Select
          labelId={labelId}
          multiple={isMutiple}
          open={open}
          onChange={e => {
            if (disabled) {
              return;
            }
            isMutiple && e.stopPropagation();
            onChange(e);
          }}
          value={value}
          MenuProps={MenuProps}
          onClose={onClose}
          renderValue={renderValue}
          style={{ minWidth: 'unset' }}
        >
          {useSelectAll && (
            <CustomMenuItem value="selectAll">
              <Checkbox
                icon={<CheckboxDefault width={20} height={20} />}
                checkedIcon={<CheckboxActive width={20} height={20} />}
                color="primary"
                checked={checkedAll}
                className={classes.checkboxIcon}
              />
              {labelSelectAll}
            </CustomMenuItem>
          )}
          {children ||
            data?.map(item => (
              <CustomMenuItem
                key={item.key || item.id || item}
                value={item[key] || item.key || item.id || item}
                style={{ fontSize: 14, minWidth: 'unset' }}
                disabled={item.isDisabled || disabled}
                className={isMutiple ? 'multiple' : ''}
              >
                <span className="max-line">
                  {isMutiple ? (
                    <Checkbox
                      color="primary"
                      icon={<CheckboxDefault width={20} height={20} />}
                      checkedIcon={<CheckboxActive width={20} height={20} />}
                      checked={
                        value.includes(item[key]) ||
                        value.includes(item.key) ||
                        value.includes(item.id) ||
                        value.includes(item)
                      }
                      className={classes.checkboxIcon}
                    />
                  ) : (
                    item.name || item.locationAddress
                  )}
                  {isClinic
                    ? `${item.name}, ${item.address}, ${item.unitNumber}`
                    : item[label] ||
                      item.value ||
                      startCase(item.value) ||
                      (typeof item === 'string' && item)}
                </span>
              </CustomMenuItem>
            ))}
          {renderValidItem && renderValidItem}
        </Select>
        {error && <Typography color="error">{error}</Typography>}
      </FormControl>
    </div>
  );
};

export default MutipleSelection;

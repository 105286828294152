import React, { useEffect, useState, useMemo } from 'react';
import { CloseIcon } from '@/assets/svg';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import CustomStepper from '@/new-components/CustomStepper';
import { DEFAULT_COMPULSORY_TAGS, refactorListData } from '../../constants';
import { STEPS_CONTROL } from '@/module/setup/constants';
import { StepperButton } from '@/new-components/CustomStepper/styled';
import GeneralInformationStep from './GeneralInformationStep';
import { useSelector } from 'react-redux';
import { cloneDeep, isBoolean, isEmpty } from 'lodash';
import validateData from '@/helpers/validationHelpers/validationSchema';

import allScreeningsDispatcher from '@/module/all-screenings/action';
import { packageDispatcher } from '@/module/package-management';
import globalDispatcher from '@/redux/global/actions';

import customToast from '@/new-components/CustomNotification';
import AvailableServicesStep from './AvailableServicesStep';
import { formatData } from '@/module/package-management/utils';
import RestrictLocations from './RestrictLocations';

import {
  generateParamsTestIdsPackageIdsData,
  refactorListDataCategory
} from '@/module/all-screenings/utils';

const titleStyles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  title: {
    fontWeight: 600,
    fontSize: 18,
    color: '#333'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 14,
    color: theme.palette.grey[500]
  }
});

const useStyles = makeStyles({
  mainWrapper: {
    height: 'calc(100vh - 200px)',
    overflow: 'auto'
  }
});

const CustomDrawerTitle = withStyles(titleStyles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <Box className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </Box>
  );
});

const CreateAndEditPackage = ({ onClose, isCreate, selectedItem }) => {
  const tagsPackage = useSelector(state => state.globalState.tagsPackage);

  const classes = useStyles();
  const [currentStep, setCurrentStep] = useState(0);
  const [errors, setErrors] = useState({});
  const [formValue, setFormValue] = useState({
    name: '',
    packageType: '',
    lstTests: [],
    lstProfiles: [],
    lstMeasurements: [],
    lstProcedures: [],
    lstRadiologies: [],
    lstVaccinations: [],
    lstReviews: [],
    restrictedLocationIds: [],
    description: '',
    shortDesc: '',
    tags: ['General'],
    compulsoryTag: 'General'
  });

  useEffect(() => {
    if (!isCreate) {
      packageDispatcher.getPackageById(selectedItem.id, data => {
        const compulsoryTag = data?.tags?.find(spec =>
          DEFAULT_COMPULSORY_TAGS.some(el => spec.includes(el))
        );
        const optionalTags = data?.tags?.filter(
          spec => !DEFAULT_COMPULSORY_TAGS.some(el => spec.includes(el))
        );
        setFormValue({ ...formatData(data), optionalTags, compulsoryTag });
      });
    }

    globalDispatcher.getListTagsPackage();
  }, []);

  const handleSubmit = async () => {
    try {
      if (errors.numberSelected) customToast('error', 'Please choose again');
      else {
        const tags = !isEmpty(formValue?.optionalTags)
          ? formValue?.optionalTags.concat(formValue?.compulsoryTag)
          : [formValue?.compulsoryTag];

        await validateData(
          'packageSchema',
          {
            ...formValue,
            tags
          },
          data => {
            let params = {
              ...data,
              ...refactorListData(data),
              profiles: formValue.lstProfiles.map(item => ({
                ...item,
                profileId: item.id
              }))
            };

            onSubmit(params);
          }
        );
      }
    } catch (errs) {
      console.log('errs', errs);

      setErrors(errs);
    }
  };

  const onSubmit = data => {
    isCreate
      ? packageDispatcher.addPackage(data, () => {
          onClose();
          customToast(
            'success',
            <span>
              New <strong style={{ fontWeight: 600 }}>{data.name}</strong> has
              been successfully created.
            </span>,
            'New package created'
          );
        })
      : packageDispatcher.updatePackage(selectedItem.id, data, () => {
          onClose();
          customToast(
            'success',
            <span>
              <strong style={{ fontWeight: 600 }}>{selectedItem.name}</strong>{' '}
              has been successfully updated.
            </span>,
            'Package updated'
          );
        });
  };

  const handleProceed = async () => {
    if (currentStep === renderListTabs.length - 1) {
      return handleSubmit(formValue);
    }

    if (renderListTabs[currentStep].name === 'Available services') {
      return allScreeningsDispatcher.getRestrictLocationByIds(
        generateParamsTestIdsPackageIdsData(
          {
            lstTests: refactorListDataCategory(cloneDeep(formValue))
          },
          true
        ),
        restrictedLocationIds => {
          setFormValue(formValue => ({
            ...formValue,
            restrictedTestLocationIds: restrictedLocationIds
          }));
          return setCurrentStep(currentStep => currentStep + 1);
        }
      );
    }

    return setCurrentStep(currentStep => currentStep + 1);
  };

  const StepperButtons = () => (
    <Box display="flex" justifyContent="flex-end" p={1.25}>
      {currentStep !== 0 && (
        <StepperButton
          className="secondary"
          onClick={() => setCurrentStep(currentStep - 1)}
        >
          Back
        </StepperButton>
      )}

      <StepperButton disabled={isEmptyFilterParams} onClick={handleProceed}>
        {currentStep === renderListTabs.length - 1
          ? isCreate
            ? 'Create'
            : 'Save'
          : 'Next'}
      </StepperButton>
    </Box>
  );

  const renderListTabs = [
    {
      name: 'General information',
      validKey: ['name', 'code', 'packageType', 'tags'],
      component: (
        <GeneralInformationStep
          errors={errors}
          formValue={formValue}
          setFormValue={setFormValue}
          listTags={tagsPackage}
        />
      )
    },
    {
      name: 'Available services',
      component: (
        <AvailableServicesStep
          errors={errors}
          setErrors={setErrors}
          formValue={formValue}
          setFormValue={setFormValue}
        />
      )
    },
    {
      name: 'Restrict locations',
      component: (
        <RestrictLocations
          errors={errors}
          setErrors={setErrors}
          formValue={formValue}
          setFormValue={setFormValue}
          prefixName="Package"
        />
      )
    }
  ];
  const isEmptyFilterParams = useMemo(() => {
    if (renderListTabs[currentStep].validKey) {
      const filterKeys = renderListTabs[currentStep].validKey;
      return filterKeys.some(key =>
        isBoolean(formValue[key]) ? !formValue[key] : isEmpty(formValue[key])
      );
    }
  }, [renderListTabs, currentStep, formValue]);

  return (
    <Paper square>
      <CustomDrawerTitle onClose={onClose}>
        {isCreate ? 'Create new package' : 'Edit package'}
      </CustomDrawerTitle>
      <CustomStepper
        steps={renderListTabs}
        stepsControl={STEPS_CONTROL}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        allowSkip
        renderButtons={StepperButtons}
      >
        <main className={classes.mainWrapper}>
          {renderListTabs[currentStep].component}
        </main>
      </CustomStepper>
    </Paper>
  );
};

export default CreateAndEditPackage;

import {
  HS_SERVICE_TYPES,
  HS_UPCOMING_PAXSCREENING_STATUS,
  QUICK_PRICE_VALUE_RANGE,
} from '../constants';
import { CheckboxActive, CheckboxDefault } from '@/assets/svg';
import { getCostRange } from '@/helpers';
import CustomAsyncAutoComplete from '@/new-components/CustomAsyncAutoComplete';
import CustomQuickRangeGroup from '@/new-components/CustomChip/CustomGroupChips';
import CustomSelect from '@/new-components/CustomSelect';
import CustomSelectContainer from '@/new-components/CustomSelectOld/CustomSelectContainer';
import { Checkbox, Paper } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Popover from '@material-ui/core/Popover';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import { isBoolean } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import React, { useMemo, useState } from 'react';
import NumberFormat from 'react-number-format';

const useStyles = makeStyles((theme) => ({
  checkboxIcon: {
    padding: 0,
    marginRight: 4,
  },
  tagStyle: {
    maxWidth: 'calc(100% - 60px)', // Adjust based on your needs
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  selectWrapper: {
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'transparent',
    },

    '& .MuiAutocomplete-option': {
      padding: 8,
      borderRadius: 8,
      margin: '2px 8px',
      '&:first-child': {
        marginTop: 8,
      },
      '&:last-child': {
        marginBottom: 8,
      },
      '&.MuiAutocomplete-option[aria-selected="true"]': {
        backgroundColor: '#ffffff',
      },
    },
  },
}));

const CustomButton = withStyles({
  label: {
    fontWeight: 700,
  },
})(Button);

const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      height: 40,
      borderRadius: 8,
    },
    '& input': {
      padding: 8,
    },
  },
})(TextField);

const CustomInputLabel = withStyles({
  root: {
    marginBottom: 8,
    fontWeight: 600,
  },
})(InputLabel);

const CustomFilterInput = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      height: 40,
      borderRadius: 8,

      '& input': {
        padding: 0,
        marginLeft: 0,
      },

      '& input::placeholder': {
        opacity: 1,
        color: '#999999',
      },
    },
  },
})(TextField);

const GroupToolbar = ({
  serviceTypes,
  setServiceTypes,
  paxScreenStatuses,
  setPaxScreenStatuses,
  locationIds,
  setLocationIds,

  quickRange,
  setQuickRange,
  filterOption,
  setFilterOption,
  onFilterOptionClear,
  onFilterOptionChange,
  onResetFilterOps,
  maxPriceDefault,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleChangeQuickRange = (event, rangeType) => {
    setQuickRange(rangeType);
    if (rangeType) {
      let [minCost, maxCost] = getCostRange(rangeType, maxPriceDefault);
      setFilterOption({
        ...filterOption,
        minCost,
        maxCost,
      });
    } else {
      setFilterOption({
        ...filterOption,
        minCost: 0,
        maxCost: maxPriceDefault,
      });
    }
  };

  const isEmptyFilterParams = useMemo(() => {
    const filterKeys = Object.keys(filterOption).filter(
      (item) => !['paxScreenStatuses', 'startDate', 'endDate'].includes(item)
    );

    return (
      filterKeys.every((key) =>
        isBoolean(filterOption[key])
          ? !filterOption[key]
          : isEmpty(filterOption[key])
      ) &&
      paxScreenStatuses.every((item) => !item.length) &&
      !filterOption.maxCost &&
      !filterOption.minCost
    );
  }, [filterOption, paxScreenStatuses]);

  const renderValue = (array, label, defaultLabel) => {
    let value = '';
    if (array.length === 0) value = defaultLabel;
    else if (array.length === 1) value = array[0];
    else value = `${label} (${array.length})`;

    return value;
  };

  return (
    <Toolbar>
      <Box
        display="flex"
        flex={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" gridGap={12}>
          <CustomSelectContainer
            isFullWidth
            filterValue={serviceTypes || []}
            data={HS_SERVICE_TYPES}
            renderValue={renderValue(serviceTypes, 'Services', 'All services ')}
            onClear={onFilterOptionClear('serviceTypes', [], setServiceTypes)}
            onChange={onFilterOptionChange('serviceTypes', setServiceTypes)}
            labelSelectAll={'All services'}
            useSelectAll
            checkedAll={
              HS_SERVICE_TYPES.map((item) => item.value).length ===
              serviceTypes.length
            }
          />

          <CustomAsyncAutoComplete
            multiple
            disableCloseOnSelect
            isLocation
            endpoint="Locations/filter"
            method="post"
            nameKey="name"
            value={locationIds}
            onChange={(e, value) => {
              setFilterOption({
                ...filterOption,
                locationIds: value.map((it) => it.id),
              });
              setLocationIds(value);
            }}
            defaultFilter={{
              sortByName: true,
            }}
            getOptionLabel={(option) => option?.name}
            renderInput={(params) => (
              <CustomFilterInput
                {...params}
                variant="outlined"
                placeholder={isEmpty(locationIds) ? 'All locations' : ''}
              />
            )}
            renderOption={(option, { selected }) => {
              return (
                <Box display="flex" gridGap={4}>
                  <Checkbox
                    icon={<CheckboxDefault width={20} height={20} />}
                    checkedIcon={<CheckboxActive width={20} height={20} />}
                    checked={selected}
                    color="primry"
                    className={classes.checkboxIcon}
                  />
                  <span>{option.name}</span>
                </Box>
              );
            }}
            renderTags={(value, getTagProps) => {
              let newValue = value.filter((it) => locationIds?.includes(it));
              return newValue.length === 1 ? (
                <div className={classes.tagStyle}>{newValue[0].name}</div>
              ) : newValue.length > 1 ? (
                <Box>Locations ({newValue.length})</Box>
              ) : (
                ''
              );
            }}
            PaperComponent={({ children, ...other }) => (
              <Paper
                {...other}
                style={{ width: 320 }}
                className={classes.selectWrapper}
              >
                {children}
              </Paper>
            )}
            style={{ width: locationIds.length === 1 ? 200 : 160 }}
            isSelected={locationIds.length === 1}
          />

          <CustomSelectContainer
            isFullWidth
            filterValue={paxScreenStatuses || []}
            data={HS_UPCOMING_PAXSCREENING_STATUS}
            renderValue={
              !isEmpty(paxScreenStatuses)
                ? `Statuses (${paxScreenStatuses.length})`
                : 'All statuses'
            }
            onClear={onFilterOptionClear(
              'paxScreenStatuses',
              HS_UPCOMING_PAXSCREENING_STATUS,
              setPaxScreenStatuses
            )}
            onChange={onFilterOptionChange(
              'paxScreenStatuses',
              setPaxScreenStatuses
            )}
            labelSelectAll={'All statuses'}
            useSelectAll
            checkedAll={
              HS_UPCOMING_PAXSCREENING_STATUS.length ===
              paxScreenStatuses.length
            }
          />

          <CustomSelect
            isFullWidth
            isShowArrow
            onClick={handleClick}
            renderValue={
              filterOption.minCost || filterOption.maxCost
                ? `$${filterOption.minCost || 0} - $${
                    filterOption.maxCost || 0
                  }`
                : 'Total cost'
            }
          >
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              PaperProps={{
                style: {
                  border: '1px solid #F1F1F1',
                  boxShadow: '0 4px 16px 0 rgba(0,0,0,0.1)',
                },
              }}
            >
              <Box
                p={2}
                display="flex"
                justifyContent="space-between"
                gridGap={16}
              >
                <Box width="100%">
                  <CustomInputLabel>From</CustomInputLabel>
                  <FormControl component="fieldset" fullWidth>
                    <NumberFormat
                      customInput={CustomTextField}
                      variant="outlined"
                      placeholder="$0"
                      prefix="$ "
                      thousandSeparator=","
                      onValueChange={onFilterOptionChange('minCost')}
                      allowNegative={false}
                      decimalScale={2}
                      value={filterOption.minCost || ''}
                    />
                  </FormControl>
                </Box>
                <Box width="100%">
                  <CustomInputLabel>To</CustomInputLabel>
                  <FormControl component="fieldset" fullWidth>
                    <NumberFormat
                      customInput={CustomTextField}
                      variant="outlined"
                      placeholder="$0"
                      prefix="$ "
                      thousandSeparator=","
                      onValueChange={onFilterOptionChange('maxCost')}
                      allowNegative={false}
                      decimalScale={2}
                      value={filterOption.maxCost || ''}
                    />
                  </FormControl>
                </Box>
              </Box>
              <Divider light />
              <Box display="flex" p={2}>
                <CustomQuickRangeGroup
                  value={quickRange}
                  handleChange={handleChangeQuickRange}
                  listButtons={QUICK_PRICE_VALUE_RANGE}
                />
              </Box>
            </Popover>
          </CustomSelect>
        </Box>

        <CustomButton
          disabled={isEmptyFilterParams}
          onClick={() => {
            onResetFilterOps();
            setLocationIds([]);
          }}
        >
          Reset
        </CustomButton>
      </Box>
    </Toolbar>
  );
};

export default GroupToolbar;

export const COLUMN_ALL_COUNSELS = [
  {
    label: 'Name',
    stateValue: 'name'
  },
  {
    label: 'ID number',
    stateValue: 'idNumber',
    minWidth: 112
  },
  {
    label: 'Type',
    stateValue: 'counselType'
  },
  {
    label: 'Counsellor',
    stateValue: 'counsellor'
  },
  {
    label: 'Date & Time',
    stateValue: 'date',
    sortBy: 'Date',
    minWidth: 112
  },
  {
    label: 'Duration',
    stateValue: 'duration'
  },
  {
    label: 'Total cost',
    stateValue: 'totalCost',
    minWidth: 112
  },
  {
    label: 'Status',
    stateValue: 'status'
  },
  {
    label: 'Action'
  }
];

export const renderAllCounselsCol = isCounsellor => {
  let list = [...COLUMN_ALL_COUNSELS];
  if (isCounsellor) {
    list = list.filter(it => it.stateValue !== 'counsellor');
  }
  return list;
};

export const COUNSEL_STATUS = {
  InProgress: 'InProgress',
  Cancelled: 'Cancelled',
  Done: 'Done',
  ReadyToPay: 'ReadyToPay',
  ConsultCompleted: 'ConsultCompleted'
};

export const COUNSEL_DISPLAY_STATUS = {
  InProgress: 'In Progress',
  Cancelled: 'Cancelled',
  ReadyToPay: 'Need payment',
  Done: 'Completed',
  ConsultCompleted: 'Call ended'
};

export const COUNSEL_STATUS_COLORS = {
  [COUNSEL_DISPLAY_STATUS.InProgress]: {
    txt: '#9365E8',
    background: '#9365e829'
  },
  [COUNSEL_DISPLAY_STATUS.Cancelled]: {
    txt: '#333333',
    background: '#f1f1f1'
  },
  [COUNSEL_DISPLAY_STATUS.ConsultCompleted]: {
    txt: '#AD5E99',
    background: '#FFE9FB'
  },
  [COUNSEL_DISPLAY_STATUS.ReadyToPay]: {
    txt: '#23A6CC',
    background: '#23a6cc29'
  },
  [COUNSEL_DISPLAY_STATUS.InDelivery]: {
    txt: '#E37F2A',
    background: '#e37f2a29'
  },
  [COUNSEL_DISPLAY_STATUS.Done]: {
    txt: '#0B8E5E',
    background: '#15a47029'
  }
};

export const ALL_COUNSELS_STATUS = [
  {
    value: 'In Progress',
    key: 'InProgress'
  },
  {
    value: 'Cancelled',
    key: 'Cancelled'
  },
  {
    value: 'Completed',
    key: 'Done'
  },
  {
    value: 'Need payment',
    key: 'ReadyToPay'
  },
  {
    value: 'Call ended',
    key: 'ConsultCompleted'
  }
];

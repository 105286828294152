import React, { useEffect, useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  Box,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import { Editor } from '@tinymce/tinymce-react';

import { NEW_PACKAGE_TYPE } from '../constants';
import {
  TINY_INIT_CONFIGURATION,
  TINY_API_KEY
} from '@/constants/editorConfig';
import MutipleSelection from '@/components/CustomSelect/MutipleSelection';
import CustomSelect from '@/components/CustomSelect';
import { useSelector } from 'react-redux';
import { cloneDeep, isEmpty } from 'lodash';
import { ignoredSelectedTags } from '@/helpers';

const StyledNumberFormat = styled(NumberFormat)`
  border: none;
  border-bottom: 1px solid gray;
  outline: none;
  padding-bottom: 8px;
  margin-top: 20px;
  width: 100%;
`;

const GeneralInformation = ({
  personalInfo = {},
  setPersonalInfo,
  errors = {},
  disabled = false,
  price
}) => {
  const { tagsPackage } = useSelector(state => state.globalState);
  const [showModal, setShowModal] = useState(false);
  const [listTags, setListTags] = useState([]);

  const handleChange = key => e => {
    if (!setPersonalInfo) return;

    let value;
    switch (key) {
      case 'description':
      case 'shortDesc':
        value = e;
        break;

      case 'tags':
        value = e.target.value;
        const newList = ignoredSelectedTags(listTags, e.target.value);
        setListTags(newList);
        break;

      default:
        value = e.target.value;
        break;
    }
    setPersonalInfo(prevState => ({
      ...prevState,
      [key]: value
    }));
  };

  const handleChangeInputPrice = values => {
    setPersonalInfo &&
      setPersonalInfo({
        ...personalInfo,
        price: values.value
      });
  };

  useEffect(() => {
    if (!isEmpty(tagsPackage)) {
      const newListTags = cloneDeep(tagsPackage).map(it => ({
        key: it,
        value: it,
        isDisabled: false
      }));
      const newList = ignoredSelectedTags(newListTags, personalInfo?.tags);

      setListTags(newList);
    }
  }, [tagsPackage, personalInfo]);

  return (
    <Grid
      container
      spacing={4}
      style={{ margin: 0, width: '100%', minHeight: 250 }}
    >
      <Grid item xs={6}>
        <TextField
          disabled={disabled}
          fullWidth
          label="Package Name"
          value={personalInfo.name || ''}
          error={errors.name}
          helperText={errors.name}
          onChange={handleChange('name')}
        />
      </Grid>
      <Grid item xs={6}>
        <InputLabel id="id-label-type">Type</InputLabel>
        <Select
          disabled={disabled}
          fullWidth
          labelId="id-label-type"
          value={personalInfo.packageType}
          onChange={handleChange('packageType')}
          placeholder="Select type"
        >
          {NEW_PACKAGE_TYPE.map(item => (
            <MenuItem value={item.value}>{item.value}</MenuItem>
          ))}
        </Select>
        {errors.packageType && (
          <Typography color="error">{errors.packageType}</Typography>
        )}
      </Grid>
      <Grid item xs={6}>
        <TextField
          disabled={disabled}
          fullWidth
          label="Test Code"
          value={personalInfo.code || ''}
          error={errors.code}
          helperText={errors.code}
          onChange={handleChange('code')}
        />
      </Grid>
      <Grid item xs={6}>
        <InputLabel id="id-label-type">Tags</InputLabel>
        {disabled ? (
          <TextField disabled fullWidth value={personalInfo.tags || ''} />
        ) : (
          <Box py={1}>
            <CustomSelect
              isFullWidth
              onClick={() => setShowModal(!showModal)}
              renderValue={
                <>{personalInfo?.tags?.join(', ') || 'Select Tags'}</>
              }
              colorBorderBottomInput="#212121"
              colorInput="unset"
              clearable={!isEmpty(personalInfo?.tags)}
              onClear={() => {
                setPersonalInfo({
                  ...personalInfo,
                  tags: []
                });
              }}
            >
              <MutipleSelection
                value={personalInfo?.tags || []}
                data={listTags}
                open={showModal}
                onChange={handleChange('tags')}
              />
            </CustomSelect>
            {errors.tags && (
              <Typography color="error">{errors.tags}</Typography>
            )}
          </Box>
        )}
      </Grid>
      <Grid item xs={6}>
        <StyledNumberFormat
          prefix="$ "
          disabled={disabled}
          customInput={TextField}
          label="Default price"
          error={errors.price}
          helperText={errors.price}
          thousandSeparator=" "
          onValueChange={handleChangeInputPrice}
          allowNegative={false}
          decimalScale={2}
          value={price ? price : personalInfo.price || ''}
        />
      </Grid>
      <Grid item xs={6}></Grid>
      <Grid item lg={6} md={12} xs={12}>
        <InputLabel id="id-label-type" style={{ marginBottom: 8 }}>
          Long Description
        </InputLabel>

        <Editor
          disabled={disabled}
          apiKey={TINY_API_KEY}
          value={personalInfo?.description || ''}
          init={TINY_INIT_CONFIGURATION}
          onEditorChange={handleChange('description')}
        />
      </Grid>

      <Grid item lg={6} md={12} xs={12}>
        <InputLabel id="id-label-type" style={{ marginBottom: 8 }}>
          Short Description
        </InputLabel>
        <Editor
          disabled={disabled}
          apiKey={TINY_API_KEY}
          value={personalInfo?.shortDesc || ''}
          init={TINY_INIT_CONFIGURATION}
          onEditorChange={handleChange('shortDesc')}
        />
      </Grid>
    </Grid>
  );
};

export default GeneralInformation;

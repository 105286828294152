import {
  setCurrentUserId,
  getListChannels,
  listenChannels
} from '../../firebase';
import TableCellFilted from '../common/componentUI/TableCellFilted';
import teleQueueRoomDispatcher from './action';
import { StatusComponent } from './component/StatusComponent';
import { DOCTOR_STATUS, renderTeleQueueCol } from './constants';
import {
  CloseCircle,
  ConfirmIcon,
  ConnectingIcon,
  EndCall2Icon,
  NoDataTelecounsel,
  Teleconsult
} from '@/assets/svg';
import { ROLES_NAMES_SPECIAL, ROLE_TYPES } from '@/constants/roles';
import { loadFromStorage } from '@/helpers/StorageHelper';
import {
  rejoinCall,
  joinCall,
  rejectPatient,
  initHubConnection
} from '@/helpers/telecounsellingRoomSignalR';
import CustomMenu from '@/new-components/CustomMenu';
import CustomMenuActions from '@/new-components/CustomMenuActions';
import ModalConfirmation from '@/new-components/CustomModal/ModalConfirmation';
import ModalGenerateInvoice from '@/new-components/CustomModal/ModalGenerateInvoice';
import ModalVerifyID from '@/new-components/CustomModal/ModalVerifyID';
import CustomPaperContainer from '@/new-components/CustomPaperContainer';
import CustomHeader from '@/new-components/CustomPaperContainer/CustomHeader';
import CustomTable from '@/new-components/CustomTable';
import CustomTooltip from '@/new-components/CustomTooltip';
import StreamInCallPanel from '@/new-components/StreamInCallPanel';
import {
  Button,
  IconButton,
  TableCell,
  TableHead,
  TableRow,
  withStyles
} from '@material-ui/core';
import { MoreVertRounded } from '@material-ui/icons';
import { debounce, isEmpty } from 'lodash';
import moment from 'moment';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useSelector } from 'react-redux';

const StartCallButton = withStyles(theme => ({
  root: {
    padding: '8px 12px',
    minWidth: 93
  }
}))(Button);

const CustomButton = withStyles({
  root: {
    width: props => (props.width ? props.width : 166),
    height: 48,
    borderRadius: 8,
    margin: props => (props.margin ? props.margin : 'initial'),

    fontSize: 18,
    fontWeight: 400,
    textTransform: 'unset',
    '&.red': {
      color: '#ffffff',
      background: '#DB3D49'
    }
  },
  containedPrimary: {
    boxShadow: 'none'
  }
})(Button);

const TeleQueue = () => {
  const waitingList = useSelector(state => state.teleQueueRoom.waitingList);
  const calling = useSelector(state => state.teleQueueRoom.calling);
  const videoChannelId = useSelector(state => state.teleQueueRoom.channelId);
  const patientId = useSelector(state => state.teleQueueRoom.patientId);
  const callId = useSelector(state => state.teleQueueRoom.callId);

  const verifyingUserData = useSelector(
    state => state.teleQueueRoom.verifyingUserData
  );
  const rejoinCallData = useSelector(
    state => state.teleQueueRoom.rejoinCallData
  );

  const showVerifyModalVisibility = useSelector(
    state => state.teleQueueRoom.verifyModalVisibility
  );
  const showConsConfirmVisibility = useSelector(
    state => state.teleQueueRoom.consConfirmVisibility
  );
  const waitingModalVisibility = useSelector(
    state => state.teleQueueRoom.waitingModalVisibility
  );

  const currentUser = useSelector(state => state.auth.userInfo);
  const accessToken = useSelector(state => state.auth.accessToken);
  const { roleName } = useSelector(state => state.auth.userInfo);

  const [showModalInvoiceForm, setShowModalInvoiceForm] = useState(false);
  const [showModalReject, setShowModalReject] = useState(false);
  const [showModalCancel, setShowModalCancel] = useState(false);
  const [showModalDisconnected, setShowModalDisconnected] = useState(false);
  const [appointmentData, setAppointmentData] = useState();

  const [partner, setPartner] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState({});

  const [listChannels, setListchannels] = useState([]);

  const listChannelsRef = useRef();
  listChannelsRef.current = listChannels;

  const isCounsellor = currentUser.roleName === ROLE_TYPES.COUNSELLOR;

  const handleToggleDisconnectedModal = useCallback(() => {
    setShowModalDisconnected(!showModalDisconnected);
  }, [showModalDisconnected]);

  const orderWaitingList = useMemo(() => {
    let newList = [];
    newList = [...waitingList].sort(
      (a, b) => moment(a?.JoinedTime).unix() - moment(b?.JoinedTime).unix()
    );

    if (
      rejoinCallData &&
      ROLES_NAMES_SPECIAL.includes(currentUser.roleName) &&
      currentUser?.approveStatus === DOCTOR_STATUS.Approved
    ) {
      const formatRejoinData = {
        ...rejoinCallData,
        FullName: rejoinCallData.PatientFullName,
        PatientRoomStatus: 'Join'
      };

      newList.unshift(formatRejoinData);
    }

    return newList;
  }, [waitingList, rejoinCallData]);

  const handleToggleModalInvoiceForm = useCallback(() => {
    setShowModalInvoiceForm(!showModalInvoiceForm);
  }, [showModalInvoiceForm]);

  const handleToggleVerifyModal = useCallback(() => {
    teleQueueRoomDispatcher.setState(
      'verifyModalVisibility',
      !showVerifyModalVisibility
    );
  }, [showVerifyModalVisibility]);

  const handleToggleConsConfirmModal = useCallback(() => {
    teleQueueRoomDispatcher.setState(
      'consConfirmVisibility',
      !showConsConfirmVisibility
    );
  }, [showConsConfirmVisibility]);

  const handleToggleRejectModal = useCallback(() => {
    setShowModalReject(!showModalReject);
  }, [showModalReject]);

  const handleToggleCancelModal = useCallback(() => {
    setShowModalCancel(!showModalCancel);
  }, [showModalCancel]);

  useEffect(() => {
    if (currentUser.id) {
      initFirebase();
    }
    if (
      ROLES_NAMES_SPECIAL.includes(currentUser.roleName) &&
      currentUser.approveStatus !== DOCTOR_STATUS.Approved
    )
      return;

    setTimeout(() => initHubConnection(accessToken, currentUser.id), 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser.id]);

  useEffect(() => {
    teleQueueRoomDispatcher.getTeleConsultStatus();

    return () => {};
  }, []);

  useEffect(() => {
    debounceLoadData(rejoinCallData, calling);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rejoinCallData, calling]);

  const debounceLoadData = useCallback(
    debounce((rejoinCallData, calling) => {
      if (!isEmpty(rejoinCallData)) {
        handleToggleDisconnectedModal();
        teleQueueRoomDispatcher.setState('consConfirmVisibility', false);
      } else {
        teleQueueRoomDispatcher.checkTeleCounsellingPending(data => {
          setAppointmentData(data);
          // if (!calling && data && data.consultStatus === 'ConsultCompleted')
          //   handleToggleModalInvoiceForm();

          if (!calling && data && data.consultStatus === 'InProgress') {
            handleToggleConsConfirmModal();
          }
        });
      }
    }, 500),
    []
  );

  const initFirebase = () => {
    setCurrentUserId(currentUser.id, currentUser);
    updateListChannels();
  };

  const updateListChannels = async () => {
    let listChannels = await getListChannels(currentUser.id);
    listenChannels(currentUser.id, data => {
      if ([...listChannelsRef.current].find(channel => channel.id === data.id))
        setListchannels(
          [...listChannelsRef.current].map(i => (i.id === data.id ? data : i))
        );
      else {
        setListchannels([...listChannelsRef.current, data]);
      }
    });
    setListchannels(listChannels);
  };

  const getPatientChannel = () => {
    return listChannels.find(channel => channel?.members?.includes(partner.id));
  };

  const Timer = ({ time }) => {
    const [duration, setDuration] = useState(0);

    useEffect(() => {
      const serverTimeOffset = loadFromStorage('serverTimeOffset');
      const joinedTimestamp = moment.utc(time).unix() * 1000;

      const intervalId = setInterval(() => {
        const currentTimestamp = moment.utc().unix() * 1000 + serverTimeOffset;
        setDuration(() => currentTimestamp - joinedTimestamp);
      }, 1000);

      return () => clearInterval(intervalId);
    }, [time]);

    return moment.utc(duration).format('mm:ss');
  };

  const doVerify = ({ data }) => {
    teleQueueRoomDispatcher.getMemberAppointment(data?.UserId, () => {
      teleQueueRoomDispatcher.setState('verifyModalVisibility', true);
      teleQueueRoomDispatcher.setState('verifyingUserData', data);
    });
  };

  const doCalling = async ({ data }) => {
    teleQueueRoomDispatcher.setState('waitingModalVisibility', true);
    teleQueueRoomDispatcher.setState('verifyingUserData', data);
    await joinCall(data.UserId);
  };

  const callPatient = async data => {
    setPartner({
      id: data.UserId,
      name: data.FullName,
      imageUrl: null
    });

    if (data.IdentityStatus !== DOCTOR_STATUS.Approved) {
      doVerify({ data });
    } else {
      if (data.BackCorperatePlan && data.FrontCorperatePlan) {
        doVerify({ data });
      } else {
        doCalling({ data });
      }
    }
  };

  const handleCloseVerifyModal = shouldCleanUp => {
    handleToggleVerifyModal();
    if (shouldCleanUp) {
      // clean up memberAppointment
      teleQueueRoomDispatcher.setState('memberAppointment', null);
    }
  };

  const submitGenerateInvoice = (id, data) => {
    teleQueueRoomDispatcher.generateInvoice(id, data);
    handleToggleModalInvoiceForm();
  };

  const renderListActions = selectedItem => {
    let listActions = [];

    listActions.push({
      key: 'cancel',
      icon: CloseCircle,
      label: 'Cancel call',
      color: '#DB3D49',
      styles: { width: 18, height: 18 },
      onClick: () => {
        setAnchorEl(null);
        setShowModalCancel(true);
      }
    });

    return listActions;
  };

  const renderTableBody = useCallback(
    (row, index) => {
      return (
        <>
          <TableCell>{index + 1}</TableCell>
          <TableCell>
            <CustomTooltip content={row.FullName || '—'} />
          </TableCell>
          <TableCell>
            <CustomTooltip content={row.IdentityNumber || '—'} />
          </TableCell>
          <TableCell>{row.Age || '—'}</TableCell>
          <TableCell>{row.Gender || '—'}</TableCell>
          <TableCell>
            {row.AppointmentDate
              ? moment(row.AppointmentDate).format('LT')
              : '—'}
          </TableCell>
          <TableCell>
            {rejoinCallData ? (
              <Timer time={row.CallProblemTime} />
            ) : (
              <Timer time={row.JoinedTime} />
            )}
          </TableCell>
          {!isCounsellor && (
            <TableCell>
              <CustomTooltip content={row.CounsellorName || '—'} />
            </TableCell>
          )}
          <TableCell>
            <StatusComponent status={rejoinCallData ? 'Join' : 'Waiting'} />
          </TableCell>
          {isCounsellor && (
            <TableCell>
              {ROLES_NAMES_SPECIAL.includes(roleName) && (
                <>
                  <StartCallButton
                    startIcon={<Teleconsult />}
                    color="primary"
                    variant="contained"
                    disabled={
                      rejoinCallData && row.PatientRoomStatus !== 'Join'
                    }
                    onClick={() => {
                      if (rejoinCallData) {
                        rejoinCall();
                        teleQueueRoomDispatcher.setState(
                          'patientId',
                          rejoinCallData.PatientId
                        );
                        teleQueueRoomDispatcher.setState(
                          'verifyingUserData',
                          rejoinCallData
                        );
                        setPartner({
                          id: rejoinCallData.PatientId,
                          name: `${rejoinCallData.PatientFullName}`,
                          imageUrl: null
                        });
                      } else callPatient(row);
                    }}
                  >
                    {rejoinCallData && row.PatientRoomStatus === 'Join'
                      ? 'Rejoin'
                      : 'Start'}
                  </StartCallButton>
                  {rejoinCallData && (
                    <IconButton
                      hidden={row.PatientRoomStatus !== 'Join'}
                      onClick={e => {
                        setAnchorEl(e.currentTarget);
                        setSelectedItem(row);
                      }}
                    >
                      <MoreVertRounded />
                    </IconButton>
                  )}
                </>
              )}
            </TableCell>
          )}
        </>
      );
    },
    [rejoinCallData]
  );

  const TableHeader = () => (
    <TableHead>
      <TableRow>
        {renderTeleQueueCol(isCounsellor).map(item => (
          <TableCellFilted
            key={item.stateValue}
            label={item.label}
            stateValue={item.stateValue}
            style={{ minWidth: item.minWidth || 'unset' }}
            isHidden={false}
          />
        ))}
      </TableRow>
    </TableHead>
  );

  return (
    <>
      <CustomMenu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <CustomMenuActions listActions={renderListActions(selectedItem)} />
      </CustomMenu>
      <CustomPaperContainer
        header={<CustomHeader isSearch={false} title="Queue" />}
      >
        <CustomTable
          data={orderWaitingList}
          header={TableHeader}
          renderRow={renderTableBody}
          Icon={NoDataTelecounsel}
          noDataHelperText="No clients in queue"
          noDataHelperSubText="Please come back at another time."
          totalCols={renderTeleQueueCol(isCounsellor).length}
          style={{ height: 'calc(100vh - 207px)' }}
        />
      </CustomPaperContainer>

      {calling && (
        <StreamInCallPanel
          patientId={patientId}
          videoChannelId={videoChannelId}
          currentUser={currentUser}
          channelInfo={getPatientChannel()}
          partner={partner}
          updateListChannels={updateListChannels}
          verifyingUserData={verifyingUserData}
          doVerify={doVerify}
        />
      )}

      {showVerifyModalVisibility && (
        <ModalVerifyID
          setShowModalReject={setShowModalReject}
          verifyingUserData={verifyingUserData}
          open={showVerifyModalVisibility}
          onClose={handleToggleVerifyModal}
          doCalling={doCalling}
          closeModal={handleCloseVerifyModal}
        />
      )}
      {showModalReject && (
        <ModalConfirmation
          open={showModalReject}
          onClick={() => {
            handleCloseVerifyModal(true);
            handleToggleRejectModal();
            rejectPatient(verifyingUserData.UserId);
          }}
          onClose={handleToggleRejectModal}
          mainContent="Reject ID"
          subContent="Are you sure you want to reject this client’s ID?"
          cancelLabel="Cancel"
          confirmLabel="Reject"
          confirmColor="red"
        />
      )}

      {showConsConfirmVisibility && (
        <ModalConfirmation
          open={!!showConsConfirmVisibility && !showVerifyModalVisibility}
          disableBackdropClick
          onClose={handleToggleConsConfirmModal}
          mainContent="Complete appointment?"
          subContent="Please note appointment details to create an invoice after completing"
          Icon={<ConfirmIcon />}
          otherActionsContent={() => (
            <>
              <CustomButton
                onClick={() => {
                  teleQueueRoomDispatcher.cancelConsult(
                    appointmentData.callId || callId
                  );
                  handleToggleConsConfirmModal();
                }}
                variant="contained"
                className="red"
              >
                Cancel call
              </CustomButton>
              <CustomButton
                onClick={() => {
                  teleQueueRoomDispatcher.completeConsult(
                    appointmentData.callId || callId
                  );
                  handleToggleConsConfirmModal();
                  // handleToggleModalInvoiceForm();
                }}
                variant="contained"
                color="primary"
              >
                Complete
              </CustomButton>
            </>
          )}
        />
      )}

      {waitingModalVisibility && (
        <ModalConfirmation
          open={!!waitingModalVisibility}
          onClose={() => {}}
          mainContent="Connecting"
          subContent="Please wait for the client to connect the call with you."
          isShowActions={false}
          Icon={<ConnectingIcon />}
        />
      )}

      {showModalCancel && (
        <ModalConfirmation
          open={!!showModalCancel}
          onClick={() => {
            rejoinCall(false);
            teleQueueRoomDispatcher.setState('rejoinCallData', null);
            handleToggleCancelModal();
          }}
          onClose={handleToggleCancelModal}
          mainContent="Cancel call"
          subContent="Are you sure you want to cancel this call?"
          Icon={<EndCall2Icon />}
          confirmLabel="Confirm"
          confirmColor="red"
        />
      )}

      {/* {showModalInvoiceForm && (
        <ModalGenerateInvoice
          counsellorData={currentUser}
          patientData={
            !isEmpty(appointmentData) ? appointmentData : verifyingUserData
          }
          open={!!showModalInvoiceForm}
          onClose={handleToggleModalInvoiceForm}
          onSubmit={submitGenerateInvoice}
          isPending={!isEmpty(appointmentData)}
        />
      )} */}

      {showModalDisconnected && (
        <ModalConfirmation
          disableBackdropClick
          open={!!showModalDisconnected && rejoinCallData}
          mainContent="Disconnected call"
          subContent={
            <span>
              Your previous call with{' '}
              <strong>{rejoinCallData?.PatientFullName}</strong> has been
              disconnected.
            </span>
          }
          otherActionsContent={() => (
            <>
              <CustomButton
                onClick={handleToggleDisconnectedModal}
                variant="contained"
                color="primary"
                width="100%"
                margin="0 16px"
              >
                Done
              </CustomButton>
            </>
          )}
        />
      )}
    </>
  );
};

export default TeleQueue;

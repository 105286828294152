import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  capitalize,
  includes,
  isBoolean,
  isEmpty,
  lowerCase,
  map
} from 'lodash';
import { CloseIcon } from '@/assets/svg';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import CustomStepper from '@/new-components/CustomStepper';
import { STEPS_CONTROL } from '@/module/setup/constants';
import {
  StepperButton,
  StepperControl
} from '@/new-components/CustomStepper/styled';
import customToast from '@/new-components/CustomNotification';

import { SERVICE_STEP_TYPES, SPECIAL_MEASUREMENT } from '../../constants';
import ConsentFormStep from '../consent/ConsentFormStep';
import { DEFAULT_COMPULSORY_TAGS } from '@/module/configuration/constants';
import validateData from '@/helpers/validationHelpers/validationSchema';

import globalDispatcher from '@/redux/global/actions';
import { Button } from '@material-ui/core';
import ModalConfirmation from '@/new-components/CustomModal/ModalConfirmation';
import RestrictLocations from '@/module/configuration/component/Packages/RestrictLocations';

const titleStyles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    border: '1px solid #f1f1f1'
  },
  title: {
    fontWeight: 600,
    fontSize: 18,
    color: '#333'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 14,
    color: theme.palette.grey[500]
  }
});

const useStyles = makeStyles(theme => ({
  mainStepperWrapper: {
    height: 'calc(100vh - 200px)',
    overflow: 'auto'
  },
  mainWrapper: {
    height: 'calc(100vh - 140px)',
    overflow: 'auto'
  },
  stepsControlWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(1)
  }
}));

const CustomDrawerTitle = withStyles(titleStyles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <Box className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </Box>
  );
});

const ServiceModalContainer = ({
  prefixName,
  category,
  createDispatcher,
  editDispatcher,
  getServiceByID,
  schema,

  requireLabCode,
  requireLoinc,

  steps = [],

  onClose,
  isCreate,
  selectedItem,
  ServicesForm,
  listValidKeys = []
}) => {
  const tagsAddOns = useSelector(state => state.globalState.tagsAddOns);
  const { consents: listConsents = [] } = useSelector(
    state => state.globalState
  );
  const classes = useStyles();
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [errors, setErrors] = useState({});
  const [formValue, setFormValue] = useState({
    name: '',
    labHeader: '',
    loinc: '',
    euroFinLOINC: '',
    consents: [],
    consentIds: [],
    tags: ['General'],
    compulsoryTag: 'General',
    restrictedLocationIds: []
  });

  useEffect(() => {
    globalDispatcher.getListTagsAddOns();
    globalDispatcher.getListStations();
    globalDispatcher.getListConsentForm();
  }, []);

  // Fetch services
  useEffect(() => {
    if (!isCreate) {
      getServiceByID(selectedItem.id, result => {
        const { consents, tags, ...resRestutls } = result;
        const compulsoryTag = tags?.find(spec =>
          DEFAULT_COMPULSORY_TAGS.some(el => spec.includes(el))
        );
        const optionalTags = tags?.filter(
          spec => !DEFAULT_COMPULSORY_TAGS.some(el => spec.includes(el))
        );

        // Keep the consents is empty & update on the useEffect below
        setFormValue({
          ...resRestutls,
          consents: [],
          consentIds: consents,
          optionalTags,
          compulsoryTag
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreate]);

  // Filter list consents & update consents for render items in Table
  // Because ConsentTable check render by consents.length
  useEffect(() => {
    if (formValue?.consentIds?.length && listConsents?.length) {
      const fullConsents = listConsents.filter(consent =>
        includes(formValue.consentIds, consent.id)
      );

      setFormValue({ ...formValue, consents: fullConsents });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValue?.consentIds?.length, listConsents]);

  const handleSubmit = async () => {
    const labHeader = (formValue.labHeader || '').trim();
    const tags = !isEmpty(formValue?.optionalTags)
      ? formValue?.optionalTags.concat(formValue?.compulsoryTag)
      : [formValue?.compulsoryTag];

    try {
      await validateData(
        schema,
        {
          ...formValue,
          labHeader,
          requireLabHeader: requireLabCode,
          requireLoinc: requireLoinc,
          param: 'Test Code',
          tags
        },
        data => {
          const consents = map(data.consents, consent => consent.id);
          onSubmit({
            ...data,
            consents
          });
        }
      );
    } catch (errs) {
      setErrors(errs);
    }
  };

  const renderToast = (isCreate, data, mainType) => {
    return customToast(
      'success',
      <span>
        {capitalize(mainType)}{' '}
        <strong style={{ fontWeight: 600 }}>{data}</strong> has been
        successfully {isCreate ? 'created' : 'updated'}.
      </span>,
      isCreate ? `New ${mainType} created` : ` ${capitalize(mainType)} updated`
    );
  };

  const onSubmit = data =>
    isCreate
      ? createDispatcher(data, category, () => {
          onClose();
          renderToast(isCreate, data.name, prefixName);
        })
      : editDispatcher(data, category, () => {
          onClose();
          renderToast(isCreate, data.name, prefixName);
        });

  const StepperButtons = () => (
    <Box display="flex" justifyContent="flex-end" p={1.25}>
      {currentStep !== 0 && (
        <StepperButton
          className="secondary"
          onClick={() => setCurrentStep(currentStep - 1)}
        >
          Back
        </StepperButton>
      )}

      <StepperButton
        disabled={isEmptyFilterParams}
        onClick={() => {
          if (currentStep === steps.length - 1) {
            handleSubmit(formValue);
          } else setCurrentStep(currentStep + 1);
        }}
      >
        {currentStep === steps.length - 1
          ? isCreate
            ? 'Create'
            : 'Save changes'
          : 'Next'}
      </StepperButton>
    </Box>
  );

  const LIST_TABS = {
    [SERVICE_STEP_TYPES.GENERAL_INFORMATION]: {
      label: 'General Information',
      component: (
        <ServicesForm
          errors={errors}
          formValue={formValue}
          setFormValue={setFormValue}
          listTags={tagsAddOns}
          showModalConfirm={showModalConfirm}
          setShowModalConfirm={setShowModalConfirm}
        />
      )
    },
    [SERVICE_STEP_TYPES.CONSENT_FORM]: {
      label: 'Consent form',
      component: (
        <ConsentFormStep
          errors={errors}
          formValue={formValue}
          setFormValue={setFormValue}
        />
      )
    },
    [SERVICE_STEP_TYPES.RESTRICT_LOCATION]: {
      label: 'Restrict locations',
      component: (
        <RestrictLocations
          errors={errors}
          formValue={formValue}
          setFormValue={setFormValue}
          prefixName={prefixName}
        />
      )
    }
  };

  const isEmptyFilterParams = useMemo(() => {
    if (listValidKeys) {
      const filterKeys = listValidKeys;
      return filterKeys.some(key =>
        isBoolean(formValue[key]) ? !formValue[key] : isEmpty(formValue[key])
      );
    }
  }, [listValidKeys, formValue]);

  const handleEditSubmit = () => {
    if (
      category === 'Measurements' &&
      !isCreate &&
      selectedItem.group !== formValue.group &&
      SPECIAL_MEASUREMENT.includes(formValue.labHeader)
    ) {
      setShowModalConfirm(true);
    } else {
      handleSubmit(formValue);
    }
  };

  return (
    <Paper square elevation={0}>
      <CustomDrawerTitle onClose={onClose}>
        {isCreate
          ? `Create new ${lowerCase(prefixName)}`
          : `Edit ${lowerCase(prefixName)}`}
      </CustomDrawerTitle>
      {steps.length ? (
        <CustomStepper
          steps={steps}
          stepsControl={STEPS_CONTROL}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          allowSkip
          renderButtons={StepperButtons}
        >
          <main className={classes.mainStepperWrapper}>
            {LIST_TABS[steps[currentStep].type].component}
          </main>
        </CustomStepper>
      ) : (
        <>
          <main className={classes.mainWrapper}>
            <ServicesForm
              errors={errors}
              formValue={formValue}
              setFormValue={setFormValue}
              listTags={tagsAddOns}
              showModalConfirm={showModalConfirm}
              setShowModalConfirm={setShowModalConfirm}
            />
          </main>
          <StepperControl className={classes.stepsControlWrapper}>
            <StepperButton
              disabled={isEmptyFilterParams}
              onClick={handleEditSubmit}
            >
              {isCreate ? 'Create' : 'Save changes'}
            </StepperButton>
          </StepperControl>
        </>
      )}
      {showModalConfirm && (
        <ModalConfirmation
          Icon={null}
          open={showModalConfirm}
          onClick={() => {
            handleSubmit(formValue);
            setShowModalConfirm(false);
          }}
          onClose={() => {
            setFormValue({
              ...formValue,
              group: selectedItem?.group || 'None'
            });
            setShowModalConfirm(false);
          }}
          mainContent={`Update category of ${formValue.name}`}
          subContent={
            'Are you sure you want to change this measurement category? ' +
            'This action will result in all measurements belonging to that group being updated to the new category!'
          }
          cancelLabel="Cancel"
          confirmLabel="Continue"
        />
      )}
    </Paper>
  );
};

export default ServiceModalContainer;

import React, { useEffect, useState } from 'react';
import { CloseIcon } from '@/assets/svg';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import CustomStepper from '@/new-components/CustomStepper';
import { DEFAULT_COMPULSORY_TAGS, PACKAGE_STEPS } from '../../constants';
import { STEPS_CONTROL } from '@/module/setup/constants';
import { StepperButton } from '@/new-components/CustomStepper/styled';
import GeneralInformationStep from './GeneralInformationStep';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { capitalize, isBoolean, isEmpty } from 'lodash';
import validateData from '@/helpers/validationHelpers/validationSchema';

import globalDispatcher from '@/redux/global/actions';

import AvailableServicesStep from './AvailableServicesStep';
import { healthProfileDispatcher } from '@/module/health-profile';
import customToast from '@/new-components/CustomNotification';

const titleStyles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  title: {
    fontWeight: 600,
    fontSize: 18,
    color: '#333'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 14,
    color: theme.palette.grey[500]
  }
});

const useStyles = makeStyles({
  mainWrapper: {
    height: 'calc(100vh - 200px)',
    overflow: 'auto'
  }
});

const CustomDrawerTitle = withStyles(titleStyles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <Box className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </Box>
  );
});

const CreateAndEditProfile = ({ onClose, isCreate, selectedItem }) => {
  const tagsAddOns = useSelector(state => state.globalState.tagsAddOns);

  const classes = useStyles();
  const [currentStep, setCurrentStep] = useState(0);
  const [errors, setErrors] = useState({});
  const [formValue, setFormValue] = useState({
    name: '',
    code: '',
    lstTests: [],
    lstOldProfiles: [],
    price: '',
    tags: ['General'],
    compulsoryTag: 'General'
  });

  useEffect(() => {
    if (!isCreate) {
      healthProfileDispatcher.getProfileById(selectedItem.id, data => {
        const compulsoryTag = data?.tags?.find(spec =>
          DEFAULT_COMPULSORY_TAGS.some(el => spec.includes(el))
        );
        const optionalTags = data?.tags?.filter(
          spec => !DEFAULT_COMPULSORY_TAGS.some(el => spec.includes(el))
        );
        setFormValue({ ...data, optionalTags, compulsoryTag });
      });
    }

    globalDispatcher.getListTagsAddOns();
  }, []);

  const handleSubmit = async () => {
    try {
      const tags = !isEmpty(formValue?.optionalTags)
        ? formValue?.optionalTags.concat(formValue?.compulsoryTag)
        : [formValue?.compulsoryTag];
      await validateData(
        'healthProfileSchema',
        {
          ...formValue,
          tags
        },
        data => {
          onSubmit({
            ...data,
            ...refactorListData()
          });
        }
      );
    } catch (errs) {
      setErrors(errs);
    }
  };

  const renderToast = (isCreate, data, mainType) => {
    return customToast(
      'success',
      <span>
        <strong style={{ fontWeight: 600 }}>{data}</strong> has been
        successfully {isCreate ? 'created' : 'updated'}.
      </span>,
      isCreate ? `New ${mainType} created` : ` ${capitalize(mainType)} updated`
    );
  };

  const onSubmit = data => {
    isCreate
      ? healthProfileDispatcher.addProfile(data, () => {
          onClose();
          renderToast(isCreate, data.name, 'profile');
        })
      : healthProfileDispatcher.updateProfile(selectedItem.id, data, () => {
          onClose();
          renderToast(isCreate, data.name, 'profile');
        });
  };

  const refactorListData = () => {
    let lstOldProfileIds = formValue.lstOldProfiles.map(i => i.id);
    let lstTestIds = formValue.lstTests.map(i => i.id);
    return {
      lstOldProfileIds,
      lstTestIds
    };
  };

  const StepperButtons = () => (
    <Box display="flex" justifyContent="flex-end" p={1.25}>
      {currentStep !== 0 && (
        <StepperButton
          className="secondary"
          onClick={() => setCurrentStep(currentStep - 1)}
        >
          Back
        </StepperButton>
      )}

      <StepperButton
        disabled={isEmptyFilterParams}
        onClick={() => {
          if (currentStep === PACKAGE_STEPS.length - 1) {
            handleSubmit(formValue);
          } else setCurrentStep(currentStep + 1);
        }}
      >
        {currentStep === PACKAGE_STEPS.length - 1
          ? isCreate
            ? 'Create'
            : 'Save'
          : 'Next'}
      </StepperButton>
    </Box>
  );

  const renderListTabs = [
    {
      label: 'General Information',
      validKey: ['name', 'code', 'tags'],
      component: (
        <GeneralInformationStep
          errors={errors}
          formValue={formValue}
          setFormValue={setFormValue}
          listTags={tagsAddOns}
        />
      )
    },
    {
      label: 'Available services',
      component: (
        <AvailableServicesStep
          errors={errors}
          setErrors={setErrors}
          formValue={formValue}
          setFormValue={setFormValue}
        />
      )
    }
  ];

  const isEmptyFilterParams = useMemo(() => {
    if (renderListTabs[currentStep].validKey) {
      const filterKeys = renderListTabs[currentStep].validKey;
      return filterKeys.some(key =>
        isBoolean(formValue[key]) ? !formValue[key] : isEmpty(formValue[key])
      );
    }
  }, [renderListTabs, currentStep, formValue]);

  return (
    <Paper square>
      <CustomDrawerTitle onClose={onClose}>
        {isCreate ? 'Create new profile' : 'Edit profile'}
      </CustomDrawerTitle>
      <CustomStepper
        steps={PACKAGE_STEPS}
        stepsControl={STEPS_CONTROL}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        allowSkip
        renderButtons={StepperButtons}
      >
        <main className={classes.mainWrapper}>
          {renderListTabs[currentStep].component}
        </main>
      </CustomStepper>
    </Paper>
  );
};

export default CreateAndEditProfile;

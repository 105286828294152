import { createDownloadableLink } from '@/helpers';
import customToast from '@/new-components/CustomNotification';
import moment from 'moment';
import { synthesize } from 'redux-dispatcher';
import { FORMAT_DD_MM_YYYY } from '../constants';
import { isEmpty } from 'lodash';

const mapDispatchToAC = {
  getCompanySuccess: data => ({ data }),
  getCompany: (search, paging, filterOption) => async ({ Api, getState }) => {
    let pagingData = paging || getState().company.paging;
    let { result, status } = await Api.post(`Companies/filter`, {
      pageIndex: pagingData.pageIndex,
      pageSize: pagingData.pageSize,
      search,
      ...filterOption
    });
    if (status === 200) {
      const { list, ...restParams } = result;
      const baseIndex = pagingData.pageIndex * pagingData.pageSize + 1;
      const dataAddIndex = list.map((item, index) => ({
        ...item,
        index: baseIndex + index
      }));

      companyDispatcher.getCompanySuccess({
        ...restParams,
        list: dataAddIndex
      });
    }
  },
  getDetailedCompany: (companyId, callbackSuccess) => async ({ Api }) => {
    const { result, status } = await Api.get(`Companies/${companyId}`);
    if (status === 200) {
      callbackSuccess && callbackSuccess(result);
    }
  },
  getKeyCompany: () => async ({ Api }) => {
    const { result, status } = await Api.get(`Companies/key-values`);
    status === 200 && companyDispatcher.getCompanySuccess({ list: result });
  },
  setState: (state, value) => ({ state, value }),
  createCompany: (data, callback) => async ({ Api }) => {
    const { status, result, message } = await Api.post('Companies', data);
    companyDispatcher.setState('loading', false);
    if (status === 200) {
      callback && callback(result);
      return customToast('success', message || 'Create success');
    }
  },
  editCompany: (payload, callbackSuccess) => async ({ Api, customToast }) => {
    const { data, id } = payload;
    const { result, status } = await Api.put(`Companies/${id}`, data);
    companyDispatcher.setState('loading', false);
    if (status === 200) {
      callbackSuccess && callbackSuccess(result);
    }
  },
  deleteCompany: (id, callbackSuccess) => async ({ Api, customToast }) => {
    const { status } = await Api.delete(`Companies/${id}`);
    if (status === 200) {
      customToast('success', 'Company deleted');
      callbackSuccess && callbackSuccess();
    }
  },
  createProject: (data, callbackSuccess) => async ({ Api, customToast }) => {
    const { status } = await Api.post(`Projects`, data);
    if (status === 200) {
      callbackSuccess && callbackSuccess();
    }
  },
  editProject: (id, data, callbackSuccess) => async ({ Api, customToast }) => {
    const { status } = await Api.put(`Projects/${id}`, data);
    if (status === 200) {
      callbackSuccess && callbackSuccess();
    }
  },
  getProjectByCompanyID: (companyId, callbackSuccess) => async ({ Api }) => {
    const { status, result } = await Api.get(`Projects`, { companyId });
    if (status === 200) {
      callbackSuccess && callbackSuccess(result.list);
    }
  },
  getProjectByID: (id, callbackSuccess) => async ({ Api, customToast }) => {
    const { result, status } = await Api.get(`Projects/${id}`);
    if (status === 200) {
      callbackSuccess && callbackSuccess(result);
    }
  },
  getPackageDataSuccess: data => ({ data }),
  getPackageById: (id, callbackSuccess) => async ({ Api, customToast }) => {
    const { result, status } = await Api.get(`Packages/${id}`);
    if (status === 200) {
      callbackSuccess(result);
    }
  },
  deleteProject: (id, callbackSuccess) => async ({ Api, customToast }) => {
    const { status } = await Api.delete(`Projects/${id}`);
    if (status === 200) {
      customToast('success', 'Deleted');
      callbackSuccess && callbackSuccess();
    }
  },
  getExportExcel: (search, paging, filterOption) => async ({
    Api,
    getState
  }) => {
    let pagingData = paging || getState().company.paging;
    let { result, status } = await Api.post(`Companies/csv-report`, {
      pageIndex: pagingData.pageIndex,
      pageSize: pagingData.pageSize,
      search,
      ...filterOption
    });
    status === 200 && createDownloadableLink(result, 'companies-report');
  },
  getProjectByCode: (code, callbackSuccess) => async ({ Api }) => {
    const { result, status } = await Api.get(`Projects/by-code?code=${code}`);
    if (status === 200) {
      callbackSuccess && callbackSuccess(result);
    } else {
      callbackSuccess && callbackSuccess(false);
    }
  },
  getProjectOrganization: callbackSuccess => async ({ Api }) => {
    const { result, status } = await Api.get(`Projects/pm-codes`);
    if (status === 200) {
      callbackSuccess && callbackSuccess(result);
    }
  },
  getProjectKeyValue: callbackSuccess => async ({ Api }) => {
    const { result, status } = await Api.get(`Projects/key-values`);
    if (status === 200) {
      callbackSuccess && callbackSuccess(result);
    }
  },
  getLocationKeyValue: callbackSuccess => async ({ Api }) => {
    const { result, status } = await Api.get(`Locations/key-values`);
    if (status === 200) {
      const newResult = result.filter(it => !isEmpty(it.name));
      callbackSuccess && callbackSuccess(newResult);
    }
  },
  getProjectByLocation: (locationIds, callbackSuccess) => async ({ Api }) => {
    const { status, result } = await Api.post(`Projects/all-by-location`, {
      locationIds
    });
    if (status === 200) {
      callbackSuccess && callbackSuccess(result);
    }
  },
  getExportCRReport: projectId => async ({ Api }) => {
    let { result, status } = await Api.post(
      `Projects/${projectId}/compiling-cr-report`
    );
    const fileName = `CR_Report_${moment().format(FORMAT_DD_MM_YYYY)}`;
    status === 200 && createDownloadableLink(result, fileName);
  },
  getDownloadSubsidyForm: (projectId, projectName) => async ({ Api }) => {
    let {
      result,
      status
    } = await Api.get(`Projects/download-subsidy-claim-form`, { projectId });
    const fileName = `${projectName} Subsidy Claim Forms`;
    status === 200 && createDownloadableLink(result, fileName, 'zip');
  },
  getStationsByProject: (projectId, callbackSuccess) => async ({ Api }) => {
    const { status, result } = await Api.get(
      `Projects/${projectId}/station-config`
    );
    if (status === 200) {
      callbackSuccess && callbackSuccess(result);
    }
  },
  editSequencesStations: (projectId, items, callbackSuccess) => async ({
    Api
  }) => {
    const { status } = await Api.put(`Projects/${projectId}/station-config`, {
      items
    });
    if (status === 200) callbackSuccess && callbackSuccess();
  },
  getAllProjects: callbackSuccess => async ({ Api }) => {
    const { result, status } = await Api.get(`Projects`);
    if (status === 200) {
      callbackSuccess && callbackSuccess(result);
    }
  }
};

const companyDispatcher = synthesize('company', mapDispatchToAC);
export default companyDispatcher;

import React, { useEffect, useMemo, useState } from 'react';
import { CloseIcon } from '@/assets/svg';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import MomentAdapter from '@material-ui/pickers/adapter/moment';
import { LocalizationProvider } from '@material-ui/pickers';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import validateData from '@/helpers/validationHelpers/validationSchema';

import {
  StepperButton,
  StepperControl
} from '@/new-components/CustomStepper/styled';

import consentDispatcher from '../action';
import ConsentForm from './ConsentForm';
import { capitalize, isBoolean, isEmpty } from 'lodash';
import customToast from '@/new-components/CustomNotification';

const titleStyles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    border: '1px solid #f1f1f1'
  },
  title: {
    fontWeight: 600,
    fontSize: 18,
    color: '#333'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 14,
    color: theme.palette.grey[500]
  }
});

const useStyles = makeStyles(theme => ({
  mainWrapper: {
    height: 'calc(100vh - 140px)',
    overflow: 'auto'
  },
  stepsControlWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(1)
  }
}));

const CustomDrawerTitle = withStyles(titleStyles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <Box className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </Box>
  );
});

const ModalContainerForm = ({ onClose, isCreate, selectedItem }) => {
  const classes = useStyles();
  const [errors, setErrors] = useState({});
  const [formValue, setFormValue] = useState({
    htmlContent: ''
  });

  useEffect(() => {
    if (!isCreate && !isEmpty(selectedItem)) {
      setFormValue(selectedItem);
    }
  }, []);

  const handleSubmit = async () => {
    try {
      await validateData('consentSchema', formValue, data => {
        onSubmit(data);
      });
    } catch (errs) {
      setErrors(errs);
    }
  };

  const renderToast = (isCreate, data, mainType) => {
    return customToast(
      'success',
      <span>
        <strong style={{ fontWeight: 600 }}>{data}</strong> has been
        successfully {isCreate ? 'created' : 'updated'}.
      </span>,
      isCreate ? `New ${mainType} created` : ` ${capitalize(mainType)} updated`
    );
  };

  const onSubmit = data =>
    isCreate
      ? consentDispatcher.createConsent(data, () => {
          renderToast(isCreate, data.title, 'consent form');
          onClose();
        })
      : consentDispatcher.editConsent(selectedItem.id, data, () => {
          renderToast(isCreate, data.title, 'consent form');
          onClose();
        });

  const isEmptyFilterParams = useMemo(() => {
    const filterKeys = ['title', 'description', 'htmlContent'];
    return filterKeys.some(key =>
      isBoolean(formValue[key]) ? !formValue[key] : isEmpty(formValue[key])
    );
  }, [formValue]);

  return (
    <LocalizationProvider dateAdapter={MomentAdapter}>
      <Paper square elevation={0}>
        <CustomDrawerTitle onClose={onClose}>
          {isCreate ? 'Create new' : 'Edit'} consent form
        </CustomDrawerTitle>
        <main className={classes.mainWrapper}>
          <ConsentForm
            errors={errors}
            formValue={formValue}
            setFormValue={setFormValue}
          />
        </main>
        <StepperControl className={classes.stepsControlWrapper}>
          <StepperButton
            disabled={isEmptyFilterParams}
            onClick={() => handleSubmit(formValue)}
          >
            {isCreate ? 'Create' : 'Save changes'}
          </StepperButton>
        </StepperControl>
      </Paper>
    </LocalizationProvider>
  );
};

export default ModalContainerForm;

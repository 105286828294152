import React, { useEffect, useMemo, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Popover from '@material-ui/core/Popover';

import CustomSelectContainer from '@/new-components/CustomSelectOld/CustomSelectContainer';
import CustomSelect from '@/new-components/CustomSelectOld';

import { QUICK_PRICE_VALUE_RANGE } from '@/module/all-screenings/constants';
import { isBoolean } from 'lodash';
import { withStyles } from '@material-ui/core';
import { getCostRange } from '@/helpers';
import NumberFormat from 'react-number-format';
import { formatTotalPrice } from '@/module/checkin-patient-information/util';
import CustomQuickRangeGroup from '@/new-components/CustomChip/CustomGroupChips';
import { HS_SERVICE_TYPES } from '../../constants';

const CustomInputLabel = withStyles({
  root: {
    marginBottom: 8,
    fontWeight: 600
  }
})(InputLabel);

const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      height: 40,
      borderRadius: 8
    },
    '& input': {
      padding: 8
    }
  }
})(TextField);

const CustomButton = withStyles({
  label: {
    fontWeight: 700
  }
})(Button);

const GroupToolbar = ({
  filterOption,
  setFilterOption,
  packageTypes,
  setPackageTypes,

  onResetFilterOps,
  onFilterOptionClear,
  onFilterOptionChange,

  quickRange,
  setQuickRange,
  maxPriceDefault
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = event => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleChangeQuickRange = (event, rangeType) => {
    setQuickRange(rangeType);
    if (rangeType) {
      let [minCost, maxCost] = getCostRange(rangeType, maxPriceDefault);
      setFilterOption({
        ...filterOption,
        minCost,
        maxCost
      });
    } else {
      setFilterOption({
        ...filterOption,
        minCost: 0,
        maxCost: maxPriceDefault
      });
    }
  };

  const renderValue = (array, label, defaultLabel) => {
    let value = '';
    if (array.length === 0) value = defaultLabel;
    else if (array.length === 1) value = array[0];
    else value = `${label} (${array.length})`;

    return value;
  };

  const isEmptyFilterParams = useMemo(() => {
    const filterKeys = ['maxCost', 'minCost', 'packageTypes'];
    const checkString = filterKeys.filter(key => Boolean(filterOption[key]));
    return isEmpty(checkString);
  }, [filterOption]);

  const renderPrice = data => {
    const { minCost, maxCost } = data;
    if (minCost || maxCost) {
      return `${formatTotalPrice(minCost) || 0} - ${formatTotalPrice(maxCost) ||
        0}`;
    }
    return 'Select price range';
  };

  return (
    <Toolbar>
      <Box
        display="flex"
        flex={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" gridGap={12}>
          <CustomSelectContainer
            isFullWidth
            filterValue={packageTypes || []}
            data={HS_SERVICE_TYPES}
            renderValue={renderValue(
              packageTypes,
              'Services type',
              'All services type'
            )}
            onClear={onFilterOptionClear('packageTypes', [], setPackageTypes)}
            onChange={onFilterOptionChange('packageTypes', setPackageTypes)}
            labelSelectAll={'All services type'}
            useSelectAll
            checkedAll={
              HS_SERVICE_TYPES.map(item => item.value).length ===
              packageTypes.length
            }
          />
          <CustomSelect
            isFullWidth
            isShowArrow
            onClick={handleClick}
            renderValue={renderPrice(filterOption)}
          >
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
              PaperProps={{
                style: {
                  border: '1px solid #F1F1F1',
                  boxShadow: '0 4px 16px 0 rgba(0,0,0,0.1)'
                }
              }}
            >
              <Box
                p={2}
                display="flex"
                justifyContent="space-between"
                gridGap={16}
              >
                <Box width="100%">
                  <CustomInputLabel>From</CustomInputLabel>
                  <FormControl component="fieldset" fullWidth>
                    <NumberFormat
                      customInput={CustomTextField}
                      variant="outlined"
                      placeholder="$0"
                      prefix="$ "
                      thousandSeparator=","
                      onValueChange={onFilterOptionChange('minCost')}
                      allowNegative={false}
                      decimalScale={2}
                      value={filterOption.minCost || ''}
                    />
                  </FormControl>
                </Box>
                <Box width="100%">
                  <CustomInputLabel>To</CustomInputLabel>
                  <FormControl component="fieldset" fullWidth>
                    <NumberFormat
                      customInput={CustomTextField}
                      variant="outlined"
                      placeholder="$0"
                      prefix="$ "
                      thousandSeparator=","
                      onValueChange={onFilterOptionChange('maxCost')}
                      allowNegative={false}
                      decimalScale={2}
                      value={filterOption.maxCost || ''}
                    />
                  </FormControl>
                </Box>
              </Box>
              <Divider light />
              <Box display="flex" p={2}>
                <CustomQuickRangeGroup
                  value={quickRange}
                  handleChange={handleChangeQuickRange}
                  listButtons={QUICK_PRICE_VALUE_RANGE}
                />
              </Box>
            </Popover>
          </CustomSelect>
        </Box>
        <CustomButton disabled={isEmptyFilterParams} onClick={onResetFilterOps}>
          Reset
        </CustomButton>
      </Box>
    </Toolbar>
  );
};

export default GroupToolbar;

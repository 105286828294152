import moment from 'moment';
import {
  ALPHA_NUMERIC_REGEX,
  NRIC_REGEX,
  SPECIAL_CHARACTERS_REGEX
} from '@/helpers/validationHelpers/validationSchema';
import { isValidPhoneNumber } from 'libphonenumber-js';

// Must be at least 8 characters, 1 uppercase and 1 special
export function validatePassword(password) {
  var regex = /^(?=.*[A-Z])(?=.*\W)[a-zA-Z0-9\S]{8,}$/;
  return regex.test(password);
}

export function validateEmail(email) {
  let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email.trim());
}

export function validateMobile(mobile, countryCode = '') {
  return isValidPhoneNumber(mobile, countryCode);
}

export function validateDateOfBirth(date) {
  const dateParse = moment(date);
  if (!date) {
    return {
      valid: false,
      message: 'Date of birth is required'
    };
  }
  if (!dateParse.isValid()) {
    return {
      valid: false,
      message: 'Invalid date of birth'
    };
  }
  if (Date.parse(dateParse) > Date.parse(new Date())) {
    return {
      valid: false,
      message: 'Date of Birth is not in the future'
    };
  }
  return {
    valid: true,
    message: ''
  };
}

export function validateIdentity(str) {
  return ALPHA_NUMERIC_REGEX.test(str);
}

export function validateNRIC(str) {
  if (str.length != 9 || SPECIAL_CHARACTERS_REGEX.test(str)) return false;

  str = str.toUpperCase();

  let i,
    icArray = [];
  for (i = 0; i < 9; i++) {
    icArray[i] = str.charAt(i);
  }
  // Add weight - The following table shows the weight for the NRIC number in Singapore: 2,7,6,5,4,3,2
  icArray[1] = parseInt(icArray[1], 10) * 2;
  icArray[2] = parseInt(icArray[2], 10) * 7;
  icArray[3] = parseInt(icArray[3], 10) * 6;
  icArray[4] = parseInt(icArray[4], 10) * 5;
  icArray[5] = parseInt(icArray[5], 10) * 4;
  icArray[6] = parseInt(icArray[6], 10) * 3;
  icArray[7] = parseInt(icArray[7], 10) * 2;

  // Sum Product of Weights
  let weight = 0;
  for (i = 1; i < 8; i++) {
    weight += icArray[i];
  }

  // If T or G add an offset = 4 || if M add an offset = 3
  let offset =
    icArray[0] == 'T' || icArray[0] == 'G' ? 4 : icArray[0] == 'M' ? 3 : 0;

  // Divide by 11 and get remainder
  let temp = (offset + weight) % 11;
  if (icArray[0] == 'M') temp = 10 - temp;

  // Use st table for Singapore Residents and fg, m table for foreigners
  const st = ['J', 'Z', 'I', 'H', 'G', 'F', 'E', 'D', 'C', 'B', 'A'];
  const fg = ['X', 'W', 'U', 'T', 'R', 'Q', 'P', 'N', 'M', 'L', 'K'];
  const m = ['K', 'L', 'J', 'N', 'P', 'Q', 'R', 'T', 'U', 'W', 'X'];

  let theAlpha;
  if (icArray[0] == 'S' || icArray[0] == 'T') {
    theAlpha = st[temp];
  } else if (icArray[0] == 'F' || icArray[0] == 'G') {
    theAlpha = fg[temp];
  } else if (icArray[0] == 'M') {
    theAlpha = m[temp];
  }

  return icArray[8] === theAlpha;
}

export function checkRegexFullName(name) {
  const passportRegex = /^[A-Z][0-9]+$/;
  const letterWithSpecialCharRegex = /^(?=.*[a-zA-Z])(?!\d+$)(?:[a-zA-Z !@#$&()`/.+,:;']*)?$/;
  if (passportRegex.test(name)) return false;
  if (NRIC_REGEX.test(name)) return false;
  return letterWithSpecialCharRegex.test(name);
}

export function validateDate(date) {
  const dateParse = moment(date);

  if (!date) {
    return {
      valid: false,
      message: 'Date is required'
    };
  }
  if (!dateParse.isValid()) {
    return {
      valid: false,
      message: 'Invalid date'
    };
  }

  return {
    valid: true,
    message: ''
  };
}

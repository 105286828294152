import {
  FORMAT_LIST_TESTS,
  LIST_PROVIDER_LABNUMBER,
  SERVICE_TYPES,
} from './constants';
import { chain, cloneDeep, isEmpty, sum } from 'lodash';

export const formatData = (data) => {
  if (!data?.lstTests) return;
  let { lstTests } = data;

  let lstMeasurements,
    lstProcedures,
    lstRadiologies,
    lstVaccinations,
    lstReviews;

  lstMeasurements =
    lstProcedures =
    lstRadiologies =
    lstVaccinations =
    lstReviews =
      [];
  if (isEmpty(lstTests))
    return {
      ...data,
      lstTests,
      lstMeasurements,
      lstProcedures,
      lstRadiologies,
      lstVaccinations,
      lstReviews,
    };

  [
    lstMeasurements,
    lstProcedures,
    lstRadiologies,
    lstVaccinations,
    lstReviews,
    lstTests,
  ] = [
    'Measurements',
    'Procedures',
    'Radiologies',
    'Vaccinations',
    'Reviews',
    'Tests',
  ].map(
    (category) => lstTests.find((i) => i.category === category)?.items || []
  );

  return {
    ...data,
    lstTests,
    lstMeasurements,
    lstProcedures,
    lstRadiologies,
    lstVaccinations,
    lstReviews,
  };
};

export const transformDataTest = (dataTransform) => {
  const Tests = [];
  const Measurements = [];
  const Procedures = [];
  const Radiologies = [];
  const Vaccinations = [];
  const Reviews = [];
  dataTransform.forEach((it) => {
    switch (it.category) {
      case 'Tests':
        Tests.push(it);
        break;
      case 'Measurements':
        Measurements.push(it);
        break;
      case 'Procedures':
        Procedures.push(it);
        break;
      case 'Radiologies':
        Radiologies.push(it);
        break;
      case 'Vaccinations':
        Vaccinations.push(it);
        break;
      case 'Reviews':
        Reviews.push(it);
        break;
      default:
        break;
    }
  });

  const newListTest = {
    Tests,
    Measurements,
    Procedures,
    Radiologies,
    Vaccinations,
    Reviews,
  };

  return newListTest;
};

export const formatDataMeasurements = (categoryTempData, status) => {
  const dataBMI = { height: null, weight: null };
  const dataVisualAcuity = {
    rightAided: null,
    leftAided: null,
    rightUnaided: null,
    leftUnaided: null,
  };
  const dataWaistHipRatio = {
    hip: null,
    waist: null,
  };
  const dataBlood = {
    diastolic: null,
    systolic: null,
    diastolic2: null,
    systolic2: null,
  };
  const dataUrineDipstick = {
    urineDipstickBlood: null,
    urineDipstickGlucose: null,
    urineDipstickProtein: null,
    urineDipstick: null,
  };

  const dataAPR = {
    left: null,
    right: null,
  };

  const dataPVT = {
    left: null,
    right: null,
  };

  const dataTonometry = {
    left: null,
    right: null,
  };

  const others = [];

  const waistHip = 'WaistHipRatioHip';
  const waistWaist = 'WaistHipRatioWaist';

  const codeBMIHeight = 'BMIHeight';
  const codeBMIWeight = 'BMIWeight';

  const codeVA_RA = 'RightAided';
  const codeVA_LA = 'LeftAided';
  const codeVA_RUA = 'RightUnaided';
  const codeVA_LUA = 'LeftUnaided';

  const codeBPDia = 'Diastolic';
  const codeBPSys = 'Systolic';
  const codeBPDia2 = 'Diastolic2';
  const codeBPSys2 = 'Systolic2';

  const codeUDBlood = 'UDSBlood';
  const codeUDGlucose = 'UDSGlucose';
  const codeUDProtein = 'UDSProtein';

  const codeAPRLeft = 'APRLeft';
  const codeAPRRight = 'APRRight';

  const codePVTLeft = 'PVTLeft';
  const codePVTRight = 'PVTRight';

  const codeTonometryLeft = 'LeftIOP';
  const codeTonometryRight = 'RightIOP';

  const regexBMI = {
    height: codeBMIHeight.toLowerCase(),
    weight: codeBMIWeight.toLowerCase(),
  };

  const regexVisualAcuity = {
    rightAided: codeVA_RA.toLowerCase(),
    leftAided: codeVA_LA.toLowerCase(),
    rightUnaided: codeVA_RUA.toLowerCase(),
    leftUnaided: codeVA_LUA.toLowerCase(),
  };

  const regexWaistHip = {
    hip: waistHip.toLowerCase(),
    waist: waistWaist.toLowerCase(),
  };

  const regexBloodPressure = {
    diastolic: codeBPDia.toLowerCase(),
    systolic: codeBPSys.toLowerCase(),
    diastolic2: codeBPDia2.toLowerCase(),
    systolic2: codeBPSys2.toLowerCase(),
  };

  const regexUrineDipstick = {
    urineDipstickBlood: codeUDBlood.toLowerCase(),
    urineDipstickGlucose: codeUDGlucose.toLowerCase(),
    urineDipstickProtein: codeUDProtein.toLowerCase(),
  };

  const regexAPR = {
    aprLeft: codeAPRLeft.toLowerCase(),
    aprRight: codeAPRRight.toLowerCase(),
  };

  const regexPVT = {
    pvtLeft: codePVTLeft.toLowerCase(),
    pvtRight: codePVTRight.toLowerCase(),
  };

  const regexTonometry = {
    left: codeTonometryLeft.toLowerCase(),
    right: codeTonometryRight.toLowerCase(),
  };

  const newData = [];
  categoryTempData.forEach((category) => {
    const { testCode } = category;
    const codeCompare = testCode && testCode.toLowerCase();

    switch (codeCompare) {
      // BMI
      case regexBMI.height:
        if (!dataBMI.height) {
          dataBMI.height = category;
        }
        break;
      case regexBMI.weight:
        if (!dataBMI.weight) {
          dataBMI.weight = category;
        }
        break;

      // Visual Acuity
      case regexVisualAcuity.leftAided:
        if (!dataVisualAcuity.leftAided) {
          dataVisualAcuity.leftAided = category;
        }
        break;
      case regexVisualAcuity.rightAided:
        if (!dataVisualAcuity.rightAided) {
          dataVisualAcuity.rightAided = category;
        }
        break;
      case regexVisualAcuity.leftUnaided:
        if (!dataVisualAcuity.leftUnaided) {
          dataVisualAcuity.leftUnaided = category;
        }
        break;

      case regexVisualAcuity.rightUnaided:
        if (!dataVisualAcuity.rightUnaided) {
          dataVisualAcuity.rightUnaided = category;
        }
        break;

      // Waist Hip Ratio
      case regexWaistHip.hip:
        if (!dataWaistHipRatio.hip) {
          dataWaistHipRatio.hip = category;
        }
        break;
      case regexWaistHip.waist:
        if (!dataWaistHipRatio.waist) {
          dataWaistHipRatio.waist = category;
        }
        break;

      // Blood Pressure
      case regexBloodPressure.diastolic:
        if (!dataBlood.diastolic) {
          dataBlood.diastolic = category;
        }
        break;
      case regexBloodPressure.systolic:
        if (!dataBlood.systolic) {
          dataBlood.systolic = category;
        }
        break;
      case regexBloodPressure.diastolic2:
        if (!dataBlood.diastolic2) {
          dataBlood.diastolic2 = category;
        }
        break;
      case regexBloodPressure.systolic2:
        if (!dataBlood.systolic2) {
          dataBlood.systolic2 = category;
        }
        break;

      // Urine Dipstick
      case regexUrineDipstick.urineDipstickBlood:
        if (!dataUrineDipstick.urineDipstickBlood) {
          dataUrineDipstick.urineDipstickBlood = category;
        }
        break;
      case regexUrineDipstick.urineDipstickGlucose:
        if (!dataUrineDipstick.urineDipstickGlucose) {
          dataUrineDipstick.urineDipstickGlucose = category;
        }
        break;
      case regexUrineDipstick.urineDipstickProtein:
        if (!dataUrineDipstick.urineDipstickProtein) {
          dataUrineDipstick.urineDipstickProtein = category;
        }
        break;

      // Pupil Reflex
      case regexAPR.aprLeft:
        if (!dataAPR.left) {
          dataAPR.left = category;
        }
        break;
      case regexAPR.aprRight:
        if (!dataAPR.right) {
          dataAPR.right = category;
        }
        break;

      // Peripheral (side) Vision Test
      case regexPVT.pvtLeft:
        if (!dataPVT.left) {
          dataPVT.left = category;
        }
        break;
      case regexPVT.pvtRight:
        if (!dataPVT.right) {
          dataPVT.right = category;
        }
        break;

      // Tonometry
      case regexTonometry.left:
        if (!dataTonometry.left) {
          dataTonometry.left = category;
        }
        break;
      case regexTonometry.right:
        if (!dataTonometry.right) {
          dataTonometry.right = category;
        }
        break;

      default:
        others.push(category);
        break;
    }
  });

  if (dataBMI.height && dataBMI.weight) {
    const { height, weight } = dataBMI;
    const {
      stationName,
      stationId,
      status,
      projectId,
      isOptOut,

      category,
    } = height;

    const total = [height, weight];

    const newDataBMI = {
      category,
      measurementType: 'BMI',
      testName: 'BMI',
      name: 'BMI',
      isMeasurements: true,
      stationName,
      stationId,
      status,
      isOptOut: isOptOut || false,
      testCode: SERVICE_TYPES.BMI,
      projectId,
      total,
      testId: 'BMI-key',
      height,
      weight,
      price: total.reduce(
        (accumulator, currentValue) =>
          (accumulator += currentValue.testPrice || 0),
        0
      ),
    };
    newData.push(newDataBMI);
  }

  if (dataBlood.diastolic && dataBlood.systolic) {
    const { diastolic, systolic, diastolic2, systolic2 } = dataBlood;
    const {
      stationName,
      stationId,
      status,
      projectId,
      isOptOut,

      category,
    } = diastolic;

    const total = [diastolic, systolic, diastolic2, systolic2].filter(
      (it) => it
    );

    const newDataBlood = {
      category,
      measurementType: 'BloodPressure',
      testName: 'Blood Pressure',
      name: 'Blood Pressure',
      isMeasurements: true,
      stationName,
      stationId,
      status,
      isOptOut: isOptOut || false,
      testCode: SERVICE_TYPES.BLOOD_PRESSURE,
      projectId,
      total,
      testId: 'BLOOD-key',
      diastolic,
      systolic,
      diastolic2: diastolic2 || {},
      systolic2: systolic2 || {},
      price: total.reduce(
        (accumulator, currentValue) =>
          (accumulator += currentValue.testPrice || 0),
        0
      ),
    };
    newData.push(newDataBlood);
  }

  if (
    dataVisualAcuity.leftAided &&
    dataVisualAcuity.rightAided &&
    dataVisualAcuity.leftUnaided &&
    dataVisualAcuity.rightUnaided
  ) {
    const { leftAided, rightAided, leftUnaided, rightUnaided } =
      dataVisualAcuity;

    const {
      stationName,
      stationId,
      status,
      isOptOut,
      projectId,
      visualAucityType,

      category,
    } = leftAided;
    const total = [leftAided, rightAided, leftUnaided, rightUnaided];

    const newDataAcuity = {
      category,
      measurementType: 'VisualAcuity',
      testName: 'Visual Acuity',
      name: 'Visual Acuity',
      stationName,
      stationId,
      status,
      isOptOut,
      isMeasurements: true,
      testCode: SERVICE_TYPES.ACUITY,
      total,
      projectId,
      testId: 'Visual-Acuity-key',
      leftAided,
      rightAided,
      leftUnaided,
      rightUnaided,
      visualAucityType,
      price: total.reduce(
        (accumulator, currentValue) =>
          (accumulator += currentValue.testPrice || 0),
        0
      ),
    };

    newData.push(newDataAcuity);
  }

  if (dataWaistHipRatio.waist && dataWaistHipRatio.hip) {
    const { waist, hip } = dataWaistHipRatio;
    const {
      stationName,
      stationId,
      status,
      isOptOut,
      projectId,

      category,
    } = waist;

    const total = [waist, hip];

    const newDataWaistHip = {
      category,
      measurementType: 'WaistHipRatio',
      testName: 'Waist Hip Ratio',
      name: 'Waist Hip Ratio',
      stationName,
      stationId,
      status,
      isOptOut: isOptOut || false,
      isMeasurements: true,
      testCode: SERVICE_TYPES.WAIST_HIP_RADIO,
      total,
      projectId,
      testId: 'Waist-Hip-Ratio-key',
      waist,
      hip,
      price: total.reduce(
        (accumulator, currentValue) =>
          (accumulator += currentValue.testPrice || 0),
        0
      ),
    };
    newData.push(newDataWaistHip);
  }

  if (
    dataUrineDipstick.urineDipstickBlood &&
    dataUrineDipstick.urineDipstickGlucose &&
    dataUrineDipstick.urineDipstickProtein
  ) {
    const { urineDipstickBlood, urineDipstickGlucose, urineDipstickProtein } =
      dataUrineDipstick;
    const {
      stationName,
      stationId,
      status,
      projectId,
      isOptOut,

      category,
    } = urineDipstickBlood;

    const total = [
      urineDipstickBlood,
      urineDipstickGlucose,
      urineDipstickProtein,
    ].filter((it) => it);

    const newDataUrineDipstick = {
      category,
      measurementType: 'UrineDipstick',
      testName: 'Urine Dipstick',
      name: 'Urine Dipstick',
      isMeasurements: true,
      stationName,
      stationId,
      status,
      isOptOut: isOptOut || false,
      testCode: SERVICE_TYPES.URINE_DIPSTICK,
      projectId,
      total,
      testId: 'URINEDIPSTICK-key',
      urineDipstickBlood,
      urineDipstickGlucose,
      urineDipstickProtein,
      price: total.reduce(
        (accumulator, currentValue) =>
          (accumulator += currentValue.testPrice || 0),
        0
      ),
    };
    newData.push(newDataUrineDipstick);
  }

  if (dataAPR.left && dataAPR.right) {
    const { left, right } = dataAPR;
    const {
      stationName,
      stationId,
      status,
      projectId,
      isOptOut,

      category,
    } = left;

    const total = [left, right].filter((it) => it);

    const newDataAPR = {
      category,
      measurementType: 'Other',
      testName: 'Assessment of Pupil Reflex',
      name: 'Assessment of Pupil Reflex',
      isMeasurements: true,
      stationName,
      stationId,
      status,
      isOptOut: isOptOut || false,
      testCode: SERVICE_TYPES.APR,
      projectId,
      total,
      testId: 'APR-key',
      left,
      right,
      price: total.reduce(
        (accumulator, currentValue) =>
          (accumulator += currentValue.testPrice || 0),
        0
      ),
    };
    newData.push(newDataAPR);
  }

  if (dataPVT.left && dataPVT.right) {
    const { left, right } = dataPVT;
    const {
      stationName,
      stationId,
      status,
      projectId,
      isOptOut,

      category,
    } = left;

    const total = [left, right].filter((it) => it);

    const newDataPVT = {
      category,
      measurementType: 'Other',
      testName: 'Peripheral (side) Vision Test',
      name: 'Peripheral (side) Vision Test',
      isMeasurements: true,
      stationName,
      stationId,
      status,
      isOptOut: isOptOut || false,
      testCode: SERVICE_TYPES.PVT,
      projectId,
      total,
      testId: 'PVT-key',
      left,
      right,
      price: total.reduce(
        (accumulator, currentValue) =>
          (accumulator += currentValue.testPrice || 0),
        0
      ),
    };
    newData.push(newDataPVT);
  }

  if (dataTonometry.left && dataTonometry.right) {
    const { left, right } = dataTonometry;
    const {
      stationName,
      stationId,
      status,
      projectId,
      isOptOut,

      category,
    } = left;

    const total = [left, right].filter((it) => it);

    const newDataTonometry = {
      category,
      measurementType: 'Tonometry',
      testName: 'Tonometry',
      name: 'Tonometry',
      isMeasurements: true,
      stationName,
      stationId,
      status,
      isOptOut: isOptOut || false,
      testCode: SERVICE_TYPES.TONOMETRY,
      projectId,
      total,
      testId: 'Tonometry-key',
      left,
      right,
      price: total.reduce(
        (accumulator, currentValue) =>
          (accumulator += currentValue.testPrice || 0),
        0
      ),
    };
    newData.push(newDataTonometry);
  }

  newData.push(...others);

  return newData;
};

export const calculateTotalCost = (data) => {
  // Total cost without subsidy
  let totalCost = 0;

  const {
    lstTests = [],
    lstMeasurements = [],
    lstProcedures = [],
    lstRadiologies = [],
    lstReviews,
    lstVaccinations = [],
    lstProfiles = [],
    lstPackages = [],
  } = data;

  const totalCostPackages = sum(lstPackages?.map((item) => item.price || 0));
  const totalCostProfiles = sum(lstProfiles?.map((item) => item.price));
  const totalCostTests = sum(lstTests?.map((item) => item.price));
  const totalCostMeasurements = sum(
    lstMeasurements?.map((item) => item.price || 0)
  );
  const totalCostProcedures = sum(
    lstProcedures?.map((item) => item.price || 0)
  );
  const totalCostRadiologies = sum(
    lstRadiologies?.map((item) => item.price || 0)
  );
  const totalCostReviews = sum(lstReviews?.map((item) => item.price || 0));
  const totalCostVaccinations = sum(
    lstVaccinations?.map((item) => item.price || 0)
  );

  totalCost =
    totalCostPackages +
    totalCostProfiles +
    totalCostTests +
    totalCostMeasurements +
    totalCostReviews +
    totalCostProcedures +
    totalCostRadiologies +
    totalCostVaccinations;

  return totalCost;
};

export const formatTestServices = (lstTests = []) => {
  let decompressList = [];

  lstTests.forEach((item) => {
    if (item.total) decompressList = decompressList.concat(item.total);
    else decompressList.push(item);
  });

  return decompressList.map((item) => item.id);
};

export const formatExpressTotalTestServices = (lstTests = []) => {
  let decompressList = [];

  lstTests.forEach((item) => {
    if (item.total) decompressList = decompressList.concat(item.total);
    else decompressList.push(item);
  });

  return decompressList;
};

export const returnOptionalPackageTests = (data) => {
  const {
    lstProfiles = [],
    lstProcedures = [],
    lstRadiologies = [],
    ignoredProfiles = [],
    ignoredTests = [],
  } = data;
  let lstOptionalTests = [];
  const profilesOptional = lstProfiles
    ?.filter((item) => !item.isRequired)
    ?.filter((item) =>
      ignoredProfiles?.every((test) => {
        return item.id !== test.profileId;
      })
    );

  const proceduresOptional = lstProcedures
    ?.filter((item) => !item.isRequired)
    ?.filter((item) =>
      ignoredTests?.every((test) => {
        return item.id !== test.testId;
      })
    );
  const radiologiesOptional = lstRadiologies
    ?.filter((item) => !item.isRequired)
    ?.filter((item) =>
      ignoredTests?.every((test) => {
        return item.id !== test.testId;
      })
    );

  lstOptionalTests = [
    ...profilesOptional,
    ...proceduresOptional,
    ...radiologiesOptional,
  ];
  return lstOptionalTests;
};

export const generateLifestyle = (data) => {
  if (isEmpty(data) || isEmpty(data[0]?.questions)) return [];
  const newData = chain(data[0]?.questions)
    .filter((perm) => perm)
    .groupBy('category')
    .map((value, key) => ({
      name: key,
      questions: value,
    }))
    .value();
  return newData;
};

export const formatCustomCategories = (
  lstProjectCustomFields,
  fieldArrValue
) => {
  return (lstProjectCustomFields = lstProjectCustomFields.map((item) => {
    return fieldArrValue.includes(item.customFieldType)
      ? {
          ...item,
          value: isJsonString(item.value) ? JSON.parse(item.value) : item.value,
        }
      : item;
  }));
};

export const isJsonString = (item) => {
  item = typeof item !== 'string' ? JSON.stringify(item) : item;

  try {
    item = JSON.parse(item);
  } catch (e) {
    return false;
  }

  if (typeof item === 'object' && item !== null) {
    return true;
  }

  return false;
};

export const formatMeasurementInSideListTest = (lstTests) => {
  if (isEmpty(lstTests)) return [];
  let newLstTests = [];
  let newDataMeasurement = lstTests.filter(
    (test) => test.category === 'Measurements'
  );

  newDataMeasurement = newDataMeasurement.map((test) => {
    const testsFormat = formatDataMeasurements(test?.items);
    return {
      category: test.category,
      items: testsFormat,
    };
  });

  newLstTests = lstTests
    .filter((test) => test.category !== 'Measurements')
    .concat(newDataMeasurement);

  return newLstTests;
};

export const generateScreeningFieldsListData = (data) => {
  if (isEmpty(data)) return [];
  const newData = data.map((it) => {
    if (['DropdownList', 'Rating'].includes(it.customFieldType)) {
      return { ...it, value: String(it.value) };
    }
    if (it.customFieldType === 'MultiSelect') {
      return { ...it, value: JSON.stringify(it.value) };
    }
    return it;
  });
  return newData;
};

export const generateParamsTestIdsPackageIdsData = (
  paxData,
  requiredOnly = false
) => {
  const { lstPackages = [], lstTests = [] } = paxData || {};
  let newListTest = [];
  lstTests.forEach((test) => (newListTest = newListTest.concat(test.items)));

  if (requiredOnly) {
    newListTest = newListTest.filter((it) => it.isRequired);
  }

  const params = {
    packageId: lstPackages[0]?.id || '',
    testIds: newListTest?.map((it) => it?.id) || [],
  };

  return params;
};

export const refactorListDataCategory = (data) => {
  let newLstTests = [];
  for (const [key, value] of Object.entries(data)) {
    if (FORMAT_LIST_TESTS[key] && !isEmpty(value))
      newLstTests.push({
        category: FORMAT_LIST_TESTS[key],
        items: formatExpressTotalTestServices(value),
      });
  }

  return newLstTests;
};

export const generateLstTests = (data) => {
  const lstTests = [];

  for (const [key, value] of Object.entries(data)) {
    if (FORMAT_LIST_TESTS[key] && !isEmpty(value)) {
      lstTests.push({
        category: FORMAT_LIST_TESTS[key],
        items: value,
      });
    }
  }

  return lstTests;
};

export const generateTotalLabNumbers = (totalLabNumbers) => {
  if (isEmpty(totalLabNumbers)) return [];
  const newTotalLabNumber = totalLabNumbers.map((it) =>
    generateCurrentLabNumber(it.number, it.provider)
  );

  return newTotalLabNumber;
};

export const generateCurrentLabNumber = (currentLabNumber, currentProvider) => {
  if (isEmpty(currentLabNumber)) return '';
  const newTotalLabNumber =
    currentProvider === LIST_PROVIDER_LABNUMBER.Eurofins
      ? currentLabNumber
      : currentLabNumber.slice(3);
  return newTotalLabNumber;
};

export const refactorPackageData = (data) => {
  const {
    isFollowUp = false,
    lstProfiles,
    lstProcedures,
    lstRadiologies,
    lstTests: lstPackageTests,
    totalMandatoryProfile,
    totalMandatoryProcedure,
    totalMandatoryRadiology,
    doneFollowUpTests = [],
    ignoredTests = [],
    ignoredProfiles = [],
    restrictedTestIds = [],
  } = data;

  /* 
    SUMMARY: 
    1. GET LIST PROCEDURES & RADIOLOGIES
      - Get list procedures and radiologies based on list package

    2. FILTER LIST OF PROCEDURES & RADIOLOGIES BASED ON RESTRICED TEST
      - Filter to remove tests restricted by current location

    3. GET UNMANDATORY LIST OF PROCEDURES & RADIOLOGIES
     - Get unmandatory list procedures and radiologies based on LIST PROCEDURES & RADIOLOGIES

    4. RE-CALCULATE TOTAL MANDATORY
      4.1.Follow Up appointment (Child of Main appointment) (Procedure, Radiology) 
        - Get new list PROCEDURES & RADIOLOGIES based on DONE LIST
        - Remove optional tests are done in Main appointment
        - Format data with isDone (test is done) & isChecked (test is selected by Main appointment)

      4.2.Main appointment 

    5. GET OPTIONAL TEST ARE SELECTED 
      - Get optional test are selected to render in Invoice list
      - Handle id to find the locations are restricting them (call another api)
 */

  /* 1. GET LIST PROCEDURES & RADIOLOGIES */
  // Get list procedures and radiologies based on list package
  let newLstProcedures = cloneDeep(lstProcedures);
  let newLstRadiologies = cloneDeep(lstRadiologies);

  // lstPackageTests.forEach(item => {
  //   if (item.category === 'Procedures') lstProcedures = item.items;
  //   if (item.category === 'Radiologies') lstRadiologies = item.items;
  // });

  /* 2. FILTER LIST OF PROCEDURES & RADIOLOGIES BASED ON RESTRICED TEST */
  // Filter to remove tests restricted by current location
  // if (!isEmpty(restrictedTestIds)) {
  //   newLstProcedures = newLstProcedures?.filter(
  //     it => !restrictedTestIds?.includes(it.id)
  //   );
  //   newLstRadiologies = lstRadiologies?.filter(
  //     it => !restrictedTestIds?.includes(it.id)
  //   );
  // }

  /* 3. GET UNMANDATORY LIST OF PROCEDURES & RADIOLOGIES */
  // Get unmandatory list procedures and radiologies based on LIST PROCEDURES & RADIOLOGIES
  const unMandatoryProcedures = cloneDeep(newLstProcedures)?.filter(
    (it) => !it.isRequired
  );
  const unMandatoryRadiologies = cloneDeep(newLstRadiologies)?.filter(
    (it) => !it.isRequired
  );

  let newTotalMandatoryProcedure = 0;
  let newTotalMandatoryRadiology = 0;
  let holdDoneFollowUpTestsProcedures = [];
  let holdDoneFollowUpTestsRadiologies = [];

  /* 4. RE-CALCULATE TOTAL MANDATORY */
  /* 4.1.Follow Up appointment (Child of Main appointment) (Procedure, Radiology) */
  if (isFollowUp) {
    // Get new list PROCEDURES & RADIOLOGIES based on DONE LIST
    // Remove optional tests are done in Main appointment
    // Format data with isDone (test is done) & isChecked (test is selected by Main appointment)

    // With Done list
    if (!isEmpty(doneFollowUpTests)) {
      // PROCEDURES
      holdDoneFollowUpTestsProcedures = unMandatoryProcedures.filter((it) =>
        doneFollowUpTests.find((item) => item.testId === it.id)
      );

      newLstProcedures = unMandatoryProcedures.map((item) => {
        const itemDone = doneFollowUpTests?.find(
          (it) => it.testId === item.id && !it.isAddon
        );

        let selectedProcedures = {};
        if (isEmpty(ignoredTests)) {
          selectedProcedures = ignoredTests?.some((test) => {
            return item.id === test.testId;
          });
        } else {
          selectedProcedures = ignoredTests?.every((test) => {
            return item.id !== test.testId;
          });
        }

        if (itemDone) {
          return { ...item, isDone: true, isChecked: false };
        } else if (selectedProcedures)
          return { ...item, isDone: false, isChecked: true };

        return { ...item, isDone: false, isChecked: false };
      });
      newLstProcedures = newLstProcedures.filter(
        (test) =>
          holdDoneFollowUpTestsProcedures.findIndex(
            (item) => item.id === test.id
          ) === -1
      );

      // RADIOLOGIES
      holdDoneFollowUpTestsRadiologies = unMandatoryRadiologies.filter((it) =>
        doneFollowUpTests.find((item) => item.testId === it.id)
      );

      newLstRadiologies = unMandatoryRadiologies.map((item) => {
        const itemDone = doneFollowUpTests?.find(
          (it) => it.testId === item.id && !it.isAddon
        );

        let selectedRadiologies = {};
        if (isEmpty(ignoredTests)) {
          selectedRadiologies = ignoredTests?.some((test) => {
            return item.id === test.testId;
          });
        } else {
          selectedRadiologies = ignoredTests?.every((test) => {
            return item.id !== test.testId;
          });
        }

        if (itemDone) {
          return { ...item, isDone: true, isChecked: false };
        } else if (selectedRadiologies)
          return { ...item, isDone: false, isChecked: true };

        return { ...item, isDone: false, isChecked: false };
      });

      newLstRadiologies = newLstRadiologies.filter(
        (test) =>
          holdDoneFollowUpTestsRadiologies.findIndex(
            (item) => item.id === test.id
          ) === -1
      );
    } else {
      newLstProcedures = unMandatoryProcedures.map((item) => {
        const itemChecked = newLstProcedures.find((it) => it.id === item.id);

        if (itemChecked) {
          return { ...item, isDone: false, isChecked: true };
        }
        return { ...item, isDone: false, isChecked: false };
      });

      newLstRadiologies = unMandatoryRadiologies.map((item) => {
        const itemChecked = newLstRadiologies.find((it) => it.id === item.id);

        if (itemChecked) {
          return { ...item, isDone: false, isChecked: true };
        }
        return { ...item, isDone: false, isChecked: false };
      });
    }

    newTotalMandatoryProcedure =
      totalMandatoryProcedure - holdDoneFollowUpTestsProcedures.length;

    newTotalMandatoryRadiology =
      totalMandatoryRadiology - holdDoneFollowUpTestsRadiologies.length;
  } else {
    /* 4.2.Main appointment */
    newTotalMandatoryProcedure = totalMandatoryProcedure;
    newTotalMandatoryRadiology = totalMandatoryRadiology;
  }

  /* 5. GET OPTIONAL TEST ARE SELECTED */
  // Get optional test are selected to render in Invoice list
  const lstOptionalTests = returnOptionalPackageTests({
    lstProcedures: newLstProcedures,
    lstRadiologies: newLstRadiologies,
    lstProfiles,
    ignoredTests,
    ignoredProfiles,
  });
  // Handle id to find the locations are restricting them (call another api)
  const selectedOptionalIds = lstOptionalTests.map((it) => it.id);

  return {
    lstPackageTests,
    selectedOptionalIds,
    lstProfiles,
    lstProcedures: newLstProcedures,
    lstRadiologies: newLstRadiologies,
    totalMandatoryProfile,
    totalMandatoryProcedure: newTotalMandatoryProcedure,
    totalMandatoryRadiology: newTotalMandatoryRadiology,
    holdDoneFollowUpTestsProcedures,
    holdDoneFollowUpTestsRadiologies,
    lstOptionalTests,
  };
};

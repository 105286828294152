import React from 'react';
import { Select, MenuItem } from '@material-ui/core';
import { countries } from '../../countries';
import { Divider } from 'antd';
import './styles.scss';

const initCountry = 'SG';

const PhoneInput = ({ country = initCountry, onChange, disabled }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Select
        disabled={disabled}
        disableUnderline
        value={country}
        onChange={onChange}
        renderValue={value => (
          <>
            <img
              src={countries[value]?.flag}
              alt={countries[value]?.name?.common || ''}
              style={{ maxWidth: 20, marginRight: 5 }}
            />
            {`+${countries[value]?.callingCode[0] || ''}`}
          </>
        )}
      >
        {Object.keys(countries).map(item => (
          <MenuItem key={item} value={item}>
            <img
              src={countries[item].flag}
              alt={countries[item].name.common}
              style={{ maxWidth: 20, marginRight: 5 }}
            />
            {countries[item].name.common}
          </MenuItem>
        ))}
      </Select>
      <Divider type="vertical" className="custom-divider" />
    </div>
  );
};

export default PhoneInput;

import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
  wrappedItem: {
    width: '100%',
    minHeight: 120,
    maxHeight: 220,
    overflowY: 'auto',
    backgroundColor: '#F6F7FA',
    padding: '10px 20px',
  },

  rowItem: {
    margin: 0,
    paddingBottom: 10,
    overflowY: 'auto',
  },
});

export const WrappedItem = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.wrappedItem}>{children}</div>;
};
export const RowItem = ({ children }) => {
  const classes = useStyles();
  return <p className={classes.rowItem}>{children}</p>;
};

import { createDownloadableLink } from '@/helpers';
import { synthesize } from 'redux-dispatcher';

const mapDispatchToAC = {
  getStationsSuccess: data => ({ data }),
  getData: (search, paging, sortOption = {}, filterOption = {}) => async ({
    Api,
    getState
  }) => {
    let pagingData = paging || getState().station.paging;
    let { result, status } = await Api.get(`Stations`, {
      pageIndex: pagingData.pageIndex,
      pageSize: pagingData.pageSize,
      search,
      ...sortOption,
      ...filterOption
    });
    status === 200 && stationDispatcher.getStationsSuccess(result);
  },
  getAllStation: () => async ({ Api }) => {
    let { result, status } = await Api.get(`Stations`);
    status === 200 && stationDispatcher.getStationsSuccess(result);
  },
  getStationById: (id, callback) => async ({ Api }) => {
    const { result, status } = await Api.get(`Stations/${id}`);
    if (status === 200) {
      callback && callback(result);
    }
  },
  setState: (state, value) => ({ state, value }),
  createStation: (data, callbackSuccess) => async ({ Api, customToast }) => {
    const { status } = await Api.post('Stations', data);
    stationDispatcher.setState('loading', false);
    if (status === 200) {
      callbackSuccess && callbackSuccess();
    }
  },
  editStation: (id, data, callbackSuccess) => async ({ Api, customToast }) => {
    const { result, status } = await Api.put(`Stations/${id}`, data);
    stationDispatcher.setState('loading', false);
    if (status === 200) {
      callbackSuccess && callbackSuccess(result);
    }
  },
  deleteStation: (id, callbackSuccess) => async ({ Api, customToast }) => {
    const { status } = await Api.delete(`Stations/${id}`);
    if (status === 200) {
      customToast('success', 'Deleted successful');
      callbackSuccess && callbackSuccess();
    }
  },
  getExportExcel: (search, paging) => async ({ Api, getState }) => {
    let pagingData = paging || getState().station.paging;
    let { result, status } = await Api.get(`Stations/csv-report`, {
      search,
      pageIndex: pagingData.pageIndex,
      pageSize: pagingData.pageSize
    });
    status === 200 && createDownloadableLink(result, 'stations-report');
  }
};

const stationDispatcher = synthesize('station', mapDispatchToAC);
export default stationDispatcher;

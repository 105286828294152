import { createDownloadableLink } from '@/helpers';
import { synthesize } from 'redux-dispatcher';

const mapDispatchToAC = {
  getDataSuccess: data => ({ data }),
  getData: (search, paging, sortOption = {}, filterOption = {}) => async ({
    Api,
    getState
  }) => {
    let pagingData = paging || getState().telecounselAllCounsels.paging;
    let { result, status } = await Api.post(`Consultations`, {
      pageIndex: pagingData.pageIndex,
      pageSize: pagingData.pageSize,
      search,
      ...filterOption,
      sort: {
        ...sortOption
      }
    });
    status === 200 && telecounselAllCounselsDispatcher.getDataSuccess(result);
  },
  getCounselDetail: (id, isCounsellor, cb) => async ({ Api }) => {
    let api = isCounsellor ? 'Doctors/meeting-histories/' : 'Consultations/';
    let { result, status } = await Api.get(`${api}${id}`);
    if (status === 200) {
      cb && cb(result);
    }
  },
  processSummary: (id, onSucess) => async ({ Api }) => {
    let { status } = await Api.put(`TeleCounsellings/${id}/process-summary`);
    if (status === 200) {
      onSucess && onSucess();
    }
  },
  getExportExcel: (search, filterOption = {}) => async ({ Api }) => {
    let { result, status } = await Api.post(`Consultations/csv-report`, {
      search,
      ...filterOption
    });
    status === 200 && createDownloadableLink(result);
  },
  editInvoice: (id, data, onSucess, onFail) => async ({ Api, customToast }) => {
    const { status } = await Api.put(`TeleCounsellings/${id}/invoice`, data);
    if (status === 200) {
      onSucess && onSucess();
      customToast('success', 'Edit invoice successfully');
    } else {
      onFail && onFail();
    }
  }
};

const telecounselAllCounselsDispatcher = synthesize(
  'telecounselAllCounsels',
  mapDispatchToAC
);
export default telecounselAllCounselsDispatcher;

import allScreeningsDispatcher from '../../action';
import { FORMAT_LIST_TESTS } from '../../constants';
import {
  calculateTotalCost,
  formatData,
  formatExpressTotalTestServices,
  generateParamsTestIdsPackageIdsData,
  returnOptionalPackageTests,
} from '../../utils';
import ViewSelectPackage from '../CreateAppointment/ViewSelectPackage';
import SelecetServiceStep from './SelecetServiceStep';
import SelectPlaceAndTimeStep from './SelectPlaceAndTimeStep';
import { urlLabel } from '@/enum/PermissionEnum';
import checkinPatientDispatcher from '@/module/checkin-patient-information/action';
import companyDispatcher from '@/module/company/action';
import { packageDispatcher } from '@/module/package-management';
import locationDispatcher from '@/module/setup/action';
import { refactorListData } from '@/module/setup/constants';
import customToast from '@/new-components/CustomNotification';
import CustomPaperContainer from '@/new-components/CustomPaperContainer';
import CustomHeader from '@/new-components/CustomPaperContainer/CustomHeader';
import CustomStepper from '@/new-components/CustomStepper';
import { StepperButton } from '@/new-components/CustomStepper/styled';
import { Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { get, cloneDeep, isEmpty, result } from 'lodash';
import moment from 'moment';
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

const RescheduleAppointment = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [patientInfo, setPatientInfo] = useState({});
  const [projectInfo, setProjectInfo] = useState({});
  const [packageDetail, setPackageDetail] = useState({});
  const [dataInfo, setDataInfo] = useState({});

  const [loading, setLoading] = useState(false);

  const [timeSlot, setTimeSlot] = useState([]);
  const [timeSlotChecked, setTimeSlotChecked] = useState();
  const [closureDate, setClosureDate] = useState([]);
  const [dateChecked, setDateChecked] = useState();
  const [loadingSearchTimeSlot, setLoadingSearchTimeSlot] = useState(false);
  const [showErrorInfo, setShowErrorInfo] = useState(false);

  const [currentLocation, setCurrentLocation] = useState({});
  const [isValidHealthScreening, setIsValidHealthScreening] = useState(false);
  const [isRestrictPackages, setIsRestrictPackages] = useState(false);
  const [restrictedPackageIds, setRestrictedPackageIds] = useState([]);
  const [restrictedTestIds, setRestrictedTestIds] = useState([]);
  const [projectCustomFields, setProjectCustomFields] = useState([]);
  const [lstOptionalTests, setLstOptionalTests] = useState([]);
  const [currentPackage, setCurrentPackage] = useState({});
  const [dataPackageExisted, setdataPackageExisted] = useState({});
  const [locationFollowUp, setLocationFollowUp] = useState([]);
  const [lstHealthScreenings, setLstHealthScreenings] = useState([]);

  const [errors, setErrors] = useState({});

  const [totalCost, setTotalCost] = useState(0);
  const [totalAmountDefault, setTotalAmountDefault] = useState(0);

  const [formValue, setFormValue] = useState({
    timeSlotId: '',
    date: '',
    healthScreeningId: '',
    isSuppression: false,
  });

  const location = useLocation();
  const history = useHistory();
  const apptData = get(location, 'state', {});
  const isFollowUp = get(apptData, 'isFollowUpAppointment', undefined);

  const _tempHistory = useRef();
  const ref = useRef(false);

  useEffect(() => {
    _tempHistory.current = cloneDeep(history);
  }, []);

  const checkIsValidDate = (valueStartDate) => {
    const startDateUnix = moment(valueStartDate).startOf('date').unix();

    const dateCompare = isFollowUp
      ? moment().startOf('date').add(1, 'day').unix()
      : moment().startOf('date').unix();

    return startDateUnix < dateCompare;
  };

  const checkIsValidHealthScreening = (endTime) => {
    const endTimeUnix = moment(endTime).endOf('date').unix();
    const timeCompare = moment().unix();

    return endTimeUnix >= timeCompare;
  };

  const onSubmit = () => {
    if (loading) return;
    if (!timeSlotChecked) {
      return customToast('error', 'Please select time slots');
    }

    if (isRestrictPackages) {
      return customToast(
        'error',
        'The current package has been restricted to this location. Please select another location! '
      );
    }
    setLoading(true);

    let lstTests = refactorListData(formValue);
    lstTests = formatExpressTotalTestServices(lstTests);

    const params = {
      timeSlotId: formValue.timeSlotId,
      date: dateChecked,
      healthScreeningId: formValue.healthScreeningId,
      lstTests,
      lstProfiles: formValue.lstProfiles,
      lstPackages: formValue.lstPackages,
      ignoredTests: formValue.ignoredTests,
      ignoredProfiles: formValue.ignoredProfiles,
    };

    allScreeningsDispatcher.rescheduleAppointment(
      apptData.id,
      params,
      (isSuccess) => {
        setLoading(false);

        if (isSuccess) {
          customToast(
            'success',
            <span>
              Appointment for patient <b>{apptData.fullName}</b> has been
              successfully rescheduled.
            </span>,
            'Appointment rescheduled'
          );
          history.push(`/${urlLabel.allScreenings}`);
        }
      }
    );
  };

  useEffect(() => {
    if (isEmpty(apptData)) return;
    const {
      startDate = '',
      appointmentTypes = [],
      nric = '',
      fullName = '',
      shortId = '',
      endDate = '',
      healthScreeningStartDate,
      healthScreeningEndDate,
      isPaxscreenSuppression,
      isProjectSuppression,
      projectId,
      healthScreeningId,
      locationId,
    } = apptData;

    let newFormValue = {
      appointmentTypes,
      nric,
      fullName,
      shortId,
      endDate,
      healthScreeningStartDate,
      healthScreeningEndDate,
      isPaxscreenSuppression,
      isProjectSuppression,
      healthScreeningId,
      locationId,
    };

    let startDateValid = moment(startDate);
    let isStartDateValid = true;
    let newLstHealthScreenings = [];

    if (checkIsValidDate(startDateValid)) {
      isStartDateValid = false;
      startDateValid = isFollowUp ? moment().add(1, 'day') : moment();

      // Check if healthScreeningStartDate is after startDateValid
      if (moment(healthScreeningStartDate).isAfter(moment(startDateValid))) {
        startDateValid = moment(healthScreeningStartDate);
      }
    }
    ref.current = isPaxscreenSuppression;
    newFormValue.startDate = startDateValid;

    onGetDataTimeSlot(startDateValid, isStartDateValid, locationId);

    companyDispatcher.getProjectByID(projectId, (result) => {
      const newListHealthScreeningsActive = result.lstHealthScreenings.filter(
        (item) => checkIsValidHealthScreening(item.endDate)
      );

      let currentLocation = newListHealthScreeningsActive.find(
        (item) => item.id === healthScreeningId
      );

      // set healthScreeningEndDate is an endDate of the project if the appointment is a follow-up
      if (isFollowUp) newFormValue.healthScreeningEndDate = result.endDate;

      if (!isFollowUp && isEmpty(newListHealthScreeningsActive)) {
        setErrors({
          location:
            'All health screenings are expired. Please update in your project!',
        });
      } else {
        setErrors({});
        if (!currentLocation && !isFollowUp) {
          currentLocation = newListHealthScreeningsActive[0];
          // Update new form value
          newFormValue.healthScreeningStartDate = currentLocation.startDate;
          newFormValue.healthScreeningEndDate = currentLocation.endDate;
          newFormValue.healthScreeningId = currentLocation.id;
          // Get data time slot with new locationId
          onGetDataTimeSlot(
            startDateValid,
            isStartDateValid,
            currentLocation.locationId
          );
        }
      }
      // Check healthScreeningEndDate is expired only with parent appointment
      setIsValidHealthScreening(
        !isFollowUp && isEmpty(newListHealthScreeningsActive)
      );
      if (!isFollowUp) setCurrentLocation(currentLocation);
      setProjectInfo({
        id: result.id,
        code: result.code,
        pmCode: currentLocation?.pmCode,
        companyName: result.companyName,
        staffTypeLabel: result.staffTypeLabel,
        startDate: result.startDate,
        endDate: result.endDate,
        lstHealthScreenings: newListHealthScreeningsActive,
        currLocationId: apptData.locationId,
        billingType: result?.billingType,
      });
      newLstHealthScreenings = newLstHealthScreenings.concat(
        newListHealthScreeningsActive
      );
    });

    checkinPatientDispatcher.getData(apptData.id, (paxData) => {
      const {
        paxInfo,
        lstTests,
        lstProfiles,
        lstPackages,
        ignoredTests,
        ignoredProfiles,
        lstScreeningFields,
        testsByCatetory,
        doneFollowUpTests,
      } = paxData;

      // Have package
      if (!isEmpty(lstPackages)) {
        const { id, name, price } = lstPackages[0];
        let lstOptionalTests = [];
        packageDispatcher.getPackageById(id, (result) => {
          const {
            lstProfiles,
            lstTests: lstPackageTests,
            totalMandatoryProcedure,
            totalMandatoryRadiology,
          } = result;

          // Get list of Profile, Procedure, Radiology
          // Filter unmandatory test
          // Filter Optional test based on ingnored Test
          let lstProcedures = [];
          let lstRadiologies = [];
          let newListTest = [];
          let newTotalMandatoryProcedure = 0;
          let newTotalMandatoryRadiology = 0;

          if (isFollowUp && !isEmpty(doneFollowUpTests)) {
            newListTest = lstPackageTests.map((item) => {
              const holdDoneFollowUpTests = doneFollowUpTests.filter(
                (it) => it.category === item.category && !it.isAddon
              );

              return {
                ...item,
                items: item.items.filter(
                  (test) =>
                    holdDoneFollowUpTests.findIndex(
                      (item) => item.testId === test.id
                    ) === -1
                ),
              };
            });
          } else {
            newListTest = lstPackageTests;
          }

          let newLstTestProcedures = [];
          let newLstTestRadiologies = [];
          let holdDoneFollowUpTestsProcedures = [];
          let holdDoneFollowUpTestsRadiologies = [];

          lstPackageTests.forEach((item) => {
            if (item.category === 'Procedures') lstProcedures = item.items;
            if (item.category === 'Radiologies') lstRadiologies = item.items;
          });
          const unMandatoryProcedures = [...lstProcedures]?.filter(
            (it) => !it.isRequired
          );
          const unMandatoryRadiologies = [...lstRadiologies]?.filter(
            (it) => !it.isRequired
          );
          if (isFollowUp) {
            if (!isEmpty(doneFollowUpTests)) {
              // Procedure
              holdDoneFollowUpTestsProcedures = unMandatoryProcedures.filter(
                (it) => doneFollowUpTests.find((item) => item.testId === it.id)
              );

              newLstTestProcedures = unMandatoryProcedures.map((item) => {
                const itemDone = doneFollowUpTests?.find(
                  (it) => it.testId === item.id && !it.isAddon
                );

                let selectedProcedures = {};
                if (isEmpty(ignoredTests)) {
                  selectedProcedures = ignoredTests?.some((test) => {
                    return item.id === test.testId;
                  });
                } else {
                  selectedProcedures = ignoredTests?.every((test) => {
                    return item.id !== test.testId;
                  });
                }

                if (itemDone) {
                  return { ...item, isDone: true, isChecked: false };
                } else if (selectedProcedures)
                  return { ...item, isDone: false, isChecked: true };

                return { ...item, isDone: false, isChecked: false };
              });
              newLstTestProcedures = newLstTestProcedures.filter(
                (test) =>
                  holdDoneFollowUpTestsProcedures.findIndex(
                    (item) => item.id === test.id
                  ) === -1
              );

              // Radiologies
              holdDoneFollowUpTestsRadiologies = unMandatoryRadiologies.filter(
                (it) => doneFollowUpTests.find((item) => item.testId === it.id)
              );

              newLstTestRadiologies = unMandatoryRadiologies.map((item) => {
                const itemDone = doneFollowUpTests?.find(
                  (it) => it.testId === item.id && !it.isAddon
                );

                let selectedRadiologies = {};
                if (isEmpty(ignoredTests)) {
                  selectedRadiologies = ignoredTests?.some((test) => {
                    return item.id === test.testId;
                  });
                } else {
                  selectedRadiologies = ignoredTests?.every((test) => {
                    return item.id !== test.testId;
                  });
                }

                if (itemDone) {
                  return { ...item, isDone: true, isChecked: false };
                } else if (selectedRadiologies)
                  return { ...item, isDone: false, isChecked: true };

                return { ...item, isDone: false, isChecked: false };
              });

              newLstTestRadiologies = newLstTestRadiologies.filter(
                (test) =>
                  holdDoneFollowUpTestsRadiologies.findIndex(
                    (item) => item.id === test.id
                  ) === -1
              );
            } else {
              newLstTestProcedures = unMandatoryProcedures.map((item) => {
                const itemChecked = lstProcedures.find(
                  (it) => it.id === item.id
                );

                if (itemChecked) {
                  return { ...item, isDone: false, isChecked: true };
                }
                return { ...item, isDone: false, isChecked: false };
              });

              newLstTestRadiologies = unMandatoryRadiologies.map((item) => {
                const itemChecked = lstRadiologies.find(
                  (it) => it.id === item.id
                );

                if (itemChecked) {
                  return { ...item, isDone: false, isChecked: true };
                }
                return { ...item, isDone: false, isChecked: false };
              });
            }

            newTotalMandatoryProcedure =
              totalMandatoryProcedure - holdDoneFollowUpTestsProcedures.length;

            newTotalMandatoryRadiology =
              totalMandatoryRadiology - holdDoneFollowUpTestsRadiologies.length;
          } else {
            newTotalMandatoryProcedure = totalMandatoryProcedure;
            newTotalMandatoryRadiology = totalMandatoryRadiology;
          }

          // After apply doneTest

          if (isFollowUp) {
            lstOptionalTests = returnOptionalPackageTests({
              lstProcedures: newLstTestProcedures,
              lstRadiologies: newLstTestRadiologies,
              ignoredTests,
              ignoredProfiles,
            });
          } else
            lstOptionalTests = returnOptionalPackageTests({
              lstProcedures,
              lstRadiologies,
              lstProfiles,
              ignoredTests,
              ignoredProfiles,
            });
          setLstOptionalTests(lstOptionalTests);
          setPackageDetail({
            ...result,
            lstTests: newListTest,
            lstProcedures: newLstTestProcedures,
            lstRadiologies: newLstTestRadiologies,
            totalMandatoryProcedure: newTotalMandatoryProcedure,
            totalMandatoryRadiology: newTotalMandatoryRadiology,
            name,
            price,
            holdDoneFollowUpTestsRadiologies,
            holdDoneFollowUpTestsProcedures,
          });
          setdataPackageExisted({
            ...result,
            ...lstPackages[0],
            ignoredTests,
            ignoredProfiles,
            lstProfiles,
            lstProcedures,
            lstRadiologies,
            price,
          });
          setCurrentPackage({
            ...result,
            ...lstPackages[0],
            ignoredTests,
            ignoredProfiles,
            lstProfiles,
            lstProcedures,
            lstRadiologies,
            price,
          });
        });
      }

      // Calculate total cost and total amount
      const newLstTests = formatData({ lstTests });
      const totalCost = calculateTotalCost({
        ...newLstTests,
        lstPackages,
        lstProfiles,
      });
      getListHealthScreenings(
        { lstPackages, ...newLstTests },
        newLstHealthScreenings
      );

      if (isFollowUp) {
        const listAddOn = lstTests.filter((it) =>
          ['Radiologies', 'Procedures', 'Vaccinations'].includes(it.category)
        );

        setDataInfo({
          ...apptData,
          lstTests: listAddOn,
          lstProfiles,
          lstPackages,
          ignoredTests,
          ignoredProfiles,
          doneFollowUpTests,
        });
      } else {
        setDataInfo(paxData);
      }
      setTotalCost(totalCost);
      setTotalAmountDefault(apptData?.totalCost);

      setPatientInfo({
        fullName: paxInfo.fullName,
        identityNumber: paxInfo.identityNumber,
        dateOfBirth: paxInfo.dateOfBirth,
        nationality: paxInfo.nationality,
        ethnicGroup: paxInfo.ethnicGroup,
        mobile: paxInfo.mobile,
        email: paxInfo.email,
        countryCode: paxInfo.countryCode,
      });

      setFormValue({
        ...newLstTests,
        timeSlotId: '',
        date: startDate,
        healthScreeningId,
        lstPackages,
        lstProfiles,
        ignoredTests,
        ignoredProfiles,
        lstScreeningFields,
        startDate: startDateValid,
        locationId,
      });
    });
  }, [apptData]);

  useEffect(() => {
    if (isEmpty(currentPackage)) {
      const { lstRadiologies, lstProcedures, lstProfiles } = packageDetail;
      const { ignoredProfiles, ignoredTests } = formValue;
      // Get list of Profile, Procedure, Radiology
      // Filter unmandatory test
      // Filter Optional test based on ingnored Test
      let lstOptionalTests = [];
      lstOptionalTests = returnOptionalPackageTests({
        lstProcedures,
        lstRadiologies,
        lstProfiles,
        ignoredTests,
        ignoredProfiles,
      });
      setLstOptionalTests(lstOptionalTests);
    } else {
      const {
        lstRadiologies,
        lstProcedures,
        lstProfiles,
        ignoredProfiles,
        ignoredTests,
      } = currentPackage;
      // Get list of Profile, Procedure, Radiology
      // Filter unmandatory test
      // Filter Optional test based on ingnored Test
      let lstOptionalTests = [];
      lstOptionalTests = returnOptionalPackageTests({
        lstProcedures,
        lstRadiologies,
        lstProfiles,
        ignoredTests,
        ignoredProfiles,
      });
      setLstOptionalTests(lstOptionalTests);
    }
  }, [formValue, packageDetail, currentPackage]);

  useEffect(() => {
    if (isFollowUp)
      locationDispatcher.getLocationFollowUp((result) => {
        const newList = [...result].map((it) => ({
          ...it,
          locationId: it.id,
          locationName: it.name,
          locationAddress: it.address,
        }));
        const currLocation = newList?.find(
          (location) => location?.locationId === apptData?.locationId
        );

        setLocationFollowUp(newList);
        setCurrentLocation({
          ...currLocation,
          startDate: moment().add(1, 'day'),
        });
      });
  }, [isFollowUp, apptData]);
  const getListHealthScreenings = (formValue, lstHealthScreenings) => {
    if (!isEmpty(lstHealthScreenings)) {
      let newLstHealthScreenings = [...lstHealthScreenings];
      let lstTests = refactorListDataCategory(formValue);

      // Get restricted location
      const params = generateParamsTestIdsPackageIdsData({
        lstPackages: formValue.lstPackages,
        lstTests,
      });
      allScreeningsDispatcher.getRestrictLocationByIds(
        params,
        (restrictedLocations) => {
          newLstHealthScreenings = newLstHealthScreenings.filter(
            (item) => !restrictedLocations?.includes(item?.locationId)
          );

          setLstHealthScreenings(newLstHealthScreenings);
        }
      );
    }
  };

  const refactorListDataCategory = (data) => {
    let newLstTests = [];
    for (const [key, value] of Object.entries(data)) {
      if (FORMAT_LIST_TESTS[key] && !isEmpty(value))
        newLstTests.push({
          category: FORMAT_LIST_TESTS[key],
          items: formatExpressTotalTestServices(value),
        });
    }

    return newLstTests;
  };

  const onGetDataTimeSlot = (value, isStartDateValid, locationId) => {
    if (!value) return;

    if (checkIsValidDate(value)) {
      setShowErrorInfo(true);
    } else {
      setShowErrorInfo(false);
    }

    if (locationId) {
      setLoadingSearchTimeSlot(true);
      locationDispatcher.getDetailLocationTimeSlot(
        {
          id: locationId,
          viewAll: true,
          date: moment(value).format('YYYY-MM-DD'),
        },
        (result) => {
          const { timeSlotId } = apptData;
          const startTimeSelected = result.find((it) => it.id === timeSlotId);

          const dayOfWeek = moment(value).day();
          const timeSlots = result.filter(
            (it) => it.dayOfWeek === dayOfWeek && !checkInvalidTimeslot(it)
          );

          const timeslotInvalid = result.filter(
            (it) => it.dayOfWeek === dayOfWeek && checkInvalidTimeslot(it)
          );
          const checkTimeslotId = timeslotInvalid?.find(
            (it) => it.id === timeSlotId
          );
          if (
            isEmpty(checkTimeslotId) &&
            startTimeSelected &&
            (!dateChecked ||
              dateChecked === moment(value).startOf('date').format())
          ) {
            isStartDateValid && setTimeSlotChecked(startTimeSelected.id);

            setDateChecked(moment(value).startOf('date').format());
          }
          setTimeSlot(timeSlots);
          setLoadingSearchTimeSlot(false);
        }
      );
      // locationDispatcher.getDetailLocation(apptData.locationId, result => {
      //   setClosureDate(result?.dayOffs);
      // });
    }
  };

  const checkInvalidTimeslot = (row) => {
    const { startDate, endDate, isActive } = row;
    // Disable Time Slot:
    // 1 Start time valid is after current time
    // 2 Start date valid is after current date 2 days
    // 3 Timeslot not active
    // 3 Timeslot not active
    // 4 End time valid is before current 5 minutes

    if (checkIsValidDate(startDate)) return true;
    if (checkIsValidTime(endDate)) return true;
    if (checkIsValidTime5Mins(endDate)) return true;

    if (!isActive) return true;

    return;
  };

  const checkIsValidTime = (startTime) => {
    const startTimeUnix = moment(startTime).unix();
    const timeCompare = moment().unix();

    return startTimeUnix <= timeCompare;
  };

  const checkIsValidTime5Mins = (endTime) => {
    const endTime5Mins = moment(endTime).subtract(5, 'minutes');
    const timeCompare = moment();
    const diffTime = endTime5Mins.diff(timeCompare, 'minutes');

    return diffTime < 0;
  };

  const getDetailLocation = (locationId) => {
    locationDispatcher.getDetailLocation(locationId, (result) => {
      if (result.restrictedPackageIds) {
        checkinPatientDispatcher.getData(apptData?.id, (resultPax) => {
          const packageIds = resultPax.lstPackages.map((test) => test.id);
          const restrictedPackageIds = result.restrictedPackageIds;
          const hasRestrictedIds = restrictedPackageIds.filter((id) =>
            packageIds.includes(id)
          );
          setIsRestrictPackages(!isEmpty(hasRestrictedIds));
        });
      }
      setClosureDate(result?.dayOffs);
      setRestrictedPackageIds(result?.restrictedPackageIds);
      setRestrictedTestIds(result?.restrictedTestIds);
    });
  };
  const handleChangeData = (data, filterKey) => {
    let allTest = [];
    data.forEach((prj) => {
      allTest = allTest.concat(prj.items);
    });
    const selectedData = allTest.filter((test) => test.isChecked);
    const totalCost = calculateTotalCost({
      ...formValue,
      [filterKey]: selectedData,
    });
    setFormValue({ ...formValue, [filterKey]: selectedData });
    setTotalCost(totalCost);
  };

  const STEPS = [
    {
      name: 'Select place and time',
      component: (
        <SelectPlaceAndTimeStep
          errors={errors}
          setErrors={setErrors}
          dataInfo={apptData}
          patientInfo={patientInfo}
          projectInfo={projectInfo}
          formValue={formValue}
          setFormValue={setFormValue}
          setCurrentLocation={setCurrentLocation}
          currentLocation={currentLocation}
          isFollowUp={isFollowUp}
          onGetDataTimeSlot={onGetDataTimeSlot}
          getDetailLocation={getDetailLocation}
          timeSlot={timeSlot}
          setTimeSlot={setTimeSlot}
          timeSlotChecked={timeSlotChecked}
          setTimeSlotChecked={setTimeSlotChecked}
          dateChecked={dateChecked}
          setDateChecked={setDateChecked}
          closureDate={closureDate}
          showErrorInfo={showErrorInfo}
          setIsRestrictPackages={setIsRestrictPackages}
          locationFollowUp={locationFollowUp}
          lstHealthScreenings={lstHealthScreenings}
          setProjectInfo={setProjectInfo}
        />
      ),
    },

    {
      name: 'Select service',
      component: isFollowUp ? (
        <ViewSelectPackage
          packageDetail={packageDetail}
          formValue={formValue}
          paymentStatus={apptData.paymentStatus}
          lstOptionalTests={lstOptionalTests}
          dataInfo={dataInfo}
          apptData={apptData}
          projectInfo={projectInfo}
          restrictedTestIds={restrictedTestIds}
        />
      ) : (
        <SelecetServiceStep
          projectInfo={projectInfo}
          formValue={formValue}
          setFormValue={setFormValue}
          handleChangeData={handleChangeData}
          packageDetail={packageDetail}
          setPackageDetail={setPackageDetail}
          projectCustomFields={projectCustomFields}
          lstOptionalTests={lstOptionalTests}
          setCurrentPackage={setCurrentPackage}
          currentPackage={currentPackage}
          dataPackageExisted={dataPackageExisted}
          locationId={formValue?.locationId}
          restrictedPackageIds={restrictedPackageIds}
          restrictedTestIds={restrictedTestIds}
        />
      ),
    },
  ];

  if (!location.state) history.push(`/${urlLabel.allScreenings}`);

  const onCancel = () => history.push(`/${urlLabel.allScreenings}`);

  const StepperButtons = () => (
    <Box display="flex" justifyContent="flex-end" p={1.25}>
      <StepperButton
        className="secondary"
        disabled={currentStep === 0}
        onClick={() => {
          setCurrentStep(currentStep - 1);
          getListHealthScreenings(formValue, projectInfo?.lstHealthScreenings);
        }}
      >
        Back
      </StepperButton>
      <StepperButton
        disabled={isValidHealthScreening || isEmpty(timeSlotChecked)}
        onClick={() => {
          if (currentStep === STEPS.length - 1) {
            onSubmit();
          } else setCurrentStep(currentStep + 1);
        }}
      >
        {currentStep === STEPS.length - 1 ? 'Reschedule' : 'Next'}
      </StepperButton>
    </Box>
  );

  return (
    <>
      <CustomPaperContainer
        header={
          <CustomHeader
            title={`Reschedule appointment`}
            isSearch={false}
            renderButton={() => (
              <>
                <Button color="primary" variant="outlined" onClick={onCancel}>
                  Cancel
                </Button>
              </>
            )}
          />
        }
        children={() => (
          <Paper square>
            <CustomStepper
              steps={STEPS}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              disableNext
              renderButtons={StepperButtons}
            >
              <Box height="calc(100vh - 280px)" overflow="scroll">
                {STEPS && STEPS[currentStep]?.component}
              </Box>
            </CustomStepper>
          </Paper>
        )}
      />
    </>
  );
};

export default RescheduleAppointment;

import React, { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import isBoolean from 'lodash/isBoolean';

import moment from 'moment';

import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';

import CustomSelectContainer from '@/new-components/CustomSelectOld/CustomSelectContainer';
import CustomDateRange from '@/new-components/CustomDateRange';
import { MARKETING_MESSAGES_STATUS_LIST } from '../constants';

const CustomButton = withStyles({
  label: {
    fontWeight: 700
  }
})(Button);

const GroupToolbar = ({
  filterOption,
  onResetFilterOps,
  onFilterOptionChange,
  publishDate,
  setPublishDate,
  setFilterOption
}) => {
  const isEmptyFilterParams = useMemo(() => {
    const filterKeys = Object.keys(filterOption);

    return filterKeys.every(key =>
      isBoolean(filterOption[key])
        ? !filterOption[key]
        : isEmpty(filterOption[key])
    );
  }, [filterOption]);

  const renderDateValue = (array, defaultLabel) => {
    /* 
    1. !startDate && !endDate => defaultLabel
    2. startDate && !endDate => startDate format: DD/MM/YYYY
    3. startDate && endDate
      - if startDate and endDate in the same month, year => format(DD-DD/MM/YYYY)
      - else format(DD/MM/YYYY-DD/MM/YYYY)
    */

    if (isEmpty(array)) return defaultLabel;
    const startDate = moment(array[0]).format('DD/MM/YYYY');
    const endDate = moment(array[1]).format('DD/MM/YYYY');
    if (startDate.trim() === endDate.trim()) return `${startDate} `;
    return `${startDate}-${endDate} `;
  };

  return (
    <Toolbar>
      <Box
        display="flex"
        flex={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" gridGap={12}>
          <CustomDateRange
            selectedDate={publishDate}
            renderValue={() =>
              renderDateValue(publishDate, 'Select published date')
            }
            onChange={(startDate, endDate) => {
              if (startDate && endDate) {
                setPublishDate([startDate, endDate]);
                setFilterOption({
                  ...filterOption,
                  fromDate: startDate.toISOString(),
                  toDate: endDate.toISOString()
                });
              } else {
                setPublishDate([]);
                setFilterOption({
                  ...filterOption,
                  fromDate: null,
                  toDate: null
                });
              }
            }}
          />

          <CustomSelectContainer
            isFullWidth
            filterValue={filterOption?.statuses || []}
            data={MARKETING_MESSAGES_STATUS_LIST}
            renderValue={
              !isEmpty(filterOption?.statuses)
                ? `Statuses (${filterOption?.statuses?.length})`
                : 'Select status'
            }
            onChange={onFilterOptionChange('statuses')}
            labelSelectAll={'All statuses'}
            useSelectAll
            checkedAll={
              MARKETING_MESSAGES_STATUS_LIST.length ===
              filterOption?.statuses?.length
            }
          />
        </Box>
        <CustomButton disabled={isEmptyFilterParams} onClick={onResetFilterOps}>
          Reset
        </CustomButton>
      </Box>
    </Toolbar>
  );
};

export default GroupToolbar;

import {
  HS_BILLING_TYPES,
  HS_SERVICE_TYPES,
  HS_ALL_SCREENINGS_PAXSCREENING_STATUS,
  QUICK_PRICE_VALUE_RANGE,
} from '../constants';
import { CheckboxActive, CheckboxDefault, FilterIcon } from '@/assets/svg';
import { getCostRange } from '@/helpers';
import CustomAsyncAutoComplete from '@/new-components/CustomAsyncAutoComplete';
import CustomQuickRangeGroup from '@/new-components/CustomChip/CustomGroupChips';
import CustomDateRange from '@/new-components/CustomDateRange';
import CustomSelect from '@/new-components/CustomSelectOld';
import CustomSelectContainer from '@/new-components/CustomSelectOld/CustomSelectContainer';
import { Checkbox, Paper } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import Popover from '@material-ui/core/Popover';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { isBoolean } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import React, { useMemo, useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      height: 40,
      borderRadius: 8,
    },
    '& input': {
      padding: 8,
      paddingLeft: 0,
      marginLeft: 0,
    },
  },
})(TextField);

const CustomFilterInput = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      height: 40,
      borderRadius: 8,

      '& input': {
        padding: 0,
        marginLeft: 0,
      },
      '& input::placeholder': {
        opacity: 1,
        color: '#999999',
      },
    },
  },
})(TextField);

const CustomInputLabel = withStyles({
  root: {
    marginBottom: 8,
    fontWeight: 600,
  },
})(InputLabel);

const useStyles = makeStyles((theme) => ({
  isBtnSelected: {
    border: 'none',
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
  },
  checkboxIcon: {
    padding: 0,
    marginRight: 4,
  },
  tagStyle: {
    maxWidth: 'calc(100% - 60px)', // Adjust based on your needs
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  selectWrapper: {
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'transparent',
    },

    '& .MuiAutocomplete-option': {
      padding: 8,
      borderRadius: 8,
      margin: '2px 8px',
      '&:first-child': {
        marginTop: 8,
      },
      '&:last-child': {
        marginBottom: 8,
      },
      '&.MuiAutocomplete-option[aria-selected="true"]': {
        backgroundColor: '#ffffff',
      },
    },
  },
}));

const CustomPopover = ({ children, isVisible }) => {
  return (
    <PopoverContainer>
      {isVisible && <PopoverContent>{children}</PopoverContent>}
    </PopoverContainer>
  );
};
const CustomButton = withStyles({
  label: {
    fontWeight: 700,
  },
})(Button);

const CustomFilterButton = withStyles({
  root: {
    height: 36,
  },
  label: {
    fontSize: 14,
  },
})(Button);

const GroupToolbar = ({
  serviceTypes,
  setServiceTypes,
  paxScreenStatuses,
  setPaxScreenStatuses,
  locationIds,
  setLocationIds,

  quickRange,
  setQuickRange,
  filterOption,
  setFilterOption,
  onFilterOptionClear,
  onFilterOptionChange,
  onResetFilterOps,

  appointmentDate,
  setAppointmentDate,
  createdDate,
  setCreatedDate,
  maxPriceDefault,

  projectIds,
  setProjectIds,
  billingTypes,
  setBillingTypes,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleChangeQuickRange = (event, rangeType) => {
    setQuickRange(rangeType);
    if (rangeType) {
      let [minCost, maxCost] = getCostRange(rangeType, maxPriceDefault);
      setFilterOption({
        ...filterOption,
        minCost,
        maxCost,
      });
    } else {
      setFilterOption({
        ...filterOption,
        minCost: 0,
        maxCost: maxPriceDefault,
      });
    }
  };

  const isEmptyFilterParams = useMemo(() => {
    const filterKeys = Object.keys(filterOption).filter(
      (item) => !['paxScreenStatuses'].includes(item)
    );
    return (
      filterKeys.every((key) =>
        isBoolean(filterOption[key])
          ? !filterOption[key]
          : isEmpty(filterOption[key])
      ) && paxScreenStatuses.every((item) => !item.length)
    );
  }, [filterOption, paxScreenStatuses]);

  const renderValue = (array, label, defaultLabel) => {
    let value = '';
    if (array.length === 0) value = defaultLabel;
    else if (array.length === 1) value = array[0];
    else value = `${label} (${array.length})`;

    return value;
  };

  const renderDateValue = (array, defaultLabel) => {
    /* 
    1. !startDate && !endDate => defaultLabel
    2. startDate && !endDate => startDate format: DD/MM/YYYY
    3. startDate && endDate
      - if startDate and endDate in the same month, year => format(DD-DD/MM/YYYY)
      - else format(DD/MM/YYYY-DD/MM/YYYY)
    */

    if (isEmpty(array)) return defaultLabel;
    const startDate = moment(array[0]).format('DD/MM/YYYY');
    const endDate = moment(array[1]).format('DD/MM/YYYY');
    return `${startDate}-${endDate} `;
  };

  return (
    <>
      <Toolbar>
        <Box
          display="flex"
          flex={1}
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" gridGap={12}>
            <CustomDateRange
              selectedDate={createdDate?.length ? [] : appointmentDate}
              renderValue={() =>
                renderDateValue(
                  createdDate?.length ? [] : appointmentDate,
                  'Select appointment date'
                )
              }
              onChange={(startDate, endDate) => {
                if (startDate && endDate) {
                  setAppointmentDate([startDate, endDate]);
                  setFilterOption({
                    ...filterOption,
                    startDate: startDate.toISOString(),
                    endDate: endDate.toISOString(),
                  });
                } else {
                  setAppointmentDate([]);
                  setFilterOption({
                    ...filterOption,
                    startDate: null,
                    endDate: null,
                  });
                }
              }}
            />

            <CustomSelectContainer
              isFullWidth
              filterValue={serviceTypes || []}
              data={HS_SERVICE_TYPES}
              renderValue={renderValue(
                serviceTypes,
                'Services',
                'All services'
              )}
              onClear={onFilterOptionClear('serviceTypes', [], setServiceTypes)}
              onChange={onFilterOptionChange('serviceTypes', setServiceTypes)}
              labelSelectAll={'All services'}
              useSelectAll
              checkedAll={
                HS_SERVICE_TYPES.map((item) => item.value).length ===
                serviceTypes.length
              }
            />

            <CustomAsyncAutoComplete
              multiple
              disableCloseOnSelect
              isLocation
              endpoint="Locations/filter"
              method="post"
              nameKey="name"
              value={locationIds}
              onChange={(e, value) => {
                setFilterOption({
                  ...filterOption,
                  locationIds: value.map((it) => it.id),
                });
                setLocationIds(value);
              }}
              defaultFilter={{
                sortByName: true,
              }}
              getOptionLabel={(option) => option?.name}
              renderInput={(params) => (
                <CustomFilterInput
                  {...params}
                  variant="outlined"
                  placeholder={isEmpty(locationIds) ? 'All locations' : ''}
                />
              )}
              renderOption={(option, { selected }) => {
                return (
                  <Box display="flex" gridGap={4}>
                    <Checkbox
                      icon={<CheckboxDefault width={20} height={20} />}
                      checkedIcon={<CheckboxActive width={20} height={20} />}
                      checked={selected}
                      color="primry"
                      className={classes.checkboxIcon}
                    />
                    <span>{option.name}</span>
                  </Box>
                );
              }}
              renderTags={(value, getTagProps) => {
                let newValue = value.filter((it) => locationIds?.includes(it));
                return newValue.length === 1 ? (
                  <div className={classes.tagStyle}>{newValue[0].name}</div>
                ) : newValue.length > 1 ? (
                  <Box>Locations ({newValue.length})</Box>
                ) : (
                  ''
                );
              }}
              PaperComponent={({ children, ...other }) => (
                <Paper
                  {...other}
                  style={{ width: 320 }}
                  className={classes.selectWrapper}
                >
                  {children}
                </Paper>
              )}
              style={{ width: locationIds.length === 1 ? 200 : 160 }}
              isSelected={locationIds.length === 1}
            />

            <CustomSelectContainer
              isFullWidth
              filterValue={paxScreenStatuses || []}
              data={HS_ALL_SCREENINGS_PAXSCREENING_STATUS}
              renderValue={renderValue(
                paxScreenStatuses.map(
                  (it) =>
                    HS_ALL_SCREENINGS_PAXSCREENING_STATUS.find(
                      (item) => item.key === it
                    ).value
                ),
                'Statuses',
                'All statuses'
              )}
              onClear={onFilterOptionClear(
                'paxScreenStatuses',
                HS_ALL_SCREENINGS_PAXSCREENING_STATUS,
                setPaxScreenStatuses
              )}
              onChange={onFilterOptionChange(
                'paxScreenStatuses',
                setPaxScreenStatuses
              )}
              labelSelectAll={'All statuses'}
              useSelectAll
              checkedAll={
                HS_ALL_SCREENINGS_PAXSCREENING_STATUS.length ===
                paxScreenStatuses.length
              }
            />

            <CustomFilterButton
              variant="outlined"
              onClick={() => setIsVisible(!isVisible)}
              startIcon={
                <FilterIcon color={isVisible ? 'primary' : 'default'} />
              }
              className={clsx({
                [classes.isBtnSelected]: isVisible,
              })}
            >
              Filter
            </CustomFilterButton>
          </Box>

          <CustomButton
            disabled={isEmptyFilterParams}
            onClick={onResetFilterOps}
          >
            Reset
          </CustomButton>
        </Box>
      </Toolbar>
      <CustomPopover isVisible={isVisible}>
        <Paper square variant="outlined">
          <Toolbar>
            <Box display="flex" flex={1} alignItems="center" gridGap={12}>
              <CustomAsyncAutoComplete
                multiple
                disableCloseOnSelect
                endpoint="Projects"
                nameKey="name"
                value={projectIds}
                onChange={(e, value) => {
                  setFilterOption({
                    ...filterOption,
                    projectIds: value.map((it) => it.id),
                  });
                  setProjectIds(value);
                }}
                defaultFilter={{
                  sortByName: true,
                }}
                getOptionLabel={(option) => option?.name}
                renderInput={(params) => (
                  <CustomFilterInput
                    {...params}
                    variant="outlined"
                    placeholder={isEmpty(projectIds) ? 'All projects' : ''}
                  />
                )}
                renderOption={(option, { selected }) => {
                  return (
                    <Box display="flex" gridGap={4}>
                      <Checkbox
                        icon={<CheckboxDefault width={20} height={20} />}
                        checkedIcon={<CheckboxActive width={20} height={20} />}
                        checked={selected}
                        color="primry"
                        className={classes.checkboxIcon}
                      />
                      <span>{option.name}</span>
                    </Box>
                  );
                }}
                renderTags={(value, getTagProps) => {
                  let newValue = value.filter((it) => projectIds?.includes(it));
                  return newValue.length === 1 ? (
                    <div className={classes.tagStyle}>{newValue[0].name}</div>
                  ) : newValue.length > 1 ? (
                    <Box pl={1.25}>Projects ({newValue.length})</Box>
                  ) : (
                    ''
                  );
                }}
                PaperComponent={({ children, ...other }) => (
                  <Paper
                    {...other}
                    style={{ width: 320 }}
                    className={classes.selectWrapper}
                  >
                    {children}
                  </Paper>
                )}
                style={{ width: projectIds.length === 1 ? 200 : 160 }}
                isSelected={projectIds.length === 1}
              />

              <CustomDateRange
                selectedDate={createdDate}
                renderValue={() =>
                  renderDateValue(createdDate, 'Select created date')
                }
                onChange={(startDate, endDate) => {
                  if (startDate && endDate) {
                    setCreatedDate([startDate, endDate]);
                    setFilterOption({
                      ...filterOption,
                      createdStartDate: startDate.toISOString(),
                      createdEndDate: endDate.toISOString(),
                    });
                  } else {
                    setCreatedDate([]);
                    setFilterOption({
                      ...filterOption,
                      createdStartDate: null,
                      createdEndDate: null,
                    });
                  }
                }}
              />

              <CustomSelect
                isFullWidth
                isShowArrow
                onClick={handleClick}
                renderValue={
                  filterOption.minCost || filterOption.maxCost
                    ? `$${filterOption.minCost || 0} - $${
                        filterOption.maxCost || 0
                      }`
                    : 'Total cost'
                }
              >
                <Popover
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  PaperProps={{
                    style: {
                      border: '1px solid #F1F1F1',
                      boxShadow: '0 4px 16px 0 rgba(0,0,0,0.1)',
                    },
                  }}
                >
                  <Box
                    p={2}
                    display="flex"
                    justifyContent="space-between"
                    gridGap={16}
                  >
                    <Box width="100%">
                      <CustomInputLabel>From</CustomInputLabel>
                      <FormControl component="fieldset" fullWidth>
                        <CustomTextField
                          type="number"
                          variant="outlined"
                          value={filterOption.minCost}
                          onChange={onFilterOptionChange('minCost')}
                          placeholder="0"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    </Box>
                    <Box width="100%">
                      <CustomInputLabel>To</CustomInputLabel>
                      <FormControl component="fieldset" fullWidth>
                        <CustomTextField
                          type="number"
                          variant="outlined"
                          value={filterOption.maxCost}
                          onChange={onFilterOptionChange('maxCost')}
                          placeholder="0"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    </Box>
                  </Box>
                  <Divider light />
                  <Box display="flex" p={2}>
                    <CustomQuickRangeGroup
                      value={quickRange}
                      handleChange={handleChangeQuickRange}
                      listButtons={QUICK_PRICE_VALUE_RANGE}
                    />
                  </Box>
                </Popover>
              </CustomSelect>

              <CustomSelectContainer
                isFullWidth
                filterValue={billingTypes || []}
                data={HS_BILLING_TYPES}
                renderValue={renderValue(
                  billingTypes,
                  'Billing types',
                  'All billing types'
                )}
                onClear={onFilterOptionClear(
                  'billingTypes',
                  [],
                  setBillingTypes
                )}
                onChange={onFilterOptionChange('billingTypes', setBillingTypes)}
                labelSelectAll={'All billing types'}
                useSelectAll
                checkedAll={
                  HS_BILLING_TYPES.map((item) => item.value).length ===
                  billingTypes.length
                }
              />
            </Box>
          </Toolbar>
        </Paper>
      </CustomPopover>
    </>
  );
};

export default GroupToolbar;

const PopoverContainer = styled.div`
  position: relative;
`;
const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const PopoverContent = styled.div`
  position: absolute;
  width: calc(100vw - 256px);
  top: 100%;
  left: 0;
  transform: translateX(0);
  animation: ${fadeIn} 0.5s ease-in-out;
  z-index: 999;
`;

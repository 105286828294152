import React from 'react';
import styled from 'styled-components';
import { Autorenew } from '@material-ui/icons';
import { Grid, Button, withStyles, SvgIcon } from '@material-ui/core';

import CustomDate from '@/components/CustomDate';
import { ButtonEnhance } from '@/module/common/componentUI/commonStyleComponents';
import CustomSelectContainer from '@/components/CustomSelect/CustomSelectContainer';
import moment from 'moment';
import { AppointmentStatusIcon } from '@/assets/svg';
import { BACKROOM_MODULE_STATUS } from '../constants';

const CustomButton = withStyles({
  root: {
    margin: 5,
    paddingLeft: 40,
    paddingRight: 40,

    height: 40,
    minWidth: 160,

    textTransform: 'unset',
    fontWeight: 600,
    borderRadius: 50
  }
})(Button);

const GroupSearch = ({
  filterOption,
  onFilterOptionClear,
  onFilterOptionChange,
  setFilterOption,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  onResetFilterOps,

  backroomStatuses,
  setBackroomStatuses,
  defaultStartDate = '',
  defaultEndDate = ''
}) => {
  const onChangeStartDate = value => {
    const newValue = moment(value).startOf('date');

    setStartTime(value ? newValue : defaultStartDate);
    setFilterOption({
      ...filterOption,
      startTime: value ? newValue : defaultStartDate
    });
  };

  const onChangeEndDate = value => {
    const newValue = moment(value).endOf('date');
    setEndTime(value ? newValue : defaultEndDate);
    setFilterOption({
      ...filterOption,
      endTime: value ? newValue : defaultEndDate
    });
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        marginBottom: 16
      }}
    >
      <Grid container spacing={3} alignItems="flex-end">
        <Grid item md={3} sm={6} xs={12}>
          <CustomSelectContainer
            isFullWidth
            filterValue={backroomStatuses}
            data={BACKROOM_MODULE_STATUS}
            StartAdornment={() => (
              <SvgIcon
                component={AppointmentStatusIcon}
                style={{ marginRight: 8 }}
              />
            )}
            renderValue={
              <>
                {backroomStatuses
                  .map(status => {
                    return BACKROOM_MODULE_STATUS[status];
                  })
                  .join(', ') || 'Status'}
              </>
            }
            onClear={onFilterOptionClear('statuses', setBackroomStatuses, [])}
            onChange={onFilterOptionChange('statuses', setBackroomStatuses)}
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <CustomDate
            {...{
              selectedDate: startTime,
              onChange: onChangeStartDate,
              placeholder: 'Start Date',
              maxDate: endTime ? moment(endTime) : null,
              onClear: () => {
                setStartTime('');
                setFilterOption({
                  ...filterOption,
                  startTime: ''
                });
              }
            }}
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <CustomDate
            {...{
              selectedDate: endTime,
              onChange: onChangeEndDate,
              placeholder: 'End Date',
              minDate: startTime ? moment(startTime) : null,
              onClear: () => {
                setEndTime('');
                setFilterOption({
                  ...filterOption,
                  endTime: ''
                });
              }
            }}
          />
        </Grid>
        <Grid container justify="flex-end" style={{ marginRight: 16 }}>
          <CustomButton
            onClick={() => {
              onResetFilterOps();
            }}
            variant="contained"
            color="primary"
            endIcon={<Autorenew />}
            style={{ borderRadius: 50, margin: 5 }}
          >
            Reset Filter
          </CustomButton>
        </Grid>
      </Grid>
    </div>
  );
};

export const StyledButtonEnhance = styled(ButtonEnhance)`
  max-width: 160px;
  display: flex;
  align-self: flex-end;
  justify-content: center;
  margin: 12px;
  .add-group {
    display: flex;
    width: fit-content;
    align-items: center;
    margin-top: -2px;
    span {
      margin-right: 8px;
      color: #cacfd3;
    }
  }
`;

export default GroupSearch;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  DialogContent,
  TextField,
  Grid,
  CircularProgress,
  makeStyles
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { LocalizationProvider } from '@material-ui/pickers';
import MomentAdapter from '@material-ui/pickers/adapter/moment';
import { useSelector } from 'react-redux';

import ModalPage, { ModalFooter } from './styled';
import { ButtonEnhance } from '../../common/componentUI/commonStyleComponents';
import LoadingButton from '../../../components/LoadingButton';
import { ClearRounded } from '@material-ui/icons';
import { TYPE_MODAL } from '../../common/constants/typeModal';
import validateData from '../../../helpers/validationHelpers/validationSchema';
import dispatcher from '../action';

const useStyles = makeStyles(theme => ({
  root: {
    width: 100,
    height: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#85868A',
    opacity: 1
  }
}));

const PromotionModal = ({
  onClose,
  id,
  typeModal,
  searchKey,
  setPromotionId
}) => {
  const [formValue, setFormValue] = useState({
    canIgnore: false,
    startDate: null,
    endDate: null
  });
  const [errors, setErrors] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const { promote } = useSelector(state => state.promotion);

  const isEdit = typeModal === 'Edit' ? true : false;

  const classes = useStyles();

  useEffect(() => {
    return () => {
      dispatcher.saveDataById(null);
      setPromotionId(null);
    };
  }, []);

  useEffect(() => {
    if (id && isEdit) {
      dispatcher.getDataById(id);
    }
  }, [id, isEdit]);

  useEffect(() => {
    if (promote) {
      const { name, code, startDate, endDate, quota, description } = promote;
      const newFormData = {
        name,
        code,
        startDate: moment(startDate),
        endDate: moment(endDate),
        quota: String(quota),
        description
      };
      setFormValue(newFormData);
    }
  }, [promote]);

  const handleSubmit = async () => {
    try {
      await validateData('promotionSchema', formValue, validData => {
        setShowLoader(true);
        dispatcher.editData(
          validData,
          searchKey,
          onClose,
          () => setShowLoader(false),
          id
        );
      });
    } catch (errs) {
      setErrors(errs);
    }
  };

  const handleChangeForm = key => e => {
    setFormValue({ ...formValue, [key]: e.target.value });
  };

  const handleChangeDate = key => value => {
    setFormValue({ ...formValue, [key]: value });
  };

  const renderContentPage = () => {
    if (isEdit && !promote) {
      return (
        <div className={classes.root}>
          <CircularProgress color="primary" />
        </div>
      );
    }
    return (
      <ModalPage>
        <DialogContent>
          <div className="test-header">
            {isEdit ? promote.name : 'Create New Promotion'}
            <ClearRounded
              style={{ cursor: 'pointer', float: 'right', color: '#CACFD3' }}
              onClick={onClose}
            />
          </div>
          <div className="test-body">
            <Grid container spacing={2} style={{ marginTop: 10 }}>
              <Grid item xs={12}>
                <TextField
                  disabled={isEdit}
                  error={errors.code}
                  helperText={errors.code}
                  label="Code"
                  style={{ width: '100%', marginTop: 10 }}
                  value={formValue.code}
                  onChange={handleChangeForm('code')}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={errors.name}
                  helperText={errors.name}
                  label="Name"
                  style={{ width: '100%', marginTop: 10 }}
                  value={formValue.name}
                  onChange={handleChangeForm('name')}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={errors.quota}
                  helperText={errors.quota}
                  label="Quota"
                  style={{ width: '100%', marginTop: 10 }}
                  value={formValue.quota}
                  onChange={handleChangeForm('quota')}
                />
              </Grid>
            </Grid>
            <LocalizationProvider dateAdapter={MomentAdapter}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <DatePicker
                    label="Start date"
                    value={formValue.startDate}
                    onChange={handleChangeDate('startDate')}
                    name="startDate"
                    renderInput={props => (
                      <TextField
                        {...props}
                        style={{ width: '100%', marginTop: 10 }}
                        error={errors.startDate}
                        helperText={errors.startDate}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    label="End date"
                    value={formValue.endDate}
                    onChange={handleChangeDate('endDate')}
                    name="endDate"
                    renderInput={props => (
                      <TextField
                        {...props}
                        style={{ width: '100%', marginTop: 10 }}
                        error={errors.endDate}
                        helperText={errors.endDate}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </LocalizationProvider>
            <Grid item xs={12}>
              <TextField
                multiline
                rowsMax={6}
                error={errors.description}
                helperText={errors.description}
                label="Description"
                style={{ width: '100%', marginTop: 20, paddingTop: 5 }}
                value={formValue.description}
                onChange={handleChangeForm('description')}
              />
            </Grid>
          </div>
          <ModalFooter>
            <ButtonEnhance
              background="#E9E9E9"
              color="black"
              backgroundHover="#ccc"
              onClick={onClose}
            >
              Cancel
            </ButtonEnhance>

            <LoadingButton onClick={handleSubmit} showLoader={showLoader}>
              {typeModal === TYPE_MODAL.Edit ? 'Update' : 'Create'}
            </LoadingButton>
          </ModalFooter>
        </DialogContent>
      </ModalPage>
    );
  };

  return <>{renderContentPage()} </>;
};

export default PromotionModal;

import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { authDispatcher } from '@/module/auth';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { throttle } from 'lodash';

const IdleTimeOutHandler = () => {
  const TIMEOUT = 1 * 60 * 60 * 1000; // 3600000 milliseconds equal 1 hour
  const events = ['click', 'scroll', 'load', 'keydown', 'mousemove'];
  const isAuthenticated = useSelector(state => !!state.auth.accessToken);
  const isCalling = useSelector(state => state.waitingRoom.calling);
  const params = useRef({
    isAuthenticated,
    isCalling
  });

  const checkTimeout = useCallback(
    throttle(hasInteraction => {
      const { isAuthenticated, isCalling } = params.current;

      const lastInteractionTime = localStorage.getItem('lastInteractionTime');
      const difference = moment().diff(moment(lastInteractionTime));

      // Condition to prevent logout on-call
      if (isAuthenticated && !isCalling && difference >= TIMEOUT) {
        authDispatcher.logout();
      } else if (hasInteraction) {
        localStorage.setItem('lastInteractionTime', moment());
      }
    }, 1000),
    []
  );

  const eventsHandler = useMemo(
    () => ({
      addEvents: () => {
        events.forEach(eventName => {
          window.addEventListener(eventName, checkTimeout);
        });
      },
      removeEvents: () => {
        events.forEach(eventName => {
          window.removeEventListener(eventName, checkTimeout);
        });
      }
    }),
    []
  );

  useEffect(() => {
    params.current = {
      isAuthenticated,
      isCalling
    };
  }, [isAuthenticated, isCalling]);

  useEffect(() => {
    eventsHandler.addEvents();

    const interval = setInterval(checkTimeout, 60 * 1000);

    return () => {
      eventsHandler.removeEvents();
      clearInterval(interval);
    };
  }, []);

  return <div></div>;
};

export default IdleTimeOutHandler;

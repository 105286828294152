import React, { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import isBoolean from 'lodash/isBoolean';

import moment from 'moment';

import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';

import { TIME_SELECT } from '@/module/setup/constants';
import CustomSelectContainer from '@/new-components/CustomSelectOld/CustomSelectContainer';

import { getTimeSlots } from '@/module/setup/utils';

const CustomButton = withStyles({
  label: {
    fontWeight: 700
  }
})(Button);

const GroupToolbar = ({
  filterOption,

  onResetFilterOps,
  onFilterOptionClear,
  onFilterOptionChange
}) => {
  const timeSlotsStart = getTimeSlots(7, 21).filter(
    obj =>
      !filterOption.endTime ||
      moment(obj.value, 'h:mm A').isSameOrBefore(filterOption.endTime)
  );
  const timeSlotsEnd = getTimeSlots(7, 21).filter(
    obj =>
      !filterOption.startTime ||
      moment(obj.value, 'h:mm A').isSameOrAfter(filterOption.startTime)
  );

  const isEmptyFilterParams = useMemo(() => {
    const filterKeys = Object.keys(filterOption);

    return filterKeys.every(key =>
      isBoolean(filterOption[key])
        ? !filterOption[key]
        : isEmpty(filterOption[key])
    );
  }, [filterOption]);

  return (
    <Toolbar>
      <Box
        display="flex"
        flex={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" gridGap={12}>
          <CustomSelectContainer
            data={timeSlotsStart}
            isMutiple={false}
            disabled={isEmpty(timeSlotsStart)}
            renderValue={
              <>
                {filterOption?.startTime
                  ? moment(filterOption?.startTime)?.format('h:mm A')
                  : 'Select opening hour'}
              </>
            }
            filterValue={moment(filterOption?.startTime).format('h:mm A')}
            onChange={onFilterOptionChange('startTime')}
          />
          <CustomSelectContainer
            data={timeSlotsEnd}
            disabled={isEmpty(timeSlotsEnd)}
            isMutiple={false}
            renderValue={
              <>
                {filterOption?.endTime
                  ? moment(filterOption?.endTime)?.format('h:mm A')
                  : 'Select closing hour'}
              </>
            }
            filterValue={moment(filterOption?.endTime).format('h:mm A')}
            onChange={onFilterOptionChange('endTime')}
          />

          <CustomSelectContainer
            isFullWidth
            filterValue={filterOption.interval || []}
            data={TIME_SELECT}
            renderValue={
              !isEmpty(filterOption.interval)
                ? filterOption.interval.join(', ')
                : 'Select interval'
            }
            onClear={onFilterOptionClear('interval')}
            onChange={onFilterOptionChange('interval')}
            labelSelectAll={'Select all'}
            useSelectAll
            checkedAll={TIME_SELECT.length === filterOption?.interval?.length}
            clearable={!isEmpty(filterOption.interval)}
          />
        </Box>
        <CustomButton disabled={isEmptyFilterParams} onClick={onResetFilterOps}>
          Reset
        </CustomButton>
      </Box>
    </Toolbar>
  );
};

export default GroupToolbar;

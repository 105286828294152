import { TYPE_MODAL } from '../constants';
import { ArrowDown } from '@/assets/svg';
import validateData from '@/helpers/validationHelpers/validationSchema';
import { CustomAutocomplete } from '@/module/company/component/CreateAndEditCompany/styled';
import CustomModalTitle from '@/new-components/CustomModal/CustomModalTitle';
import { FormHelperText, Paper } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { cloneDeep, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useState, useRef, useMemo } from 'react';
import { useEffect } from 'react';

const CustomInputLabel = withStyles({
  root: {
    color: '#666666',
    marginBottom: 8,
    fontWeight: 600,
  },
})(InputLabel);

const CustomTextField = withStyles({
  root: {
    fontSize: 16,

    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      height: 48,
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      paddingLeft: 5,
      fontSize: 16,
    },
    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6',
      },
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 0,
    },
  },
})(TextField);

const CustomDialog = withStyles({
  paper: {
    borderRadius: 16,
    width: 480,
  },
})(Dialog);

const CustomButton = withStyles({
  root: {
    width: 166,
    height: 40,
    borderRadius: 8,

    '&.red': {
      background: '#DB3D49',
    },
    '&.Mui-disabled': {
      opacity: 0.3,
      color: '#ffffff',
      backgroundColor: '#AD5E99',
    },
  },
  containedPrimary: {
    boxShadow: 'none',
  },
})(Button);

const CustomDialogContent = withStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
}))(DialogContent);

const CustomDialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    justifyContent: 'flex-end',
  },
}))(DialogActions);

const CustomFormHelperText = withStyles({
  root: {
    alignItems: 'center',
    display: 'flex',
    fontFamily: 'OpenSans',
    fontSize: 12,
    gap: 6,
    marginLeft: 4,
    marginTop: 0,

    '& .MuiSvgIcon-root': {
      fontSize: 'unset',
    },
  },
})(FormHelperText);

const CustomPaper = withStyles((theme) => ({
  root: {
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'transparent',
    },

    '& .MuiAutocomplete-option': {
      padding: 8,
      borderRadius: 8,
      margin: '2px 8px',
      '&:first-child': {
        marginTop: 8,
      },
      '&:last-child': {
        marginBottom: 8,
      },
      '&.MuiAutocomplete-option[aria-selected="true"]': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
}))(Paper);

const ModalAddOrEditQualification = ({
  open,
  title,
  onSubmit,
  onClose,
  selectedItem,
  typeModal,
}) => {
  const [formValue, setFormValue] = useState({
    degree: '',
    school: '',
    startDate: '',
    endDate: '',
  });
  const [errors, setErrors] = useState({});
  const _tempForm = useRef(null);

  const rangeNumber = (start, end, step = 1) => {
    const len = Math.floor((end - start) / step) + 1;
    return Array(len)
      .fill()
      .map((_, idx) => start + idx * step);
  };

  const listRangeYears = rangeNumber(1900, 2099).map((it) => String(it));

  const listRangeEndYears = useMemo(() => {
    const listTime = rangeNumber(1900, 2099)
      .map((it) => String(it))
      .filter((obj) => {
        const dateDate = moment(obj, 'year').format('YYYY-MM-DD');
        const startDate = moment(formValue?.startDate).format('YYYY-MM-DD');

        return moment(dateDate).isSameOrAfter(moment(startDate));
      });

    return listTime;
  }, [formValue.startDate, selectedItem]);

  useEffect(() => {
    /** Block code to cache initial form data on edit mode */
    const handleExistItem = () => {
      setFormValue(selectedItem.item);
      _tempForm.current = cloneDeep(selectedItem.item);
    };
    !isEmpty(selectedItem.item)
      ? handleExistItem()
      : setFormValue({
          degree: '',
          school: '',
          startDate: '',
          endDate: '',
        });
  }, [typeModal, selectedItem]);

  const handleChangeForm = (key) => (e, value) => {
    let newValue = '';

    switch (key) {
      case 'startDate':
        newValue = moment(value, 'year').startOf('day').startOf('month');
        break;
      case 'endDate':
        newValue = value ? moment(value).endOf('day').endOf('month') : '';
        break;

      default:
        newValue = e.target.value;
        break;
    }

    setFormValue({ ...formValue, [key]: newValue });
  };

  const handleSubmit = async () => {
    try {
      await validateData('qualificationSchema', formValue, () => {
        onSubmit(typeModal, formValue, selectedItem.position);
      });
    } catch (err) {
      setErrors(err);
    }
  };

  const isEmptyFilterParams = useMemo(() => {
    const filterKeys = ['degree', 'school', 'startDate'];
    const isValidEndTime = listRangeEndYears?.find(
      (year) => Number(year) === moment(formValue.endDate).get('y')
    );
    if (isEmpty(formValue.endDate))
      return !filterKeys.every(
        (key) => formValue[key] || !isEmpty(formValue[key])
      );
    return (
      !filterKeys.every((key) => formValue[key] || !isEmpty(formValue[key])) ||
      !isValidEndTime
    );
  }, [listRangeEndYears, formValue]);

  return (
    <CustomDialog
      maxWidth="md"
      fullWidth
      scroll="body"
      disableEnforceFocus
      open={open}
      onClose={onClose}
      disableBackdropClick
    >
      <CustomModalTitle onClose={onClose}>{title}</CustomModalTitle>
      <Divider light />
      <CustomDialogContent>
        <Grid container spacing={2} style={{ marginTop: 10 }}>
          <Grid item xs={12}>
            <CustomInputLabel>Degree / Certificate</CustomInputLabel>
            <CustomTextField
              fullWidth
              error={errors.degree}
              helperText={errors.degree}
              value={formValue.degree || ''}
              onChange={handleChangeForm('degree')}
              variant="outlined"
              placeholder="Enter details"
            />
          </Grid>

          <Grid item xs={12}>
            <CustomInputLabel>Institution</CustomInputLabel>
            <CustomTextField
              fullWidth
              error={errors.school}
              helperText={errors.school}
              value={formValue.school || ''}
              onChange={handleChangeForm('school')}
              variant="outlined"
              placeholder="Enter institution name"
            />
          </Grid>
          <Grid item xs={6}>
            <CustomInputLabel>Start time</CustomInputLabel>
            <CustomAutocomplete
              getOptionSelected={(option, value) => option === value}
              getOptionLabel={(option) => option || ''}
              options={listRangeYears || []}
              value={
                listRangeYears.find(
                  (year) =>
                    Number(year) === moment(formValue.startDate).get('y')
                ) || ''
              }
              onChange={handleChangeForm('startDate')}
              popupIcon={<ArrowDown />}
              renderInput={(params) => (
                <CustomTextField
                  variant="outlined"
                  placeholder="Select"
                  {...params}
                />
              )}
              disableClearable
              PaperComponent={({ children, ...other }) => (
                <CustomPaper {...other}>{children}</CustomPaper>
              )}
            />

            {errors.startDate && (
              <CustomFormHelperText error>
                {errors.startDate}
              </CustomFormHelperText>
            )}
          </Grid>
          <Grid item xs={6}>
            <CustomInputLabel>End time (optional)</CustomInputLabel>
            <CustomAutocomplete
              getOptionSelected={(option, value) => option === value}
              getOptionLabel={(option) => option || ''}
              options={listRangeEndYears || []}
              value={
                listRangeEndYears.find(
                  (year) => Number(year) === moment(formValue.endDate).get('y')
                ) || ''
              }
              onChange={handleChangeForm('endDate')}
              popupIcon={<ArrowDown />}
              renderInput={(params) => (
                <CustomTextField
                  variant="outlined"
                  placeholder="Select"
                  {...params}
                />
              )}
              PaperComponent={({ children, ...other }) => (
                <CustomPaper {...other}>{children}</CustomPaper>
              )}
            />

            {errors.endDate && (
              <CustomFormHelperText error>
                {errors.endDate}
              </CustomFormHelperText>
            )}
          </Grid>
        </Grid>
      </CustomDialogContent>
      <Divider light />
      <CustomDialogActions>
        <CustomButton
          disabled={isEmptyFilterParams}
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          autoFocus
          size="small"
        >
          {typeModal === TYPE_MODAL.Create ? 'Add' : 'Save'}
        </CustomButton>
      </CustomDialogActions>
    </CustomDialog>
  );
};

export default ModalAddOrEditQualification;
